import React, { useEffect } from 'react';
import APIAxios, { APIRoutes } from '../../API/api.axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import { makeStyles } from '@mui/styles';
import { Button, Hidden, Theme, Grid, Typography } from '@mui/material';
import { LoginForm } from './login.form';
import { TUserRole, LoginDto, GetAuthCredentialsDto, ITypeRouteParams } from '../../Types';
import { updateLoginState, updateUserState } from '../../Stores';
import { CarouselComponent, Copyright } from '../../Components';
import { TabPanel } from '../../Theme/MaterialStyledComponents';
import { TopColorIndicator } from '../../Components/Organisms/login-card.component';
import { useError } from '../../Hooks';
import Logo from '../../Assets/logo.png';

const useStyles = makeStyles((theme: Theme) => ({
  shadowed: {
    padding: theme.spacing(6, 5),
    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    position: 'relative',
    zIndex: 1,
    boxShadow: theme.customShadows.shadow1,
  },
  containerHeight: {
    height: `calc(100vh - ${theme.customShapes.footerHeight})`,
  },
  copyright: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    textAlign: 'center',
  },
  logo: {
    maxWidth: '150px',
  },
  [theme.breakpoints.down('md')]: {
    container: {
      padding: '2rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      padding: '1rem',
    },
    shadowed: {
      padding: theme.spacing(3, 2),
    },
    logo: {
      maxWidth: '100px',
    },
  },
}));

export const Login = () => {
  const { type } = useParams<ITypeRouteParams>();
  const {
    state: { user },
    actions,
  } = useStateMachine({ updateLoginState, updateUserState });
  const classes = useStyles();
  const navigate = useNavigate();
  const { addError } = useError();
  const onSubmit = async (data: LoginDto) => {
    try {
      const res = await APIAxios({...APIRoutes.POSTLogin(), data: {
        ...data,
        type: type?.toUpperCase()
      }})

      if (res.data as GetAuthCredentialsDto) {
        localStorage.setItem('accessToken', res.data.accessToken);
        localStorage.setItem('shouldCompleteProfile', 'true');
        actions.updateUserState({
          beneficiary: res.data.beneficiary,
          consultant: res.data.consultant,
          organism: res.data.organism,
          admin: res.data.admin,
          // id: res.data.beneficiary.id || res.data.organism.id || res.data.consultant.id || res.data.admin.id || '',
          isAuth: true,
        });
      } else {
        addError({message: 'Une erreur est survenue. Veuillez réessayer plus tard.'});
      }
    } catch (err: any) {
      switch (err.response?.data?.message) {
        case "WRONG_CREDENTIALS":
          addError({message: 'Les identifiants sont invalides.'});
          break;
        case "WRONG_USER":
          addError({message: 'Veuillez sélectionner le bon rôle'});
          break;
        case 'ACCOUNT_LOCKED':
          addError({code: 'account-locked'});
          break;
        default:
          addError({message: 'Une erreur est survenue. Veuillez réessayer plus tard.'});
          break;
      }
    }
  };

  useEffect(() => {
    if (user.isAuth) {
      navigate(`/${type}/${
        user.beneficiary || user.consultant ? 'dashboard' : user.organism ? 'consultants' : 'beneficiaries'
      }`, {
        state: {
          from: 'login',
        },
        replace: true,
      });
    }
  }, [user, navigate, type]);

  const getRoleName = () => {
    switch (type) {
      case 'consultant':
        return "Consultant";
      case 'beneficiary':
        return 'Bénéficiaire';
      default:
        return 'Organisme';
    }
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        margin="auto"
        maxWidth={800}
        height="100vh"
        className={`${classes.container} ${classes.containerHeight}`}
        flexWrap="nowrap"
        gap={{ xs: 3, sm: 5 }}
        position="relative"
      >
        <Grid item container justifyContent="center">
          <img src={Logo} className={classes.logo} alt='logo' />
        </Grid>
        <Grid item container justifyContent="center" width="100%">
          <TabsUnstyled value={type} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <TabPanel value={'beneficiary' as TUserRole} className={classes.shadowed}>
            <TopColorIndicator type={type || ''} />
              <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12} sm={6} container direction="column" rowGap={3}>
                  <Grid container direction="column">
                    <Typography variant="h2">Se connecter</Typography>
                  </Grid>
                  <LoginForm submitForm={onSubmit} />
                </Grid>
                <Hidden smDown>
                  <Grid item xs={6} container justifyContent="flex-end">
                    <CarouselComponent />
                  </Grid>
                </Hidden>
              </Grid>
            </TabPanel>
            <TabPanel value={'consultant' as TUserRole} className={classes.shadowed}>
              <TopColorIndicator type={type || ''} />
              <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12} sm={6} container direction="column" rowGap={3}>
                  <Typography variant="h2">Se connecter</Typography>
                  <LoginForm submitForm={onSubmit} />
                  {/* <Typography variant="subtitle1" component="div">
                    {`Vous n'avez pas encore de compte ?`}
                    <Button variant="text" color="primary" onClick={() => navigate('/signup/consultant')}>
                      Créer un compte
                    </Button>
                  </Typography> */}
                </Grid>
                <Hidden smDown>
                  <Grid item xs={6} container justifyContent="flex-end">
                    <CarouselComponent />
                  </Grid>
                </Hidden>
              </Grid>
            </TabPanel>
            <TabPanel value={'organism' as TUserRole} className={classes.shadowed}>
              <TopColorIndicator type={type || ''} />
              <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12} sm={6} container direction="column" rowGap={3}>
                  <Typography variant="h2">Se connecter</Typography>
                  <LoginForm submitForm={onSubmit} />
                  {/* <Typography variant="subtitle1" component="div">
                    {`Vous n'avez pas encore de compte ?`}
                    <Button variant="text" color="primary" onClick={() => navigate('/signup/organism')}>
                      Créer un compte
                    </Button>
                  </Typography> */}
                </Grid>
                <Hidden smDown>
                  <Grid item xs={6} container justifyContent="flex-end">
                    <CarouselComponent />
                  </Grid>
                </Hidden>
              </Grid>
            </TabPanel>
            <TabPanel value={'admin' as TUserRole} className={classes.shadowed} style={{maxWidth: '550px'}}>
              <TopColorIndicator type={type || ''} />
              <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12} container direction="column" rowGap={3}>
                  <Typography variant="h2">Se connecter</Typography>
                  <LoginForm submitForm={onSubmit} />
                </Grid>
              </Grid>
            </TabPanel>
          </TabsUnstyled>
        </Grid>
      <Copyright />
      </Grid>
    </>
  );
};
