import { useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { ResponsiveContainer, PaperContainer } from '../../Components';
import { UserAction } from '../../Components/Organisms/Popups/UserActions/user-action.popup';
import { usePopup } from '../../Hooks';
import { UserProfileProvider } from '../../Providers';
import { beneficiaryProfileService } from '../../Services';
import { Popup } from '../../Theme';
import { ActionsProvider } from '../../Providers';

type TTabActionsComponentProps = {
  header: ReactNode;
  content: ReactNode;
  fetchProfile?: boolean;
};

export const UserInformations = ({ header, content, fetchProfile }: TTabActionsComponentProps) => {
  const { popupOpen } = usePopup('ActionPopup');
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));  

  if (fetchProfile) {
    return (
      <ActionsProvider uid='' userRole='consultant'>
        <UserProfileProvider specializedProfileService={beneficiaryProfileService}>
          <Popup open={popupOpen()} maxWidth="md" fullScreen={fullscreen}>
            <UserAction />  
          </Popup>
          <ResponsiveContainer>
            {header}
            <PaperContainer>{content}</PaperContainer>
          </ResponsiveContainer>
          
        </UserProfileProvider>
      </ActionsProvider>
    );
  }
  return (
    <ResponsiveContainer>
      {header}
      <PaperContainer>
        {content}
      </PaperContainer>
    </ResponsiveContainer>
  );
};
