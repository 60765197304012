import React from 'react';
import Typography from '@mui/material/Typography';
import { Header } from '../../../Components';
import Button from '@mui/material/Button';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import SettingsIcon from '../../../Assets/account_black.svg'

import { useStateMachine } from 'little-state-machine';
import { resetUserState, resetLoginState } from '../../../Stores';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { NewHeader } from '../../../Components/Molecules/new-title.component';

export const SettingsHeader = () => {

  const { actions, state: { user: { organism, admin }} } = useStateMachine({ resetUserState, resetLoginState });
  const navigate = useNavigate();

  const handleSignout = () => {
    actions.resetUserState();
    actions.resetLoginState();
    localStorage.removeItem("accessToken");
    localStorage.setItem('shouldCompleteProfile', 'true');
    navigate("/select-role");
  };  

  return (
    <>
    {(organism || admin) ? (
      <Stack direction='row' justifyContent='start' width='100%'>
        <NewHeader title='Mon compte' image={SettingsIcon} />
      </Stack>
    ) : (
      <Header
        title={
          <>
            <Typography variant="h1">
              Mon compte SAM.I
            </Typography>
          </>
        }
        actions={
          <Button variant="outlined" color="primary" onClick={handleSignout}>
            <LogoutOutlined />
            Se déconnecter
          </Button>
        }
      />
    )} 
    </>
  );
}
