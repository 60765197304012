import React from 'react';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import PersonOutline from '@mui/icons-material/PersonOutline';

interface IProfileAvatarProps extends AvatarProps {
  size: number;
  disabled?: boolean;
}

export const ProfileAvatar = ({ size, src, disabled }: IProfileAvatarProps) => {
  return (
    <Avatar
      src={src || undefined}
      sx={{
        width: size,
        height: size,
        color: (theme) => (disabled ? theme.palette.grey[400] : theme.secondaryPalette.purple),
        backgroundColor: (theme) => (disabled ? theme.palette.grey[100] : theme.secondaryPalette.lightPurple),
      }}
    >
      {!src && (
        <PersonOutline
          sx={{
            width: '60%',
            height: '60%',
          }}
        />
      )}
    </Avatar>
  );
};
