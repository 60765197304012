import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';

interface IHeaderProps {
  picture?: ReactNode;
  title?: ReactNode;
  actions?: ReactNode;
}

export const Header = ({ picture, title, actions }: IHeaderProps) => {
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  return (
    <Grid container direction="row" gap={4} wrap={isMd ? 'nowrap' : 'wrap'}>
      <Grid
        container
        item
        wrap={isMd ? 'nowrap' : 'wrap'}
        gap={2}
        justifyContent={isMd ? 'initial' : 'center'}
        textAlign={isMd ? 'start' : 'center'}
      >
        {picture && <Grid item>{picture}</Grid>}
        {title && (
          <Grid flexGrow={1} container direction="column" gap={1}>
            {title}
          </Grid>
        )}
      </Grid>
      {actions && (
        <Grid
          container
          item
          xs={12}
          md="auto"
          direction="row"
          alignContent={isMd ? 'flex-start' : 'center'}
          justifyContent={isMd ? 'flex-end' : 'center'}
          gap={2}
        >
          {actions}
        </Grid>
      )}
    </Grid>
  );
};
