import axios from "axios";
import { addDoc,
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
  updateDoc
} from "firebase/firestore";
import { db } from "../../../Configuration/firebase.config";
import { IAction, IActionHistory, TActionStatus, TActionStatusMap, TUserRole } from "../../../Types";


interface IActionPayload {
  recipientId: string;
  action: Partial<IAction>;
  history?: IActionHistory[];
}

class ActionService {
  private _db: Firestore;
  public readonly actionStatus: TActionStatusMap = {
    TODO: 'À réaliser',
    PENDING: 'En cours',
    DONE: 'Réalisée',
    ABANDONED: 'Abandonnée',
    REFUSED: 'Refusée',
    APPROVED: 'Validée',
    RECOMMENDED: 'Recommandée'
  };
  public readonly actionUpdates = {
    creation: {
      key: 'STATUS_CREATED',
      label: 'Création du dispositif',
    },
    status: {
      key: 'STATUS_CHANGED',
      label: 'Changement de status',
    },
    deviceType: {
      key: 'DEVICE_CHANGED',
      label: 'Changement de dispositif',
    },
    description: {
      key: 'DESCRIPTION_CHANGED',
      label: 'Changement de description',
    },
  };

  constructor(db: Firestore) {
    this._db = db;
  }

  private getDocRef(userRole: TUserRole, recipientId: string, actionId: string): DocumentReference<IAction> {
    return doc(
      this._db,
      `${userRole === 'beneficiary' ? 'beneficiary' : 'consultant'}/${recipientId}/action/${actionId}`,
    ) as DocumentReference<IAction>;
  }

  private getCollectionRef(uid: string, userRole: TUserRole): CollectionReference<IAction> {
    return collection(
      this._db,
      `${userRole === 'beneficiary' ? 'beneficiary' : 'consultant'}/${uid}/action`,
    ) as CollectionReference<IAction>;
  }

  private getHistoryCollectionRef(
    uid: string,
    userRole: TUserRole,
    actionId: string,
  ): CollectionReference<IActionHistory> {
    return collection(
      this._db,
      `${userRole === 'beneficiary' ? 'beneficiary' : 'consultant'}/${uid}/action/${actionId}/history`,
    ) as CollectionReference<IActionHistory>;
  }

  public async update(uid: string, payload: IActionPayload): Promise<void> {
    try {
      if (!uid || !payload || !payload?.action?.actionId) {
        throw Error('missing parameter in action update');
      }
      // 1) update IAction
      await updateDoc(
        this.getDocRef('beneficiary', payload.recipientId, payload.action.actionId),
        payload.action as never,
      );
      //2) check if doc exists for the user that is modyfing it
      const docRef = await getDoc(this.getDocRef('consultant', uid, payload.action.actionId));
      if (docRef.exists()) {
        await updateDoc(this.getDocRef('consultant', uid, payload.action.actionId), payload.action as never);
      } else {
        await setDoc(this.getDocRef('consultant', uid, payload.action.actionId), payload.action);
      }
      // 2) Update history
      if (payload?.action?.actionId) {
        await Promise.all([
          payload?.history?.map((actionHistory) =>
            addDoc(
              this.getHistoryCollectionRef(payload.recipientId, 'beneficiary', payload.action.actionId as string),
              actionHistory,
            )
              .then((data) => data)
              .catch((error) => console.error(error)),
          ),
        ]);
      }
    } catch (error) {
      throw error;
    }
  }

  public async getActionHistory(uid: string, actionId: string) {
    const q = query(this.getHistoryCollectionRef(uid, 'beneficiary', actionId), orderBy('updateDate', 'desc'));
    return await getDocs(q)
      .then((payload) => payload.docs.map((d) => d.data()))
      .catch((error) => console.error(error));
  }

  public async create(uid: string, payload: IActionPayload): Promise<void> {
    try {
      // Add doc to beneficiary action collection
      const beneficiaryActionRef = await addDoc(
        this.getCollectionRef(payload.recipientId, 'beneficiary'),
        payload.action,
      );
      // Add doc to consultant action collection too (for later use)
      await setDoc(this.getDocRef('consultant', uid, beneficiaryActionRef.id), payload.action);
      // Add initial history
      await Promise.all([
        payload?.history?.map((actionHistory) =>
          addDoc(
            this.getHistoryCollectionRef(payload.recipientId, 'beneficiary', beneficiaryActionRef.id),
            actionHistory,
          )
            .then((data) => data)
            .catch((error) => console.error(error)),
        ),
      ]);
    } catch (error) {
      throw error;
    }
  }

  public onActionsSnapshot(uid: string, userRole: TUserRole, callback: (data: IAction[]) => void): Unsubscribe {
    const q = query(this.getCollectionRef(uid, userRole));
    return onSnapshot(
      q,
      (querySnapshot) =>
        callback(
          querySnapshot.docs.map((actionSnapshot) => ({ ...actionSnapshot.data(), actionId: actionSnapshot.id })),
        ),
      (error) => {
        throw error;
      },
    );
  }

  public async read(uid: string, actionId: string): Promise<void | IAction> {
    return getDoc(this.getDocRef('consultant', uid, actionId))
      .then((doc) => doc.data() as IAction)
      .catch((error) => console.error('Error reading data', error));
  }

  public get actionStatusKeys(): TActionStatus[] {
    return Object.keys(this.actionStatus) as TActionStatus[];
  }

  public async export(id: string, actionIds: string[]) {
    if (!id || !actionIds) {
      throw Error('error missing params for actions export');
    }
    const { data, headers } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/v1/consultant/exportActions`, {
      id,
      actions: actionIds
    });
    if (headers['content-disposition']) {
      return {
        data,
        filename: headers['content-disposition'].split('filename=')[1].replace(/"/gu, ''),
      };
    }
    return {
      data,
      filename: 'data.csv'
    };
  }
}

export const actionService = new ActionService(db);
