import { createStore, GlobalState } from 'little-state-machine';
import { initialLoginSessionState } from './Login';
import { initialUserState } from './User';
import { initialScreenState } from './Screen';

const logStore = (store: GlobalState, callbackName: string, payload: unknown) => {
  if (process.env.NODE_ENV !== 'production') {
  }
  return store;
};

export const _createStore = () =>
  createStore(
    {
      isLoading: false,
      user: initialUserState,
      loginSession: initialLoginSessionState,
      screen: initialScreenState,
      actions: []
    },
    {
      name: 'appStore',
      middleWares: [logStore],
    },
  );
