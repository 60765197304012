import { GetBeneficiaryLightDto } from "./auth.types";
import { MetaDto } from "./user.types";

export type GetActionListDto = {
  data: GetActionLightDto[];
  meta: MetaDto;
  customMeta: GetActionMetaDto;
}

export type GetActionMetaDto = {
  toBeRealized: number;
  inProgress: number;
  completed: number;
  cancelled: number;
  refused: number;
  recommended: number;
  approved: number;
}

export type FilterActionDto = {
  status: ActionStatus;
  searchtext: string;
}

export type GetActionLightDto = {
  id: string;
  consultant: UserLightDto;
  beneficiary: GetBeneficiaryLightDto;
  type: ActionType;
  status: ActionStatus;
  createdAt?: Date;
  description?: string;
  updatedAt?: Date;
  isWorkArrest?: boolean;
}

export type CreateActionDto = {
  type: ActionType;
  description: string;
  status: ActionStatus;
}

export type GetActionDto = {
  id: string;
  consultant: UserLightDto;
  type: ActionType;
  status: string;
  description: string;
  actionHistories: GetActionHistoryDto[];
  createdAt: Date;
  updatedAt: Date;
}

export type GetActionHistoryDto = {
  id: string;
  author: UserLightDto;
  type: string;
  currentState: ActionStatus | ActionType;
  previousState: ActionStatus | ActionType;
  createdAt: string;
  organismName?: string;
}

export type UserLightDto = {
  firstName: string;
  lastName: string;
  organismName?: string;
  phoneNumber?: string;
  postOccupied?: string;
  id: string;
}

export enum ActionType {
  AAH = "AAH", 
  ACTION_LEVEL_UP = "ACTION_LEVEL_UP", 
  TRAINING_ASSISTANCE= "TRAINING_ASSISTANCE", 
  EMPLOYMENT_MAINTENANCE_ASSISTANCE = "EMPLOYMENT_MAINTENANCE_ASSISTANCE", 
  SKILLS_ASSESSMENT = "SKILLS_ASSESSMENT", 
  FUNCTIONAL_PROFESSIONAL_ASSESSMENT = "FUNCTIONAL_PROFESSIONAL_ASSESSMENT", 
  BILAN_UEROS = "BILAN_UEROS", 
  MOBILITY_INCLUSION_CARD = "MOBILITY_INCLUSION_CARD",
  CEP = "CEP", 
  CRPE = "CRPE", 
  EPAAST = "EPAAST", 
  FRAMEWORK_TEST = "FRAMEWORK_TEST", 
  WORKSTATION_STUDY = "WORKSTATION_STUDY", 
  TRAINING_CENTER_VOCATIONAL_EDUCATION = "TRAINING_CENTER_VOCATIONAL_EDUCATION", 
  TRAINING_TRANSITION_PRO = "TRAINING_TRANSITION_PRO", 
  TEMPORARY_INCAPACITY_ALLOWANCE = "TEMPORARY_INCAPACITY_ALLOWANCE", 
  INFOS_IJSS = "INFOS_IJSS", 
  INFO_RETIREMENT = "INFO_RETIREMENT", 
  IPF = "IPF", 
  CAREER_GUIDANCE = "CAREER_GUIDANCE", 
  OTHER = "OTHER", 
  NOT = "NOT", 
  NOT_AUDITORY_DISABILITY = "NOT_AUDITORY_DISABILITY", 
  NOT_CHRONIC_EVOLVING_DISABILITY = "NOT_CHRONIC_EVOLVING_DISABILITY", 
  COGNITIVE_DISABILITY_STEP = "COGNITIVE_DISABILITY_STEP",
  MOTOR_DISABILITY_STEP = "MOTOR_DISABILITY_STEP", 
  NOT_PSYCHIC_DISABILITY = "NOT_PSYCHIC_DISABILITY", 
  NOT_VISUAL_DISABILITY = "NOT_VISUAL_DISABILITY", 
  DISABLED_PENSION = "DISABLED_PENSION", 
  PMSMP = "PMSMP", 
  PROFESSIONAL_TRANSITION_PROJECT = "PROFESSIONAL_TRANSITION_PROJECT", 
  RDV_LIAISON = "RDV_LIAISON", 
  RETIREMENT_ANTICIPATION_LONG_CAREER = "RETIREMENT_ANTICIPATION_LONG_CAREER", 
  RETIREMENT_ANTICIPATION_UNFITNESS = "RETIREMENT_ANTICIPATION_UNFITNESS", 
  RETIREMENT_ANTICIPATION_OPENABILITY = "RETIREMENT_ANTICIPATION_OPENABILITY", 
  RQTH = "RQTH", 
  INTERNSHIP_PROFESSIONAL_ORIENTATION = "INTERNSHIP_PROFESSIONAL_ORIENTATION", 
  INTERNSHIP_UEROS = "INTERNSHIP_UEROS", 
  PART_TIME_THERAPEUTIC = "PART_TIME_THERAPEUTIC", 
  VAE = "VAE", 
  RETURN_VISIT = "RETURN_VISIT",
  SOCIO_PROFESSIONAL_INTERVIEW = "SOCIO_PROFESSIONAL_INTERVIEW",
  HELP_PROFESSIONAL_ILLNESS_DECLARATION_FILE = "HELP_PROFESSIONAL_ILLNESS_DECLARATION_FILE"
}

export namespace ActionType {
  export const actionTypes = (boeth: boolean, arrest: boolean): ActionType[] => {
    if (boeth && !arrest) {
      return [
        ActionType.RQTH,
        ActionType.MOBILITY_INCLUSION_CARD,
        ActionType.AAH,
        ActionType.INTERNSHIP_PROFESSIONAL_ORIENTATION,
        ActionType.PROFESSIONAL_TRANSITION_PROJECT,
        ActionType.TRAINING_CENTER_VOCATIONAL_EDUCATION,
        ActionType.IPF,
        ActionType.EPAAST,
        ActionType.NOT_VISUAL_DISABILITY,
        ActionType.NOT_AUDITORY_DISABILITY,
        ActionType.NOT_PSYCHIC_DISABILITY,
        ActionType.COGNITIVE_DISABILITY_STEP,
        ActionType.MOTOR_DISABILITY_STEP,
        ActionType.NOT_CHRONIC_EVOLVING_DISABILITY,
        ActionType.CEP,
        ActionType.SKILLS_ASSESSMENT,
        ActionType.TRAINING_ASSISTANCE,
        ActionType.EMPLOYMENT_MAINTENANCE_ASSISTANCE,
        ActionType.ACTION_LEVEL_UP,
        ActionType.DISABLED_PENSION,
        ActionType.PMSMP,
        ActionType.VAE,
        ActionType.PART_TIME_THERAPEUTIC,
        ActionType.WORKSTATION_STUDY,
        ActionType.INFOS_IJSS,
        ActionType.INFO_RETIREMENT,
        ActionType.RETIREMENT_ANTICIPATION_LONG_CAREER,
        ActionType.RETIREMENT_ANTICIPATION_UNFITNESS,
        ActionType.RETIREMENT_ANTICIPATION_OPENABILITY,
        ActionType.SOCIO_PROFESSIONAL_INTERVIEW,
        ActionType.HELP_PROFESSIONAL_ILLNESS_DECLARATION_FILE,
        ActionType.OTHER
      ];
    } else if (arrest && !boeth) {
      return [
        ActionType.FRAMEWORK_TEST,
        ActionType.RQTH,
        ActionType.MOBILITY_INCLUSION_CARD,
        ActionType.AAH,
        ActionType.SKILLS_ASSESSMENT,
        ActionType.CEP,
        ActionType.TRAINING_TRANSITION_PRO,
        ActionType.RETURN_VISIT,
        ActionType.ACTION_LEVEL_UP,
        ActionType.DISABLED_PENSION,
        ActionType.BILAN_UEROS,
        ActionType.FUNCTIONAL_PROFESSIONAL_ASSESSMENT,
        ActionType.VAE,
        ActionType.PART_TIME_THERAPEUTIC,
        ActionType.WORKSTATION_STUDY,
        ActionType.INFOS_IJSS,
        ActionType.INFO_RETIREMENT,
        ActionType.RETIREMENT_ANTICIPATION_LONG_CAREER,
        ActionType.RETIREMENT_ANTICIPATION_UNFITNESS,
        ActionType.RETIREMENT_ANTICIPATION_OPENABILITY,
        ActionType.TEMPORARY_INCAPACITY_ALLOWANCE,
        ActionType.SOCIO_PROFESSIONAL_INTERVIEW,
        ActionType.HELP_PROFESSIONAL_ILLNESS_DECLARATION_FILE,
        ActionType.OTHER,
      ];
    } else {
      return [
        ActionType.AAH, 
        ActionType.ACTION_LEVEL_UP, 
        ActionType.TRAINING_ASSISTANCE, 
        ActionType.EMPLOYMENT_MAINTENANCE_ASSISTANCE, 
        ActionType.SKILLS_ASSESSMENT, 
        ActionType.FUNCTIONAL_PROFESSIONAL_ASSESSMENT, 
        ActionType.BILAN_UEROS, 
        ActionType.MOBILITY_INCLUSION_CARD, 
        ActionType.CEP, 
        ActionType.CRPE, 
        ActionType.EPAAST, 
        ActionType.FRAMEWORK_TEST, 
        ActionType.WORKSTATION_STUDY, 
        ActionType.TRAINING_CENTER_VOCATIONAL_EDUCATION, 
        ActionType.TRAINING_TRANSITION_PRO, 
        ActionType.TEMPORARY_INCAPACITY_ALLOWANCE, 
        ActionType.INFOS_IJSS, 
        ActionType.INFO_RETIREMENT, 
        ActionType.IPF, 
        ActionType.CAREER_GUIDANCE, 
        ActionType.OTHER, 
        ActionType.NOT, 
        ActionType.NOT_AUDITORY_DISABILITY, 
        ActionType.NOT_CHRONIC_EVOLVING_DISABILITY, 
        ActionType.COGNITIVE_DISABILITY_STEP, 
        ActionType.MOTOR_DISABILITY_STEP, 
        ActionType.NOT_PSYCHIC_DISABILITY, 
        ActionType.NOT_VISUAL_DISABILITY, 
        ActionType.DISABLED_PENSION, 
        ActionType.PMSMP, 
        ActionType.PROFESSIONAL_TRANSITION_PROJECT, 
        ActionType.RDV_LIAISON, 
        ActionType.RETIREMENT_ANTICIPATION_LONG_CAREER, 
        ActionType.RETIREMENT_ANTICIPATION_UNFITNESS, 
        ActionType.RETIREMENT_ANTICIPATION_OPENABILITY, 
        ActionType.RQTH, 
        ActionType.INTERNSHIP_PROFESSIONAL_ORIENTATION, 
        ActionType.INTERNSHIP_UEROS, 
        ActionType.PART_TIME_THERAPEUTIC, 
        ActionType.VAE, 
        ActionType.RETURN_VISIT,
        ActionType.SOCIO_PROFESSIONAL_INTERVIEW,
        ActionType.HELP_PROFESSIONAL_ILLNESS_DECLARATION_FILE,
      ]
    }
  };

  export const ActionTypeBoeth: ActionType[] = [
    
  ]

  export const label = (type: ActionType | string): string => {
    switch (type) {
      case ActionType.AAH:
        return "Allocation Adulte Handicapé (AAH)";
      case ActionType.ACTION_LEVEL_UP:
        return "Action de remise à niveau";
      case ActionType.TRAINING_ASSISTANCE:
        return "Aide financière à la formation";
      case ActionType.EMPLOYMENT_MAINTENANCE_ASSISTANCE:
        return "Aide financière maintien dans l'emploi";
      case ActionType.SKILLS_ASSESSMENT:
        return "Bilan de compétences";
      case ActionType.FUNCTIONAL_PROFESSIONAL_ASSESSMENT:
        return "Bilan fonctionnel et professionnel";
      case ActionType.BILAN_UEROS:
        return "Bilan UEROS (Unité d'Evaluation de Ré entraînement et d'Orientation Sociale)";
      case ActionType.MOBILITY_INCLUSION_CARD:
        return "Carte mobilité inclusion (CMI)";
      case ActionType.CEP:
        return "Conseil en Évolution Professionnelle";
      case ActionType.CRPE:
        return "Convention de rééducation Professionnelle en Entreprise";
      case ActionType.EPAAST:
        return "Étude ergonomique (ex-EPAAST)";
      case ActionType.FRAMEWORK_TEST:
        return "Essai encadré";
      case ActionType.WORKSTATION_STUDY:
        return "Étude ergonomique aménagement du poste de travail";
      case ActionType.TRAINING_CENTER_VOCATIONAL_EDUCATION:
        return "Formation en centre de rééducation professionnelle";
      case ActionType.TRAINING_TRANSITION_PRO:
        return "Formation Transitions PRO/OPCO";
      case ActionType.TEMPORARY_INCAPACITY_ALLOWANCE:
        return "Indemnité temporaire d'inaptitude";
      case ActionType.INFOS_IJSS:
        return "Infos droits IJSS (Indemnités journalières versée par la Sécurité Sociale)";
      case ActionType.INFO_RETIREMENT:
        return "Infos droits retraites";
      case ActionType.IPF:
        return "Inclu Pro Formation(IPF)";
      case ActionType.CAREER_GUIDANCE:
        return "Orientation professionnelle";
      case ActionType.OTHER:
        return "Autre";
      case ActionType.NOT:
        return "Prestation d'Appui Spécifique (PAS)";
      case ActionType.NOT_AUDITORY_DISABILITY:
        return "Prestation d'Appui Spécifique (PAS) handicap auditif";
      case ActionType.NOT_CHRONIC_EVOLVING_DISABILITY:
        return "Prestation d'Appui Spécifique (PAS) maladies chroniques évolutives";
      case ActionType.COGNITIVE_DISABILITY_STEP:
        return "Prestation d'Appui Spécifique (PAS) handicap cognitif";
      case ActionType.MOTOR_DISABILITY_STEP:
        return "Prestation d'Appui Spécifique (PAS) handicap moteur";
      case ActionType.NOT_PSYCHIC_DISABILITY:
        return "Prestation d'Appui Spécifique (PAS) handicap psychique";
      case ActionType.NOT_VISUAL_DISABILITY:
        return "Prestation d'Appui Spécifique (PAS) handicap visuel";
      case ActionType.DISABLED_PENSION:
        return "Pension d'invalidité";
      case ActionType.PMSMP:
        return "PMSMP (Période de Mise en Situation en Milieu Professionnel)";
      case ActionType.PROFESSIONAL_TRANSITION_PROJECT:
        return "Projet de Transition Professionnelle";
      case ActionType.RDV_LIAISON:
        return "Rendez-vous de liaison";
      case ActionType.RETIREMENT_ANTICIPATION_LONG_CAREER:
        return "Retraite anticipée dispositif carrière longue";
      case ActionType.RETIREMENT_ANTICIPATION_UNFITNESS:
        return "Retraite anticipée pour inaptitude au travail";
      case ActionType.RETIREMENT_ANTICIPATION_OPENABILITY:
        return "Retraite anticipée dispositif pénibilité";
      case ActionType.RQTH:
        return "RQTH (Reconnaissance Qualité de Travailleur Handicapé)"
      case ActionType.INTERNSHIP_PROFESSIONAL_ORIENTATION:
        return "Stage de préorientation professionnelle";
      case ActionType.INTERNSHIP_UEROS:
        return "Stage UEROS";
      case ActionType.PART_TIME_THERAPEUTIC:
        return "Temps partiel thérapeutique";
      case ActionType.VAE:
        return "VAE (Validation des Acquis de l'Expérience)";
      case ActionType.RETURN_VISIT:
        return "Visite de pré-reprise";
      case ActionType.SOCIO_PROFESSIONAL_INTERVIEW:
        return 'Entretien socio professionnel';
      case ActionType.HELP_PROFESSIONAL_ILLNESS_DECLARATION_FILE:
        return 'Aide dossier déclaration maladie professionnelle';
      default:
        return ''
    }
  }
}

export enum ActionStatus {
  TO_BE_REALIZED = "TO_BE_REALIZED", 
  IN_PROGRESS = "IN_PROGRESS", 
  COMPLETED = "COMPLETED", 
  CANCELLED = "CANCELLED", 
  REFUSED = "REFUSED",
  RECOMMENDED = "RECOMMENDED",
  APPROVED = "APPROVED"
}

export namespace ActionStatus {
  export const label = (status: ActionStatus | string): string => {
    switch (status) {
      case ActionStatus.TO_BE_REALIZED:
        return "À réaliser";
      case ActionStatus.IN_PROGRESS:
        return "En cours";
      case ActionStatus.COMPLETED:
        return "Réalisée";
      case ActionStatus.CANCELLED:
        return "Abandonnée";
      case ActionStatus.REFUSED:
        return "Refusée";
      case ActionStatus.RECOMMENDED:
        return "Recommandée"
      case ActionStatus.APPROVED:
        return "Validée";
      default:
        return '';
    }
  }

  export const actionStatuses: ActionStatus[] = [
    ActionStatus.TO_BE_REALIZED, 
    ActionStatus.IN_PROGRESS, 
    ActionStatus.COMPLETED, 
    ActionStatus.CANCELLED, 
    ActionStatus.REFUSED,
    ActionStatus.RECOMMENDED,
    ActionStatus.APPROVED
  ];
}

export enum ActionState {
  STATUS_CREATED = "STATUS_CREATED",
  STATUS_CHANGED = "STATUS_CHANGED",
  DEVICE_CHANGED = "DEVICE_CHANGED",
  DESCRIPTION_CHANGED = "DESCRIPTION_CHANGED",
}

export namespace ActionState {
  export const label = (state: ActionState): string => {
    switch (state) {
      case ActionState.STATUS_CREATED:
        return 'Création du dispositif';
      case ActionState.STATUS_CHANGED:
        return 'Modification du dispositif';
      case ActionState.DEVICE_CHANGED:
        return '';
      case ActionState.DESCRIPTION_CHANGED:
        return 'Modification de la description';
    }
  };

  export const actionStates: ActionState[] = [
    ActionState.STATUS_CREATED,
    ActionState.STATUS_CHANGED,
    ActionState.DEVICE_CHANGED,
    ActionState.DESCRIPTION_CHANGED
  ]
}