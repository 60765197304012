import React from 'react';
import { Theme, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface ReadOnlyFieldProps {
  name: string;
  value?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  name: {
    color: 'rgb(155, 159, 164)',
    fontWeight: 500,
    alignSelf: 'start',
  },
  value: {
    color: 'black',
    fontWeight: 400,
    alignSelf: 'end'
  },
}));

export const ReadOnlyField = ({name, value}: ReadOnlyFieldProps) => {
  const classes = useStyles();
  return (
    <Stack direction="row" justifyContent="space-between" gap={3}>
      <Typography align='left' className={classes.name}>{name}</Typography>
      <Typography align='right' className={classes.value}>{value || '-'}</Typography>
    </Stack>
  )
}