import { Context, useContext } from 'react';
import { IProfileContextProps, IProfileLightContextProps } from '../../Providers';

export function useUserProfile<T>(context: Context<IProfileContextProps<T>>) {
  const { id, user, actions, availableDevices, selectedAction, selectAction } =
    useContext(context);
  return { id, user, actions, availableDevices, selectedAction, selectAction };
};

export function useUserLightProfile(context: Context<IProfileLightContextProps>) {
  const { user }  = useContext(context);
  return { user };
}