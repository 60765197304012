import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { Topbar, Sidepanel } from '../Components';
import { Main } from '../Theme';
import { useStateMachine } from 'little-state-machine';

type MainLayoutProps = {
  children: ReactElement;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const {
    state: { screen },
  } = useStateMachine();
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <Topbar></Topbar>
      <Sidepanel></Sidepanel>
      {/* Drawer here */}
      <Main open={screen.isSidePanelOpen}>{children}</Main>
    </Box>
  );
};
