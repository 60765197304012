import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface IActionDataCellProps {
  date: Date | undefined;
  newTable?: boolean;
}

export const ActionDateCell = ({ date, newTable }: IActionDataCellProps) => {
  const [formated, setFormated] = useState<{
    year: number;
    month: string;
    day: number;
  } | null>();

  useEffect(() => {
    if (date) {
      let month = date.toLocaleString('fr-Fr', { month: 'short' }).replace('.', '');
      month = month[0].toUpperCase() + month.slice(1, month.length);
      setFormated({
        year: date.getFullYear(),
        month,
        day: date.getDate(),
      });
    }
  }, [date]);

  if (!formated) {
    return null;
  }

  if (!date) {
    return <Typography>-</Typography>
  }

  return (
    <>
    {!newTable ? (
      <Grid item container justifyContent="center">
        <Grid item container justifyContent="center">
            <Typography variant="h3" margin={0}>
              {formated.day}
            </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Typography variant="subtitle1">
            {formated.month}&nbsp;{formated.year}
          </Typography>
        </Grid>
      </Grid>
      ) : (
      <Grid item container justifyContent="start" direction='row'>
        <Typography fontSize='12px' margin={0}>
          {formated.day} {formated.month}&nbsp;{formated.year}
        </Typography>
      </Grid>
    )}
    </>
  );
};
