import React, { useCallback, useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { BeneficiaryStatus, ContractEnum, Departments, GetBeneficiaryDto, LanguageLevel, LevelSchool, TBeneficiaryProfile } from '../../../../Types';
import { professionalInformations } from './forms.validation';
import { useStateMachine } from 'little-state-machine';
import { ApeSearchField } from '../../../Molecules';
import APIAxios, { APIRoutes } from '../../../../API/api.axios';
import { updateUserState } from '../../../../Stores';

export const ProInformationsForm = () => {
  const {
    state: {
      user: { id, beneficiary },
    },
    actions
  } = useStateMachine({ updateUserState });
  const formMethods = useForm<Omit<Partial<GetBeneficiaryDto>, "departmentLocation"> & { departmentLocation: string | number }>({
    resolver: yupResolver(professionalInformations),
    mode: 'onChange',
  });
  const { control, handleSubmit, reset } = formMethods;
  const [isModifying, setModifying] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (data: Omit<Partial<GetBeneficiaryDto>, "departmentLocation"> & { departmentLocation: string | number }) => {
      try {
        const res = await APIAxios({...APIRoutes.PATCHBeneficiaries(), data: {
           ...data,
           languageLevel: data.languageLevel === '' ? undefined : data.languageLevel,
           status: data.status === '' ? undefined : data.status,
           contract: data.contract === '' ? undefined : data.contract,
           levelSchool: data.levelSchool === '' ? undefined : data.levelSchool,
           ape: data.ape === '' ? undefined : data.ape,
        }})
        actions.updateUserState({ beneficiary: res.data });
        setModifying(false);
      } catch (err) {
      }
    },
    [id],
  );

    const departmentsArray = useMemo(() => {
      return Array.from({ length: 95 }, (_, i) => ((i + 1) as number).toString().padStart(2, '0'));
    }, []);

  const handleClick = () => {
    if (isModifying) {
      reset({ ...beneficiary, departmentLocation: '' });
    }
    setModifying(!isModifying);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container gap={4}>
        <Grid item container justifyContent="space-between" alignItems="center" flexWrap="nowrap" gap={2}>
          <Typography variant="h3">Informations professionnelles</Typography>
          <Button variant="text" color={isModifying ? 'secondary' : 'primary'} onClick={handleClick}>
            {isModifying ? 'Annuler' : 'Modifier'}
          </Button>

        </Grid>
        <Grid item container gap={2}>
          <Controller
            name="status"
            defaultValue={beneficiary?.status || ''}
            control={control}
            shouldUnregister={true}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!isModifying}
                select
                fullWidth
                type="date"
                color="primary"
                placeholder="Statut"
                label="Statut"
              >
                {BeneficiaryStatus.beneficiaryStatuses.map((status) => (
                  <MenuItem value={status} key={status}>
                    {BeneficiaryStatus.label(status)}
                  </MenuItem>
              ))}
              </TextField>
            )}
          />
          <Controller
            name="job"
            defaultValue={beneficiary?.job || ''}
            control={control}
            shouldUnregister={true}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!isModifying}
                fullWidth
                type="text"
                color="primary"
                placeholder="Poste occupé"
                label="Poste occupé"
              ></TextField>
            )}
          />
          <FormProvider {...formMethods}>
            <Controller
              shouldUnregister={true}
              control={control}
              name="ape"
              // TODO: ajouter le picker de code ape 
              defaultValue={beneficiary?.ape || ''}
              render={({ field: { value, onChange } }) => (
                <ApeSearchField value={value} onChange={onChange} disabled={!isModifying} />
              )}
            />
          </FormProvider>
          <Controller
            name="contract"
            defaultValue={beneficiary?.contract || ''}
            control={control}
            shouldUnregister={true}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!isModifying}
                fullWidth
                select
                type="text"
                color="primary"
                placeholder="Type de contrat"
                label="Type de contrat"
              >
                {ContractEnum.contracts.map((it) => (
                  <MenuItem value={it} key={it}>
                  {ContractEnum.label(it)}
                  </MenuItem>
              ))}
              </TextField>
            )}
          />
          <Controller
            name="departmentLocation"
            defaultValue={beneficiary?.departmentLocation || ''}
            control={control}
            shouldUnregister={true}
            render={({ field }) => (
              <TextField
                disabled={!isModifying}
                {...field}
                select
                fullWidth
                type="text"
                color="primary"
                placeholder="Département du lieu de travail"
                label="Département du lieu de travail"
              >
                {Departments.AllDepartments.map((dep) => (
                  <MenuItem value={dep} key={dep}>
                    {dep + ' ' + Departments.label(dep)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="formation"
            defaultValue={beneficiary?.formation || ''}
            control={control}
            shouldUnregister={true}
            render={({ field }) => (
              <TextField
                disabled={!isModifying}
                {...field}
                fullWidth
                type="text"
                color="primary"
                placeholder="Formation"
                label="Formation"
              ></TextField>
            )}
          />
          <Controller
            name="levelSchool"
            defaultValue={beneficiary?.levelSchool || ''}
            control={control}
            shouldUnregister={true}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!isModifying}
                fullWidth
                select
                type="text"
                color="primary"
                placeholder="Niveau scolaire"
                label="Niveau scolaire"
              >
                {LevelSchool.levelSchools.map((it) => (
                  <MenuItem value={it} key={it}>
                  {LevelSchool.label(it)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="language"
            defaultValue={beneficiary?.language || ''}
            control={control}
            shouldUnregister={true}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!isModifying}
                fullWidth
                type="text"
                color="primary"
                placeholder="Langue"
                label="Langue"
              ></TextField>
            )}
          />
          <Controller
            name="languageLevel"
            defaultValue={beneficiary?.languageLevel || ''}
            control={control}
            shouldUnregister={true}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!isModifying}
                fullWidth
                select
                type="text"
                color="primary"
                placeholder="Niveau de langue"
                label="Niveau de langue"
              >
                {LanguageLevel.languageLevels.map((it) => (
                  <MenuItem value={it} key={it}>
                    {LanguageLevel.label(it)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid container>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={!isModifying}
            onClick={handleSubmit(onSubmit)}
          >
            Enregistrer les modifications
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
