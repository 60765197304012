import {
  ALL_DEVICES,
  DEVISE_BOETH,
  DEVISE_BOETH_ARREST,
  DEVISE_NOT_BOETH,
  DEVISE_NOT_BOETH_ARREST
} from '../../Configuration/devices.config';
import { StringAccessor, TBeneficiaryProfile } from '../../Types';

export class DevicesService {
  private _allDevices = new Map<string, string>();
  private _devicesBoeth = new Map<string, string>();
  private _devicesBoethArrest = new Map<string, string>();
  private _devicesNotBoeth = new Map<string, string>();
  private _devicesNotBoethArrest = new Map<string, string>();

  constructor(allDevices: StringAccessor) {
    for (const key in allDevices) {
      if (DEVISE_BOETH.includes(key)) {
        this._devicesBoeth.set(key, allDevices[key]);
      }
      if (DEVISE_BOETH_ARREST.includes(key)) {
        this._devicesBoethArrest.set(key, allDevices[key]);
      }
      if (DEVISE_NOT_BOETH.includes(key)) {
        this._devicesNotBoeth.set(key, allDevices[key]);
      }
      if (DEVISE_NOT_BOETH_ARREST.includes(key)) {
        this._devicesNotBoethArrest.set(key, allDevices[key]);
      }
      this._allDevices.set(key, allDevices[key]);
    }
  }

  public get devicesBoeth() {
    return this._devicesBoeth;
  }

  public get devicesBoethArrest() {
    return this._devicesBoethArrest;
  }

  public get devicesNotBoeth() {
    return this._devicesNotBoeth;
  }

  public get devicesNotBoethArrest() {
    return this._devicesNotBoethArrest;
  }

  /* Returns the text of the device */
  public getDeviceValue(key: string): string | undefined {
    return this._allDevices.get(key);
  }

  public getAvailableDevices(profile: TBeneficiaryProfile): Map<string, string> {
    if (profile.isWorkArrest) {
      if (profile?.isBOE) {
        return this._devicesBoethArrest;
      }
      return this._devicesNotBoethArrest
    } else if (!profile.isWorkArrest) {
      if (profile.isBOE) {
        return this._devicesBoeth;
      }
      return this._devicesNotBoeth;
    }
    return this._allDevices;
  }
}

export const devicesService = new DevicesService(ALL_DEVICES);
