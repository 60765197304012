/**
 * This service deals with authentication, user account creation
 */
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { auth } from '../../Configuration/firebase.config';
import {
  User,
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  signInWithCredential,
  updatePassword,
  deleteUser,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from 'firebase/auth';
import {
  TLoginCredentials,
  TPasswordCredentials,
  TUserProfile,
  TUserRole,
} from '../../Types';
import { updateUserState, resetUserState } from '../../Stores';
import { useStateMachine } from 'little-state-machine';

import { userProfileService, beneficiaryProfileService, consultantProfileService } from '../User';
import { FirebaseError } from 'firebase/app';

interface IFirebaseAuthContextProps {
  children: ReactNode;
  addError: (error: any) => void;
}
export const FirebaseAuthContext = ({ children, addError }: IFirebaseAuthContextProps) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const {
    state: {
      user: {
        id,
      },
      loginSession: { createAccountValues },
    },
    actions,
  } = useStateMachine({ updateUserState, resetUserState });

  const updateUserProfileState = useCallback(
    (data: TUserProfile) => {
      if (data) {
        actions.updateUserState({
          isAuth: true,
          id: user?.uid,
          // profile: {
          //   ...data,
          // },
        });
      }
    },
    [actions, user?.uid],
  );

  // const updateBeneficiaryProfileState = useCallback(
  //   (data: Partial<TBeneficiaryProfile>) => {
  //     if (data) {
  //       actions.updateUserState({
  //         // beneficiaryProfile: { ...data },
  //       });
  //     }
  //   },
  //   [actions],
  // );

  // const updateConsultantProfileState = useCallback(
  //   (data: Partial<TConsultantProfile>) => {
  //     if (data) {
  //       actions.updateUserState({
  //         // consultantProfile: { ...data },
  //       });
  //     }
  //   },
  //   [actions],
  // );

  // const updateInvitationState = useCallback(
  //   (data: TInvitation[]) => {
  //     if (data) {
  //       actions.updateUserState({
  //         // invitations: data,
  //       });
  //     }
  //   },
  //   [actions],
  // );

  useEffect(() => {
    if (user && user.uid) {
      const unsub = userProfileService.callbackOnSnapshot(user.uid, updateUserProfileState);
      return () => unsub();
    }
  }, [user, updateUserProfileState]);

  /**
   * This hook use the isConsultant / uid from the state
   * It is called after the previous hook to load beneficiary or consultant
   * profile informations from db.
   *
   * It subscribes to the collection to have real time updates from it.
   */
  // useEffect(() => {
  //   let unsub: Unsubscribe | null;
  //   if (id) {
  //     if (!!isConsultant) {
  //       unsub = consultantProfileService.callbackOnSnapshot(uid, updateConsultantProfileState);
  //     } else {
  //       unsub = beneficiaryProfileService.callbackOnSnapshot(uid, updateBeneficiaryProfileState);
  //     }
  //   }
  //   return () => {
  //     if (unsub) unsub();
  //   };
  // }, [isConsultant, uid, updateBeneficiaryProfileState, updateConsultantProfileState]);

  // useEffect(() => {
  //   let unsub: Unsubscribe | null;
  //   if (uid) {
  //     if (!!isConsultant) {
  //       unsub = consultantProfileService.onInvitationSnapshot(uid, 'consultant', updateInvitationState);
  //     } else {
  //       unsub = beneficiaryProfileService.onInvitationSnapshot(uid, 'beneficiary', updateInvitationState);
  //     }
  //   }
  //   return () => {
  //     if (unsub) unsub();
  //   };
  // }, [isConsultant, uid, updateInvitationState]);

  /**
   * Only for consultant that are creating autonomous accounts.
   * This account is intented to be locked and to ensure his profile and consultant documents
   * are created, we invoke this callback before loging him out.
   */
  const handleCreateProfileAndSignoutLockedUser = useCallback(async () => {
    // if (isLocked && user?.uid) {
    //   const [userExists, consultantProfileExists] = await Promise.all([
    //     userProfileService.profileExists(user.uid),
    //     consultantProfileService.profileExists(user.uid),
    //   ]);
    //   if (!userExists) {
    //     await userProfileService.create(user.uid, createAccountValues as Required<TAccountCreateData>);
    //   }
    //   if (!consultantProfileExists) {
    //     await consultantProfileService.create(user.uid);
    //   }
    //   addError({ code: 'account-locked' });
    //   signOutUser();
    // }
  }, [user, createAccountValues, addError]);

  useEffect(() => {
    handleCreateProfileAndSignoutLockedUser();
  }, [handleCreateProfileAndSignoutLockedUser]);

  return <>{children}</>;
};

/**
 *
 * @param createUserData
 * @returns
 *
 * TODO check if user is signing up as beneficiary or consultant (not invited) if the email is already used
 * for an invitation code
 *    - YES -> throw an error (email already used)
 */
// export const createUserAccount = async (createUserData: Required<TAccountCreateData>): Promise<void> => {
//   try {
//     //1) create user account
//     const {
//       user: { uid },
//     } = await createUserWithEmailAndPassword(auth, createUserData.email, createUserData.password);
//     //2) create user profile
//     await userProfileService.create(uid, createUserData);
//     //3) create beneficiary or consultant profile
//     if (createUserData.isConsultant) {
//       await consultantProfileService.create(uid);
//       if (createUserData?.invitations?.length) {
//         const invitations = createUserData.invitations;
//         const invitationsPromises = invitations?.map((invitation) => {
//           return beneficiaryProfileService.createInvitation(invitation.from, {
//             message: invitation.message || '',
//             senderFullName: invitation.user,
//             searchUser: {
//               email: '',
//               firstname: '',
//               lastname: '',
//               uid: uid,
//             },
//             userMail: '',
//           });
//         });
//         await Promise.all(invitationsPromises);
//       }
//     } else if (!createUserData.isConsultant) {
//       await beneficiaryProfileService.create(uid);
//       const { invitations } = await verifyInvitationCode({ email: createUserData.email, invitationCode: '' });
//       if (invitations?.length) {
//         const invitationsPromises = invitations.map((invitation) => {
//           return consultantProfileService.createInvitation(invitation.from, {
//             message: invitation.message || '',
//             senderFullName: invitation.user,
//             searchUser: {
//               email: '',
//               firstname: '',
//               lastname: '',
//               uid: uid,
//             },
//             userMail: '',
//           });
//         });
//         await Promise.all(invitationsPromises);
//       }
//     }
//     //4) if user is in authorization list, delete authorization
//     if (!!(await userIsInAuthorizationList({ email: createUserData.email }))) {
//       await deleteInvitationOnUserCreated(createUserData.email);
//     }
//   } catch (error) {
//     console.error('error creating user', error);
//     throw error;
//   }
// };

export const deleteUserAccount = async (userRole: TUserRole) => {
  try {
    const user = auth.currentUser;
    const uid = auth.currentUser?.uid;
    if (!user || !uid) {
      throw Error('impossible to delete user');
    }
    await Promise.all([userProfileService.delete(uid, userRole), deleteUser(user)]);
  } catch (error) {
    if ((error as FirebaseError).code === 'auth/requires-recent-login') {
      throw (error as FirebaseError).code;
    }
    throw error;
  }
};

export const authUserWithCredentials = async (credentials: TLoginCredentials) => {
  return await signInWithEmailAndPassword(auth, credentials.email, credentials.password);
};

/**
 * When doing a task such as changing a user's password and that the auth token
 * sends a CREDENTIAL_TOO_OLD_LOGIN_AGAIN error, use this function to recover and
 * fullfill the operation.
 * @param credentials
 * @returns `true` if operation is successful
 */
export const reauthUserWithCredentials = async (credentials: TPasswordCredentials) => {
  try {
    const { email } = auth?.currentUser || {};
    if (!email) throw Error(`email not found`);
    const authCred = EmailAuthProvider.credential(email, credentials.password);
    const { user } = await signInWithCredential(auth, authCred);
    await reauthenticateWithCredential(user, authCred);
    return true;
  } catch (error) {
    throw error;
  }
};

export const changeUserPassword = async (credentials: TPasswordCredentials) => {
  try {
    const user = auth?.currentUser;
    if (!user) {
      throw Error(`impossible to find user`);
    }
    await updatePassword(user, credentials.password);
    return await reauthUserWithCredentials(credentials);
  } catch (error) {
    throw (error as any).code;
  }
};

export const sendResetPasswordMail = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    throw error;
  }
};

export const handleResetPassword = async (actionCode: string, newPassword: string) => {
  try {
    await verifyPasswordResetCode(auth, actionCode);
    await confirmPasswordReset(auth, actionCode, newPassword);
  } catch (error) {
    throw error;
  }
};

export const checkUserPassword = (input: string) => auth?.currentUser?.email === input;

export const getUidFromAuth = () => auth?.currentUser?.uid;

export const signOutUser = () => {
  localStorage.removeItem('accessToken');
  // signOut(auth);
};
