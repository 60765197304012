import React from "react";
import { Stack } from "@mui/material";
import { Pagination } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";

export interface PaginationProps {
  page: number;
  onChange: () => void;
  pageCount: number;
}

export const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const { state } = apiRef.current;

  return (
    <Stack spacing={2} alignItems="end" width="100%" justifyContent="center">
      <Pagination
        color="primary"
        shape="rounded"
        variant="text"
        size="small"
        boundaryCount={state.pagination.pageCount}
        count={state.pagination.pageCount}
        page={state.pagination.page === 0 ? 1 : state.pagination.page + 1}
        onChange={(event, value) => {
          apiRef.current.setPage(value - 1);
        }}
      />
    </Stack>
  );
};

export const CustomPagination2 = () => {
  const apiRef = useGridApiContext();
  const { state } = apiRef.current;

  return (
    <Stack spacing={2} alignItems="end" width="100%" justifyContent="center">
      <Pagination
        color="primary"
        shape="rounded"
        variant="text"
        size="small"
        boundaryCount={state.pagination.pageCount}
        count={state.pagination.pageCount}
        page={state.pagination.page === 0 ? 1 : state.pagination.page - 1}
        onChange={(event, value) => {
          apiRef.current.setPage(value === 0 ? 1 : value + 1);
        }}
      />
    </Stack>
  );
};