import { styled } from '@mui/material/styles';

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  // display: 'flex',
  // flexGrow: 1,
  width: '100%',
  marginTop: theme.customShapes.topbarHeight,
  marginBottom: 0,
  padding: theme.spacing(6),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6)
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    marginBottom: theme.customShapes.topbarHeight,
    padding: theme.spacing(2)
  }
}));
