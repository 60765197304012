import { TAccountCreateData, TLoginSessionState } from '../../Types';

export const initialCreateAccoundData: TAccountCreateData = {
  email: '',
  firstName: '',
  lastName: '',
};

export const initialLoginSessionState: TLoginSessionState = {
  createAccountValues: initialCreateAccoundData,
};
