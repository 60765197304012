import React, { useCallback, useEffect, useState } from 'react';
import { Add, WorkOutline } from '@mui/icons-material';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Outlet, useNavigate } from 'react-router-dom';
import APIAxios, { APIRoutes } from '../../../API/api.axios';
import { NewDataTableHeader, ResponsiveContainer } from '../../../Components';
import { useDatatableSearch } from '../../../Hooks';
import { DatatableSearchProvider } from '../../../Providers';
import { OrganismRow, organismsDataTableService } from '../../../Services/DataTable/Organisms/organisms.data-table.service';
import { CustomPagination } from '../../../Theme';
import { GetOrganismListDto } from '../../../Types';
import { ClickableDatatableCell } from '../../MyBeneficiaries/Components';
import OrganismIcon from '../../../Assets/organism_black.svg';
import { NewHeader } from '../../../Components/Molecules/new-title.component';

const columns: GridColDef[] = [
  {
    field: 'profile',
    headerName: 'Organisme',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => <NewDataTableHeader headerName="Organisme" />,
    renderCell: (params: GridCellParams) => 
      <Grid container gap={2} flexWrap='nowrap' alignItems='center'>
        {params.value.photoUrl && (
          <img src={params.value.photoUrl} alt='' />
        )}
        <Typography fontSize="12px" fontWeight='500'>
          {params.value.name}
        </Typography>
      </Grid>
  },
  {
    field: 'consultantCount',
    headerName: 'Consultants',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => <NewDataTableHeader headerName="Consultants" />,
    renderCell: (params: GridCellParams) => 
    <Typography fontSize="12px" fontWeight='400'>{params.value}</Typography>,
  },
  {
    field: 'beneficiaryCount',
    headerName: 'Bénéficiaires',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => <NewDataTableHeader headerName="Bénéficiaires" />,
    renderCell: (params: GridCellParams) => 
    <Typography fontSize="12px" fontWeight='400'>{params.value}</Typography>,
  },
  {
    field: 'actionCount',
    headerName: 'Nombre de dispositifs',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => <NewDataTableHeader headerName="Nombre de dispositifs" />,
    renderCell: (params: GridCellParams) => 
    <Typography fontSize="12px" fontWeight='400'>{params.value}</Typography>,
  },
  {
    field: 'info',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => <NewDataTableHeader headerName="Fiche info" />,
    renderCell: (params: GridRenderCellParams) => 
    <ClickableDatatableCell action="organism-read" {...params} />
  },
];

const DataTable = () => {

  const { search } = useDatatableSearch();

  const [organisms, setOrganisms] = useState<GetOrganismListDto | undefined>(undefined);
  const [page, setPage] = useState(1);
  const [data, setData] = useState<OrganismRow[] | undefined>(undefined);

  const getOrganisms = useCallback(async () => {
    try {
      const res = await APIAxios({...APIRoutes.GETOrganisms(page, search)});
      if (res.data) {
        setOrganisms(res.data);
        const data = organismsDataTableService.buildRows(res.data.data);
        setData(data);
      }
    } catch (err) {
    }
  }, [page, search]);

  useEffect(() => {
    getOrganisms();
  }, [getOrganisms]);

  if (data === undefined) return <div>Chargement ...</div>;

  return (
    <DataGrid
      rows={data}
      columns={columns}
      onPageChange={setPage}
      pageSize={organisms?.meta.take}
      autoHeight
      disableSelectionOnClick
      density='standard'
      rowCount={organisms?.meta.itemCount}
      paginationMode='server'
      components={{
        Pagination: CustomPagination
      }}
      sx={{
        '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
          visibility: 'hidden',
        },
        '&.MuiDataGrid-root': {
          border: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-virtualScroller': {
          marginTop: '32px !important',
          backgroundColor: 'white !important',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
          backgroundColor: 'white !important',
          height: '35px !important',
          maxHeight: '35px !important',
          minHeight: '35px !important',
          fontSize: '10px',
          fontWeight: '500',
        },
        '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
          border: '0px solid transparent'
        },
        '.& MuiDataGrid-columnHeaders': {
          fontSize: '10px',
          fontWeight: '500',
        },
        '&.MuiDataGrid-root *:focus-within': {
          outline: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
          overflow: 'hidden',
          whiteSpace: 'normal',
          color: 'rgb(155, 159, 164)',
          footWeight: '500',
          display: 'flex',
          justifyContent: 'start',
          fontSize: '10px',
          textAlign: 'start',
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'start',
          whiteSpace: 'normal',
          textAlign: 'start',
          borderTop: '1px solid rgb(233, 233, 233)',
          borderBottom: '0px'
        },
      }}
     />
  )
}

export const OrganismHeader = () => {

  const { search, updateSearch } = useDatatableSearch();
  const navigate = useNavigate();

  return (
    <Stack width='100%' gap={2}>
      <NewHeader title={'Organismes'} image={OrganismIcon} />
        <Grid container direction='row' gap={2}>
          <Grid item xs>
            <TextField
              fullWidth
              value={search}
              onChange={(e) => updateSearch(e.target.value)}
              label="Rechercher un organisme"
              placeholder="Rechercher un organisme"
            />
          </Grid>
          <Grid item>
            <Button variant='contained' onClick={() => navigate('create')} startIcon={<Add />}>Ajouter un organisme</Button>
          </Grid>
        </Grid>
      </Stack>
  )
}

export const OrganismsTableComponent = () => {


  return (
    <>
    <ResponsiveContainer>
      <DatatableSearchProvider>
        <OrganismHeader/>
        <DataTable />
      </DatatableSearchProvider>
    </ResponsiveContainer>
    <Outlet/>
    </>
  )
}