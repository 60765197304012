import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Header } from '../../../Components';
import { useDatatableSearch, usePopup } from '../../../Hooks';
import { useStateMachine } from 'little-state-machine';
import AddIcon from '@mui/icons-material/Add';
import PersonOutline from '@mui/icons-material/PersonOutline';
import { Stack } from '@mui/material';

export const BeneficiariesHeader = () => {
  const {
    state: {
      user: {
        consultant,
        admin,
      }
    }
  } = useStateMachine();

  const { search, updateSearch } = useDatatableSearch();
  const { openPopup } = usePopup('CreateInvitationPopup');
  return (
    <Header
      title={
        <>
          <Stack direction='row' spacing={1} alignItems="center">
            {admin && (<PersonOutline sx={{width: '35px', height: '35px'}} />)}
            <Typography variant="h1" component="h1">
            {admin ? 'Bénéficiaires' : 'Mes bénéficiaires'}
            </Typography>
          </Stack>
          {!admin && (<Typography variant="h3" color="GrayText">
            Vous accompagnez ces bénéficiaires dans leur parcours de projet de maintien / retour à l&apos;emploi.
          </Typography>)}
        </>
      }
      actions={
        <>
          <Button color="primary" variant="contained" onClick={() => openPopup()} disabled={!consultant?.isPremium}>
            <AddIcon style={{ marginRight: 8 }} />
            Inviter un bénéficiaire
          </Button>
          <TextField
            value={search}
            onChange={(e) => updateSearch(e.target.value)}
            label="Rechercher un(e) bénéficiaire"
            placeholder="Rechercher un(e) bénéficiaire"
          ></TextField>
        </>
      }
    ></Header>
  );
};
