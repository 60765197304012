import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses, CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface IProgressSpinnerProps extends CircularProgressProps {
  value: number;
}

export const ProgressSpinner = ({ value, ...other }: IProgressSpinnerProps) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#FFFFFF31',
        }}
        size={86}
        thickness={5}
        {...other}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        value={value}
        sx={{
          position: 'absolute',
          color: (theme) => theme.palette.background.paper,
          animationDuration: '550ms',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={86}
        thickness={5}
        {...other}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h3" component="div" color="white">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};
