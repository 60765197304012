import React, { useEffect, useState } from "react";
import {
  DataTableHeader,
  PaperContainer,
  ResponsiveContainer,
} from "../../Components";
import {
  MyActionsHeader,
  ActionDateCell,
  MyActionBeneficiary,
  MyActionDescription,
  MyActionsFilter,
} from "./Components";
import { Typography, Theme } from "@mui/material";
import { useStateMachine } from "little-state-machine";
import { useActions } from "../../Hooks";
import { ActionsProvider } from "../../Providers";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  gridDateComparator,
} from "@mui/x-data-grid";
import { CustomPagination } from "../../Theme";
import { IMyActionsRow, myActionsDataTableService } from "../../Services";

import { makeStyles } from "@mui/styles";
import { ActionStatus, ActionType } from "../../Types/actions.type";
import { NewActionsDataTable } from "./Components/new-actions-table.component";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  todo: {
    color: theme.secondaryPalette.orange,
    backgroundColor: theme.secondaryPalette.lightOrange,
  },
  pending: {
    color: theme.secondaryPalette.purple,
    backgroundColor: theme.secondaryPalette.lightPurple,
  },
  done: {
    color: theme.palette.primary.main,
    backgroundColor: theme.secondaryPalette.lightGreen,
  },
  abandoned: {
    color: theme.secondaryPalette.yellow,
    backgroundColor: theme.secondaryPalette.lightYellow,
  },
  refused: {
    color: theme.secondaryPalette.red,
    backgroundColor: theme.secondaryPalette.lightRed,
  },
  approved: {
    color: '#66B44A',
    backgroundColor: '#F0FCED'
  },
  recommended: {
    color: '#9B4085',
    backgroundColor: '#F9EEF7',
  }
}));

const MyActionsStatusLabel = ({ status }: { status: ActionStatus }) => {
  const classes = useStyles();

  const getClass = (status: ActionStatus) => {
    if (status === ActionStatus.TO_BE_REALIZED) {
      return classes.todo;
    } else if (status === ActionStatus.IN_PROGRESS) {
      return classes.pending;
    } else if (status === ActionStatus.CANCELLED) {
      return classes.abandoned;
    } else if (status === ActionStatus.REFUSED) {
      return classes.refused;
    } else if (status === ActionStatus.RECOMMENDED) {
      return classes.recommended;
    } else if (status === ActionStatus.APPROVED) {
      return classes.approved;
    } else {
      return classes.done;
    }
  };
  return (
    <Typography
      textAlign="start"
      variant="body2"
      className={`${classes.label} capital ${getClass(status)}`}
    >
      {status ? ActionStatus.label(status) : "-"}
    </Typography>
  );
};

const columns: GridColDef[] = [
  {
    field: "createdAt",
    headerName: "Création de l'action",
    sortable: false,
    disableColumnMenu: true,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
    sortComparator: gridDateComparator,
    renderHeader: () => <DataTableHeader headerName="Création de l'action" />,
    renderCell: (params: GridCellParams<Date>) => (
      <ActionDateCell date={new Date(params?.value ?? "")} />
    ),
  },
  {
    field: "beneficiary",
    headerName: "Bénéficiaire",
    sortable: false,
    disableColumnMenu: true,
    align: "left",
    minWidth: 200,
    headerAlign: "left",
    renderHeader: () => <DataTableHeader headerName="Bénéficiaire" />,
    renderCell: (params: GridCellParams) => {
      return (
        <MyActionBeneficiary
          name={params.value.name}
          userId={params.value.beneficiaryId}
        />
      );
    },
  },
  {
    field: "type",
    headerName: "Type d'action",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    align: "left",
    headerAlign: "left",
    renderHeader: () => <DataTableHeader headerName="Type d'action" />,
    renderCell: (params: GridCellParams) => (
      <Typography textAlign="start" variant="body1" className="capital">
        {ActionType.label(params.value)}
      </Typography>
    ),
  },
  {
    field: "description",
    headerName: "Description",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    align: "left",
    headerAlign: "left",
    renderHeader: () => <DataTableHeader headerName="Description" />,
    renderCell: (params: GridCellParams) => (
      <MyActionDescription description={params.value} />
    ),
  },
  {
    field: "status",
    sortable: false,
    disableColumnMenu: true,
    minWidth: 150,
    align: "left",
    headerAlign: "left",
    renderHeader: () => <DataTableHeader headerName="Statut" />,
    renderCell: (params: GridCellParams) => (
      //   <Typography textAlign="start" variant="body2" className={`${ActionStatus.label(params.value)} capital ${params.value}`}>
      //     {ActionStatus.label(params.value)}
      // </Typography>
      <MyActionsStatusLabel status={params.value as ActionStatus} />
    ),
  },
];

const Actions = () => {
  const { actions, setPage } = useActions();
  const [data, setData] = useState<IMyActionsRow[] | undefined>(undefined);

  useEffect(() => {
    if (actions?.data) {
      const rows = myActionsDataTableService.buildRows(actions.data);
      setData(rows);
    }
  }, [actions]);

  if (!data) {
    return <div>Chargement de vos actions...</div>;
  }

  return (
    <>
      <DataGrid
        rows={data}
        rowHeight={100}
        columns={columns}
        pageSize={25}
        onPageChange={setPage}
        autoHeight
        disableSelectionOnClick
        density="comfortable"
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-columnSeparator": {
            visibility: "hidden",
          },
          "&.MuiDataGrid-root": {
            border: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
            borderBottom: "1px solid #e9e9e9",
          },
          "&.MuiDataGrid-root .MuiDataGrid-footerContainer": {
            borderTop: "0px solid #e9e9e9",
          },
          "&.MuiDataGrid-root *:focus-within": {
            outline: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
            overflow: "hidden",
            whiteSpace: "normal",
            textAlign: "center",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell": {
            overflow: "hidden",
            whiteSpace: "normal",
            textAlign: "center",
            borderBottom: "1px solid #e9e9e9",
          },
        }}
        rowCount={actions?.meta.itemCount}
        paginationMode="server"
        filterMode="server"
        components={{
          Pagination: CustomPagination,
          Toolbar: MyActionsFilter,
        }}
      />
    </>
  );
};

export const MyActions = () => {
  const {
    state: {
      user: { id, admin },
    },
  } = useStateMachine();
  return (
    <ActionsProvider uid={id as string} userRole="consultant">
      <ResponsiveContainer>
        <MyActionsHeader />
        <PaperContainer>
          <Actions />
        </PaperContainer>
      </ResponsiveContainer>
    </ActionsProvider>
  );
};
