import { Fragment, useState } from "react";
import { Stack } from "@mui/material";

import { Popup } from "../../Theme";
import { GetBeneficiaryDto, TUserRole } from "../../Types";
import { ProfessionalInformationsHelpingBeneficiary } from "../Organisms/Popups/UserRegistration/ProfessionalInformations/professionalInformationsHelping.form";

interface HelpBenefPopupProps {
    beneficiary?: GetBeneficiaryDto
    handleClose: () => void;
    isSuspended?: boolean
}
interface IUserRegistrationBaseProps { 
  userRole: TUserRole;
  start?: boolean;
  beneficiary?: GetBeneficiaryDto;
  handleClose: () => void;
}
interface IUserRegistrationProps extends IUserRegistrationBaseProps {
  handleNextStep: () => void;
  isHelping?: boolean;
  providedBeneficiary?: GetBeneficiaryDto;
}

const steps = [
    {
      title: 'Infos professionnelles',
      component: (userInformationsProps: IUserRegistrationProps) => (
        <ProfessionalInformationsHelpingBeneficiary {...userInformationsProps} />
      ),
    },
];

const UserInformationStepper = ({ userRole, beneficiary, handleClose }: IUserRegistrationBaseProps) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const handleNextStep = () => {};

  return (
    <Fragment>
      {steps[currentStep] && steps[currentStep].component({ beneficiary, userRole, handleNextStep, isHelping: true, handleClose })}
    </Fragment>
  );
};

export const HelpBenefPopup = (props: HelpBenefPopupProps) => {
    const { beneficiary, handleClose } = props

    return (
      <Popup open={true} maxWidth='md' fullWidth>
        <Stack direction='column' padding={4} gap={3}>
          <UserInformationStepper 
            userRole='beneficiary'
            beneficiary={beneficiary}
            handleClose={handleClose}
          />
        </Stack>
      </Popup>
    )
}