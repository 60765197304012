import { StringAccessor } from '../Types';

export const ALL_DEVICES: StringAccessor = {
  AIDE_FORMATION: 'Aide financière à la formation',
  AIDE_MAINTIEN_EMPLOI: "Aide financière maintien de l'emploi",
  AAH: 'Allocation adulte handicapé (AAH)',
  BILAN_DE_COMPETENCES: 'Bilan de compétences',
  CARTE_MOBILITE_INCLUSION: 'Carte mobilité inclusion (CMI)',
  CEP: 'CEP (Conseil en Évolution Professionnelle)',
  CRPE: 'CRPE (Convention de Rééducation Professionnelle en Entreprise)',
  INFOS_RETRAITES: 'Infos droits retraites',
  ACTION_REMISE_NIVEAU: 'Action de remise à niveau',
  PAS: "PAS (Prestation d'Appui Spécifique)",
  PAS_HANDICAP_AUDITIF: 'PAS handicap auditif',
  PAS_HANDICAP_PSYCHIQUE: 'PAS handicap psychique',
  PAS_HANDICAP_VISUEL: 'PAS handicap visuel',
  PAS_HANDICAP_COGNITIF: 'PAS handicap cognitif',
  PAS_HANDICAP_MOTEUR: 'PAS handicap moteur',
  PAS_HANDICAP_CHRONIQUE_EVOLUTIVE: 'PAS maladies chroniques évolutives',
  PENSION_INVALIDE: "Pension d'invalidité",
  PMSMP: 'PMSMP (Période de Mise en Situation en Milieu Professionnel)',
  IPF: 'Inclu Pro Formation (IPF)',
  RQTH: 'RQTH (Reconnaissance Qualité de Travailleur Handicapé)',
  TEMPS_PARTIEL_THERAPEUTIQUE: 'Temps partiel thérapeutique',
  VAE: "VAE (Validation des Acquis de l'Expérience)",
  DPI: "DPI (Démarche Précoce d'Insertion socio profesionnelle)",
  STAGE_UEROS: 'Stage UEROS',
  VISITE_PRE_REPRISE: 'Visite de pré reprise',
  ESSAI_ENCADRE: 'Essai encadré',
  ORIENTATION_PROFESSIONNELLE: 'Orientation professionnelle',
  EPAAST: "Étude préalable à l'aménagement et l'adaptation de la situation de travail (EPAAST)",
  INFOS_IJSS: 'Infos droits IJSS (Indémnité journalière versée par le Sécurité Sociale)',
  RETRAITE_ANTICIPE_CARRIER_LONGUE: 'Retraite anticipée dispositif carrière longue',
  RETRAITE_ANTICIPE_PENIBILITE: 'Retraite anticipée dispositif pénibilité',
  RETRAITE_ANTICIPE_INAPTITUDE: 'Retraite anticipée pour inaptitude au travail',
  BILAN_FONCTIONNEL_PROFESSIONNEL: 'Bilan fonctionnel et professionnel',
  BILAN_UEROS: "Bilan UEROS (Unité d'Evaluation de Ré entraînement et d'Orientation Sociale)",
  BILAN_NEURO_COGNITIF: 'Bilan neuro cognitif',
  INDEMNITE_TEMPORAIRE_INAPTITUDE: "Idemnité temporaire d'inaptitude",
  ETUDE_POSTE_DE_TRAVAIL: 'Étude ergonomique aménagement du poste de travail',
  STAGE_PREORIENTATION_PROFESSIONNELLE: 'Stage de préorientation professionnelle',
  FORMATION_CENTRE_REEDUCATION_PROFESSIONNELLE: 'Formation en centre de réeducation professionnelle',
  FORMATION_TRANSITION_PRO: 'Formation Transitions PRO/OPCO',
  PROJET_TRANSITION_PROFESSIONNELLE: 'Projet de transition professionnelle',
  RDV_LIAISON: 'Rendez-vous de liaison',
  OTHER: 'Autre',
};

export const DEVISE_BOETH = [
  'RQTH',
  'CARTE_MOBILITE_INCLUSION',
  'AAH',
  'ORIENTATION_PROFESSIONNELLE',
  'FORMATION_TRANSITION_PRO',
  'FORMATION_CENTRE_REEDUCATION_PROFESSIONNELLE',
  'IPF',
  'EPAAST',
  'PAS_HANDICAP_VISUEL',
  'PAS_HANDICAP_AUDITIF',
  'PAS_HANDICAP_PSYCHIQUE',
  'PAS_HANDICAP_COGNITIF',
  'PAS_HANDICAP_MOTEUR',
  'PAS_HANDICAP_CHRONIQUE_EVOLUTIVE',
  'CEP',
  'BILAN_DE_COMPETENCES',
  'AIDE_FORMATION',
  'AIDE_MAINTIEN_EMPLOI',
  'ActACTION_REMISE_NIVEAU',
  'PENSION_INVALIDE',
  'AAH',
  'ORIENTATION_PROFESSIONNELLE',
  'VAE',
  'PMSMP',
  'TEMPS_PARTIEL_THERAPEUTIQUE',
  'ETUDE_POSTE_DE_TRAVAIL',
  'INFOS_IJSS',
  'INFOS_RETRAITES',
  'RETRAITE_ANTICIPE_CARRIER_LONGUE',
  'RETRAITE_ANTICIPE_INAPTITUDE',
  'RETRAITE_ANTICIPE_PENIBILITE',
  'PROJET_TRANSITION_PROFESSIONNELLE',
  'OTHER',
];

export const DEVISE_NOT_BOETH = [
  'RQTH',
  'AAH',
  'CARTE_MOBILITE_INCLUSION',
  'ORIENTATION_PROFESSIONNELLE',
  'BILAN_DE_COMPETENCES',
  'BILAN_UEROS',
  'BILAN_NEURO_COGNITIF',
  'ACTION_REMISE_NIVEAU',
  'BILAN_FONCTIONNEL_PROFESSIONNEL',
  'FORMATION_TRANSITION_PRO',
  'PENSION_INVALIDE',
  'VAE',
  'PMSMP',
  'TEMPS_PARTIEL_THERAPEUTIQUE',
  'ETUDE_POSTE_DE_TRAVAIL',
  'INFOS_IJSS',
  'INFOS_RETRAITES',
  'RETRAITE_ANTICIPE_CARRIER_LONGUE',
  'RETRAITE_ANTICIPE_INAPTITUDE',
  'RETRAITE_ANTICIPE_PENIBILITE',
  'PROJET_TRANSITION_PROFESSIONNELLE',
  'OTHER',
];

export const DEVISE_BOETH_ARREST = [
  'ESSAI_ENCADRE',
  'IPF',
  'RQTH',
  'EPAAST',
  'CRPE',
  'FORMATION_TRANSITION_PRO',
  'FORMATION_CENTRE_REEDUCATION_PROFESSIONNELLE',
  'PAS_HANDICAP_VISUEL',
  'PAS_HANDICAP_AUDITIF',
  'PAS_HANDICAP_PSYCHIQUE',
  'PAS_HANDICAP_COGNITIF',
  'PAS_HANDICAP_MOTEUR',
  'PAS_HANDICAP_CHRONIQUE_EVOLUTIVE',
  'CEP',
  'BILAN_DE_COMPETENCES',
  'AIDE_FORMATION',
  'AIDE_MAINTIEN_EMPLOI',
  'VISITE_PRE_REPRISE',
  'RDV_LIAISON',
  'ACTION_REMISE_NIVEAU',
  'PENSION_INVALIDE',
  'AAH',
  'ORIENTATION_PROFESSIONNELLE',
  'BILAN_UEROS',
  'STAGE_UEROS',
  'STAGE_PREORIENTATION_PROFESSIONNELLE',
  'BILAN_FONCTIONNEL_PROFESSIONNEL',
  'DPI',
  'VAE',
  'TEMPS_PARTIEL_THERAPEUTIQUE',
  'ETUDE_POSTE_DE_TRAVAIL',
  'INFOS_IJSS',
  'INFOS_RETRAITES',
  'RETRAITE_ANTICIPE_CARRIER_LONGUE',
  'RETRAITE_ANTICIPE_INAPTITUDE',
  'RETRAITE_ANTICIPE_PENIBILITE',
  'INDEMNITE_TEMPORAIRE_INAPTITUDE',
  'PROJET_TRANSITION_PROFESSIONNELLE',
  'OTHER',
];
export const DEVISE_NOT_BOETH_ARREST = [
  'ESSAI_ENCADRE',
  'RQTH',
  'CARTE_MOBILITE_INCLUSION',
  'AAH',
  'BILAN_DE_COMPETENCES',
  'CEP',
  'FORMATION_TRANSITION_PRO',
  'VISITE_PRE_REPRISE',
  'RDV_LIAISON',
  'ACTION_REMISE_NIVEAU',
  'PENSION_INVALIDE',
  'BILAN_UEROS',
  'BILAN_FONCTIONNEL_PROFESSIONNEL',
  'DPI',
  'VAE',
  'TEMPS_PARTIEL_THERAPEUTIQUE',
  'ETUDE_POSTE_DE_TRAVAIL',
  'INFOS_IJSS',
  'INFOS_RETRAITES',
  'RETRAITE_ANTICIPE_CARRIER_LONGUE',
  'RETRAITE_ANTICIPE_INAPTITUDE',
  'RETRAITE_ANTICIPE_PENIBILITE',
  'INDEMNITE_TEMPORAIRE_INAPTITUDE',
  'PROJET_TRANSITION_PROFESSIONNELLE',
  'OTHER',
];
