import React from 'react';
import { Card, IBaseCard } from '../../Theme';
import { TContainer } from '../../Types';

type TGenericCardProps = IBaseCard & TContainer;

export const GenericCard = ({ variant, color, children }: TGenericCardProps) => {
  return (
    <Card variant={variant} color={color}>
      {children}
    </Card>
  );
};
