/**
 * This is a "flow" where the user is invited to fill
 * its informations trough a stepper
 */
import React, { Fragment, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import SuccessImage from '../../../../Assets/success.svg';
import { TUserRole } from '../../../../Types';
import { useStateMachine } from 'little-state-machine';
import { Link } from 'react-router-dom';

/**
 * TODO WIP
 * refacto / split code ....
 */

/**
 * Stepper imports
 */
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { PersonalInformations } from './PersonalInformations/personalInformations.form';
import { ProfesionalInformations } from './ProfessionalInformations/professionalInformations.form';
import { usePopup } from '../../../../Hooks';

const steps = [
  {
    title: 'Infos personelles',
    component: (userInformationsProps: IUserRegistrationProps) => <PersonalInformations {...userInformationsProps} />,
  },
  {
    title: 'Infos professionnelles',
    component: (userInformationsProps: IUserRegistrationProps) => (
      <ProfesionalInformations {...userInformationsProps} />
    ),
  },
  {
    title: 'Terminé',
    component: (userInformationsProps: IUserRegistrationBaseProps) => <UserRegistration {...userInformationsProps} />,
  },
];
export interface IUserRegistrationProps extends IUserRegistrationBaseProps {
  handleNextStep: () => void;
}
const UserInformationStepper = ({ userRole }: IUserRegistrationBaseProps) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { closePopup } = usePopup('RegistrationPopup');
  const {
    state: {
      user: {
        beneficiary,
      }
    }
  } = useStateMachine();

  const handleNextStep = () => {
    /**
     * If next step is the last step or user role is consultant and next step is
     * going to be the last step => close popup
     *
     * (Consultant doesn't have the message "you successfully created your consultant ..."
     * but beneficiary has it)
     */
    if (
      closePopup &&
      (currentStep + 1 >= steps.length || (userRole === 'consultant' && currentStep + 1 === steps.length - 1) || (currentStep >= 1 && beneficiary?.isHelpNeeded))
    ) {
      closePopup();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <Fragment>
      <Stepper activeStep={currentStep} alternativeLabel style={{ width: '100%' }}>
        {steps.map(({ title }) => (
          <Step key={title}>
            <StepLabel>{title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {steps[currentStep] && steps[currentStep].component({ userRole, handleNextStep })}
    </Fragment>
  );
};

interface IUserRegistrationBaseProps {
  userRole: TUserRole;
  start?: boolean;
}

export const UserRegistration = ({ userRole, start }: IUserRegistrationBaseProps) => {
  const [startFlow, setStartFlow] = useState<boolean>(false);
  const { closePopup } = usePopup('RegistrationPopup');

  const handleStartFlow = () => setStartFlow(true);
  const getUserRoleText = (): string => {
    return userRole === 'beneficiary' ? 'bénéficiaire' : 'consultant';
  };
  const { state: { user: { consultant }}} = useStateMachine();

  return (
    <Fragment>
      <Grid container gap={8} padding={(theme) => theme.spacing(6, 6)} justifyContent="center" textAlign="center">
        {!startFlow && (
          <Fragment>
            {start && <img src={SuccessImage} style={{ width: 73 }} alt='Success' />}
            <Grid container gap={4} direction="column">
              <Typography variant="h1" component="div">
                {start && `Félicitations vous avez créé votre compte ${getUserRoleText()} Sami !`}
                {!start && `Félicitations vous avez créé votre fiche ${getUserRoleText()} !`}
              </Typography>
              <Typography variant="body1" component="div" color={(theme) => theme.palette.text.secondary}>
                {start &&
                  userRole === 'consultant' &&
                  `Complétez votre fiche consultant pour pouvoir être contacté et visible par vos bénéficiaires et
                commencez dès maintenant à inviter des bénéficiaire à suivre leur projet de maintien et retour à
                l'emploi.`}
                {start &&
                  userRole === 'beneficiary' &&
                  `Pour profiter de nos services, renseignez votre fiche bénéficiaire.
                  Cette fiche sera consultée par tous les professionnels qui vous accompagnent et leur
                  permettra de suivre votre projet.`}
                {!start && (
                  <>
                    <div>
                      La fiche Bénéficiaire est visible par vos consultants, les professionnels qui vous accompagnent.
                      Vous pouvez modifier les informations de votre fiche à tout moment en vous rendant sur la page{' '}
                      <Link to="/beneficiary/my-informations">Mes informations</Link> de votre espace.
                    </div>
                  </>
                )}
              </Typography>
            </Grid>
            <Button fullWidth={false} variant="contained" onClick={start ? handleStartFlow : closePopup}>
              {start && `Compléter ma fiche ${getUserRoleText()}`}
              {!start && 'Terminer'}
            </Button>
          </Fragment>
        )}
        {startFlow && <UserInformationStepper userRole={userRole} />}
      </Grid>
    </Fragment>
  );
};
