import { UserLightDto } from "./actions.type";
import { MetaDto } from "./user.types";

export type TLoginCredentials = {
  email: string;
  password: string;
};

export type TPasswordCredentials = Pick<TLoginCredentials, "password">;

export type TInvitationCredentials = {
  email: string;
  invitationCode: string;
  invitations?: TInvitationPayload[];
  isInvited?: boolean;
};

export type TInvitationPayload = {
  from: string;
  user: string;
  message?: string;
};

export type CreateAccount = {
  gender?: GenderEnum;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  passwordConfirmation?: string;
  phoneNumber?: string;
  name?: string;
  address?: string;
  postcode?: string;
  city?: string;
  organismTmp?: string;
};

export type TAccountCreateData = {
  gender?: GenderEnum;
  firstName?: string;
  lastName?: string;
  email?: string;
  // invitations: TInvitationPayload[];
  /** Maybe storing these informations isn't necessary as it will be defined if query params is present or not */
  // isInvited?: boolean;
  // invitationCode?: string;
  // isLocked?: boolean;
  // isConsultant?: boolean;
  password?: string;
  passwordConfirmation?: string;
  phoneNumber?: string;
  name?: string;
  address?: string;
  postcode?: string;
  city?: string;
  organismTmp?: string;
};

export type TLoginSessionState = {
  createAccountValues: Partial<TAccountCreateData>;
};

export type GetAuthCredentialsDto = {
  accessToken: string;
  consultant?: GetConsultantDto;
  organism?: GetOrganismDto;
  beneficiary?: GetBeneficiaryDto;
};

export type CreateBeneficiaryDto = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  organismId: string;
  gender: GenderEnum;
};

export type CreateOrganismDto = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  name: string;
  phoneNumber: string;
  address: string;
  postcode: string;
  city: string;
  avatar?: File;
  profilePictureUrl?: string;
};

export type AddressDto = {
  address: string;
  postcode: string;
  city: string;
};

export type GetConsultantDto = {
  id: string;
  lastName: string;
  email: string;
  firstName: string;
  gender?: GenderEnum;
  address?: string;
  postcode?: string;
  city?: string;
  phoneNumber: string;
  isDeleted: boolean;
  organismName?: string;
  isPremium: boolean;
  isLocked: boolean;
  organism?: ConsultantOrganismDto;
  postOccupied?: PostOccupiedEnum;
  organismId?: string;
  organismTmp?: string;
};

export type ConsultantOrganismDto = {
  id: string;
  name: string;
  address?: string;
};

export type GetOrganismDto = {
  id: string;
  lastName: string;
  firstName: string;
  email: string;
  phoneNumber?: string;
  name?: string;
  address?: string;
  city?: string;
  postcode?: string;
  photoUrl?: string;
  isDeleted: boolean;
  isPremium: boolean;
  isLocked: boolean;
};

export type CreateConsultantDto = {
  lastName: string;
  firstName: string;
  email: string;
  phoneNumber?: string;
  isPremium: boolean;
  postOccupied: PostOccupiedEnum;
  organismId: string;
  organismName: string;
};

export type GetOrganismListDto = {
  data: GetOrganismAdminDto[];
  meta: MetaDto;
};

export type GetOrganismAdminDto = {
  id: string;
  name: string;
  consultantCount: number;
  beneficiaryCount: number;
  actionCount: number;
  photoUrl?: string;
  isDeleted?: boolean;
};

export type GetBeneficiaryDto = {
  id: string;
  lastName: string;
  firstName: string;
  email: string;
  gender?: GenderEnum;
  departmentLocation?: Departments | "";
  birthday: string;
  address?: string;
  postcode?: string;
  city?: string;
  phoneNumber?: string;
  status?: BeneficiaryStatus | "";
  job?: string;
  ape?: string;
  contract?: ContractEnum | "";
  language?: string;
  languageLevel?: LanguageLevel | "";
  levelSchool?: LevelSchool | "";
  isBOE?: boolean;
  statusBOE?: StatusBoe | "";
  isWorkArrest?: boolean;
  typeWorkArrest?: TypeWorkArrest | "";
  dateWorkArrest?: string;
  isDeleted: boolean;
  consultants: UserLightDto[];
  consultantsName: UserLightDto[];
  formation?: string;
  isSuspended: boolean;
  isHelpNeeded: boolean;
  suspendReason?: SuspendReason;
  suspendDate?: string;
  suspendedBy?: GetConsultantDto;
};

export type TestErr = {
  error?: string;
  message?: string;
  statusCode?: string;
};

export type GetBeneficiaryLightDto = {
  id: string;
  firstName: string;
  lastName: string;
  age?: number;
  statusBOE?: StatusBoe;
  dateWorkArrest?: Date;
  isDeleted: boolean;
  consultants: UserLightDto[];
  isSuspended: boolean;
  isBOE: boolean;
  isWorkArrest: boolean;
  typeWorkArrest: TypeWorkArrest;
  isHelpNeeded: boolean | null;
};

export type GetBeneficiaryListDto = {
  data: GetBeneficiaryLightDto[];
  meta: MetaDto;
};

export enum PostOccupiedEnum {
  ADMINISTRATIVE_ASSISTANT = "ADMINISTRATIVE_ASSISTANT",
  HEALTH_INSURANCE_SOCIAL_WORKER = "HEALTH_INSURANCE_SOCIAL_WORKER",
  CORPORATE_SOCIAL_WORKER = "CORPORATE_SOCIAL_WORKER",
  SPSTI_SOCIAL_WORKER = "SPSTI_SOCIAL_WORKER",
  STATUS_MAINTENANCE_MISSION_COORDINATOR = "STATUS_MAINTENANCE_MISSION_COORDINATOR",
  ERGONOMIST = "ERGONOMIST",
  OCCUPATIONAL_HEALTH_NURSE = "OCCUPATIONAL_HEALTH_NURSE",
  OCCUPATIONAL_PHYSICIAN = "OCCUPATIONAL_PHYSICIAN",
  HEALTH_AND_SAFETY_OFFICER = "HEALTH_AND_SAFETY_OFFICER",
  WORKPLACE_PSYCHOLOGIST = "WORKPLACE_PSYCHOLOGIST",
}

export namespace PostOccupiedEnum {
  export const allPosts: PostOccupiedEnum[] = [
    PostOccupiedEnum.ADMINISTRATIVE_ASSISTANT,
    PostOccupiedEnum.HEALTH_INSURANCE_SOCIAL_WORKER,
    PostOccupiedEnum.CORPORATE_SOCIAL_WORKER,
    PostOccupiedEnum.SPSTI_SOCIAL_WORKER,
    PostOccupiedEnum.STATUS_MAINTENANCE_MISSION_COORDINATOR,
    PostOccupiedEnum.ERGONOMIST,
    PostOccupiedEnum.OCCUPATIONAL_HEALTH_NURSE,
    PostOccupiedEnum.OCCUPATIONAL_PHYSICIAN,
    PostOccupiedEnum.HEALTH_AND_SAFETY_OFFICER,
    PostOccupiedEnum.WORKPLACE_PSYCHOLOGIST,
  ];

  export const label = (post: PostOccupiedEnum): string => {
    switch (post) {
      case PostOccupiedEnum.ADMINISTRATIVE_ASSISTANT:
        return "Assistant administratif";
      case PostOccupiedEnum.HEALTH_INSURANCE_SOCIAL_WORKER:
        return "Assistant(e) social(e) assurance maladie";
      case PostOccupiedEnum.CORPORATE_SOCIAL_WORKER:
        return "Assistant(e) social(e) d'entreprise";
      case PostOccupiedEnum.SPSTI_SOCIAL_WORKER:
        return "Assistant(e) social(e) du SPSTI";
      case PostOccupiedEnum.STATUS_MAINTENANCE_MISSION_COORDINATOR:
        return "Chargé(e) de mission maintien dans l'emploi";
      case PostOccupiedEnum.ERGONOMIST:
        return "Ergonome";
      case PostOccupiedEnum.OCCUPATIONAL_HEALTH_NURSE:
        return "Infirmier(e) en santé au travail";
      case PostOccupiedEnum.OCCUPATIONAL_PHYSICIAN:
        return "Médecin du travail";
      case PostOccupiedEnum.HEALTH_AND_SAFETY_OFFICER:
        return "Préventeur";
      case PostOccupiedEnum.WORKPLACE_PSYCHOLOGIST:
        return "Psychologue du travail";
    }
  };
}

export enum GenderEnum {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum TypeWorkArrest {
  OCCUPATIONAL_DISEASE = "OCCUPATIONAL_DISEASE",
  SICK_LEAVE = "SICK_LEAVE",
  WORKPLACE_ACCIDENT = "WORKPLACE_ACCIDENT",
  LONG_TERM_ILLNESS = "LONG_TERM_ILLNESS",
  LONG_TERM_LEAVE = "LONG_TERM_LEAVE",
}

export enum StatusBoe {
  BOETH = "BOETH",
  INVALIDITY = "INVALIDITY",
  PERMANENT_PARTIAL_DISABILITY = "PERMANENT_PARTIAL_DISABILITY",
}

export enum LevelSchool {
  NO_DEGREE = "NO_DEGREE",
  PATENT = "PATENT",
  PROFESSIONAL_BAC = "PROFESSIONAL_BAC",
  BAC2 = "BAC+2",
  BAC3 = "BAC+3",
  BAC4 = "BAC+4",
  BAC5_OR_MORE = "BAC+5_OR_MORE",
}

export enum LanguageLevel {
  A1 = "A1",
  A2 = "A2",
  B1 = "B1",
  B2 = "B2",
  C1 = "C1",
  C2 = "C2",
}

export namespace TypeWorkArrest {
  export const label = (type: TypeWorkArrest): string => {
    switch (type) {
      case TypeWorkArrest.SICK_LEAVE:
        return "Arrêt maladie";
      case TypeWorkArrest.WORKPLACE_ACCIDENT:
        return "Accident du travail";
      case TypeWorkArrest.OCCUPATIONAL_DISEASE:
        return "Maladie Professionnelle";
      case TypeWorkArrest.LONG_TERM_ILLNESS:
        return "CLM (Congé Longue Maladie)";
      case TypeWorkArrest.LONG_TERM_LEAVE:
        return "CLD (Congé Longue Durée)";
    }
  };

  export const typeWorkArrests: TypeWorkArrest[] = [
    TypeWorkArrest.SICK_LEAVE,
    TypeWorkArrest.WORKPLACE_ACCIDENT,
    TypeWorkArrest.OCCUPATIONAL_DISEASE,
    TypeWorkArrest.LONG_TERM_ILLNESS,
    TypeWorkArrest.LONG_TERM_LEAVE,
  ];
}

export namespace GenderEnum {
  export const label = (gender: GenderEnum): string => {
    switch (gender) {
      case GenderEnum.FEMALE:
        return "femme";
      case GenderEnum.MALE:
        return "homme";
    }
  };
}

export namespace LanguageLevel {
  export const label = (level: LanguageLevel): string => {
    switch (level) {
      case LanguageLevel.A1:
        return "A1 Débutant";
      case LanguageLevel.A2:
        return "A2 Connaissance de base";
      case LanguageLevel.B1:
        return "B1 Connaissance améliorées";
      case LanguageLevel.B2:
        return "B2 Autonomie linguistique";
      case LanguageLevel.C1:
        return "C1 Connaissances expertes";
      case LanguageLevel.C2:
        return "C2 Maîtrise";
    }
  };

  export const languageLevels: LanguageLevel[] = [
    LanguageLevel.A1,
    LanguageLevel.A2,
    LanguageLevel.B1,
    LanguageLevel.B2,
    LanguageLevel.C1,
    LanguageLevel.C2,
  ];
}

export namespace LevelSchool {
  export const label = (level: LevelSchool): string => {
    switch (level) {
      case LevelSchool.NO_DEGREE:
        return "Pas de diplôme";
      case LevelSchool.PATENT:
        return "Brevet";
      case LevelSchool.PROFESSIONAL_BAC:
        return "Bac professionnel";
      case LevelSchool.BAC2:
        return "Bac + 2";
      case LevelSchool.BAC3:
        return "Bac + 3";
      case LevelSchool.BAC4:
        return "Bac + 4";
      case LevelSchool.BAC5_OR_MORE:
        return "Bac + 5 ou +";
    }
  };

  export const levelSchools: LevelSchool[] = [
    LevelSchool.NO_DEGREE,
    LevelSchool.PATENT,
    LevelSchool.PROFESSIONAL_BAC,
    LevelSchool.BAC2,
    LevelSchool.BAC3,
    LevelSchool.BAC4,
    LevelSchool.BAC5_OR_MORE,
  ];
}

export enum ContractEnum {
  NOT_APPLICABLE = "NOT_APPLICABLE",
  CONTRACTUAL = "CONTRACTUAL",
  CDD = "CDD",
  CDI = "CDI",
  APPRENTICESHIP = "APPRENTICESHIP",
}

export namespace ContractEnum {
  export const label = (contract: ContractEnum): string => {
    switch (contract) {
      case ContractEnum.APPRENTICESHIP:
        return "Apprentissage";
      case ContractEnum.CDD:
        return "CDD";
      case ContractEnum.CDI:
        return "CDI";
      case ContractEnum.CONTRACTUAL:
        return "Contractuel";
      case ContractEnum.NOT_APPLICABLE:
        return "Non applicable";
    }
  };

  export const contracts: ContractEnum[] = [
    ContractEnum.APPRENTICESHIP,
    ContractEnum.CDD,
    ContractEnum.CDI,
    ContractEnum.CONTRACTUAL,
    ContractEnum.NOT_APPLICABLE,
  ];
}

export enum BeneficiaryStatus {
  SELF_EMPLOYED = "SELF_EMPLOYED",
  UNEMPLOYED = "UNEMPLOYED",
  EMPLOYED = "EMPLOYED",
  CIVIL_SERVANT = "CIVIL_SERVANT",
  TEMPORARY_WORKER = "TEMPORARY_WORKER",
  COMPANY_MANAGER = "COMPANY_MANAGER",
}

export namespace BeneficiaryStatus {
  export const label = (status: BeneficiaryStatus): string => {
    switch (status) {
      case BeneficiaryStatus.CIVIL_SERVANT:
        return "Agent de la fonction publique";
      case BeneficiaryStatus.COMPANY_MANAGER:
        return "Chef d'entreprise";
      case BeneficiaryStatus.EMPLOYED:
        return "Salarié";
      case BeneficiaryStatus.SELF_EMPLOYED:
        return "Indépendant";
      case BeneficiaryStatus.TEMPORARY_WORKER:
        return "Intérimaire";
      case BeneficiaryStatus.UNEMPLOYED:
        return "Sans emploi";
    }
  };

  export const beneficiaryStatuses: BeneficiaryStatus[] = [
    BeneficiaryStatus.CIVIL_SERVANT,
    BeneficiaryStatus.COMPANY_MANAGER,
    BeneficiaryStatus.EMPLOYED,
    BeneficiaryStatus.SELF_EMPLOYED,
    BeneficiaryStatus.TEMPORARY_WORKER,
    BeneficiaryStatus.UNEMPLOYED,
  ];
}

export namespace StatusBoe {
  export const statuses: StatusBoe[] = [
    StatusBoe.BOETH,
    StatusBoe.INVALIDITY,
    StatusBoe.PERMANENT_PARTIAL_DISABILITY,
  ];

  export const label = (status: StatusBoe): string => {
    switch (status) {
      case StatusBoe.BOETH:
        return "Reconnaissance qualité de travailleur handicapé (BOETH)";
      case StatusBoe.INVALIDITY:
        return "Invalidité de catégorie 1, 2, 3";
      case StatusBoe.PERMANENT_PARTIAL_DISABILITY:
        return "Incapacité partielle permanente supérieure à 10 % (maladie professionnelle/accident de travail)";
      default:
        return "";
    }
  };
}

export enum Role {
  USER = "USER",
  CONSULTANT = "CONSULTANT",
  BENEFICIARY = "BENEFICIARY",
  ORGANISM = "ORGANISM",
}

export type LoginDto = {
  type: Role;
  email: string;
  password: string; // regex "^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
};

export enum SuspendReason {
  ABANDON = "ABANDON",
  CESSATION_OF_ACTIVITY = "CESSATION_OF_ACTIVITY",
  INVALIDITY = "INVALIDITY",
  JOB_RETENTION = "JOB_RETENTION_WITH_ADJUSTMENTS",
  JOB_RETENTION_WITHOUT = "JOB_RETENTION_WITHOUT_ADJUSTMENTS",
  JOB_SEARCH = "JOB_SEARCH",
  EXTERNAL_RECLASSIFICATION_OF_THE_COMPANY = "EXTERNAL_RECLASSIFICATION_OF_THE_COMPANY",
  INTERNAL_RECLASSIFICATION_TO_ANOTHER_POSITION = "INTERNAL_RECLASSIFICATION_TO_ANOTHER_POSITION",
  ATMP_PENSION = "AT/MP_PENSION",
  RETIREMENT = "RETIREMENT",
  RETIREMENT_FOR_UNFITNESS = "RETIREMENT_FOR_UNFITNESS",
  LOST_SIGHT_OF = "LOST_SIGHT_OF",
  INCAPACITY_LAYOFF = "INCAPACITY_LAYOFF",
  BUSINESS_CREATION = "BUSINESS_CREATION",
  OCCUPATIONAL_INCAPACITY = "OCCUPATIONAL_INCAPACITY",
  NON_OCCUPATIONAL_INCAPACITY = "NON_OCCUPATIONAL_INCAPACITY",
  TRAINING = "TRAINING",
  SOCIAL_OUTING = "SOCIAL_OUTING",
  CONTRACTUAL_TERMINATION = "CONTRACTUAL_TERMINATION",
  RESIGNATION = "RESIGNATION"
}

export namespace SuspendReason {
  export const SuspendReasons: SuspendReason[] = [
    SuspendReason.ABANDON,
    SuspendReason.CESSATION_OF_ACTIVITY,
    SuspendReason.INVALIDITY,
    SuspendReason.JOB_RETENTION,
    SuspendReason.JOB_RETENTION_WITHOUT,
    SuspendReason.JOB_SEARCH,
    SuspendReason.EXTERNAL_RECLASSIFICATION_OF_THE_COMPANY,
    SuspendReason.INTERNAL_RECLASSIFICATION_TO_ANOTHER_POSITION,
    SuspendReason.ATMP_PENSION,
    SuspendReason.RETIREMENT,
    SuspendReason.RETIREMENT_FOR_UNFITNESS,
    SuspendReason.LOST_SIGHT_OF,
    SuspendReason.INCAPACITY_LAYOFF,
    SuspendReason.BUSINESS_CREATION,
    SuspendReason.OCCUPATIONAL_INCAPACITY,
    SuspendReason.NON_OCCUPATIONAL_INCAPACITY,
    SuspendReason.TRAINING,
    SuspendReason.SOCIAL_OUTING,
    SuspendReason.CONTRACTUAL_TERMINATION,
    SuspendReason.RESIGNATION
  ];

  export const label = (reason: SuspendReason) => {
    switch (reason) {
      case SuspendReason.ABANDON:
        return "Abandon";
      case SuspendReason.CESSATION_OF_ACTIVITY:
        return "Cessation d'activité";
      case SuspendReason.INVALIDITY:
        return "Invalidité";
      case SuspendReason.JOB_RETENTION:
        return "Maintien au poste avec aménagements";
        case SuspendReason.JOB_RETENTION_WITHOUT:
        return "Maintien au poste sans aménagements";
      case SuspendReason.JOB_SEARCH:
        return "Recherche d'emploi";
      case SuspendReason.EXTERNAL_RECLASSIFICATION_OF_THE_COMPANY:
        return "Reclassement externe à l'entreprise";
      case SuspendReason.INTERNAL_RECLASSIFICATION_TO_ANOTHER_POSITION:
        return "Reclassement interne sur un autre poste";
      case SuspendReason.ATMP_PENSION:
        return "Rente AT/MP";
      case SuspendReason.RETIREMENT:
        return "Retraite";
      case SuspendReason.RETIREMENT_FOR_UNFITNESS:
        return "Retraite pour inaptitude";
      case SuspendReason.LOST_SIGHT_OF:
        return "Perdu(e) de vue";
      case SuspendReason.INCAPACITY_LAYOFF:
        return "Licenciement pour inaptitude";
      case SuspendReason.BUSINESS_CREATION:
        return "Création d'entreprise";
      case SuspendReason.OCCUPATIONAL_INCAPACITY:
        return "Inaptitude d'origine professionnelle";
      case SuspendReason.NON_OCCUPATIONAL_INCAPACITY:
        return "Inaptitude d’origine non professionnelle";
      case SuspendReason.TRAINING:
        return 'Formation';
      case SuspendReason.SOCIAL_OUTING:
        return "Sortie sociale de l'entreprise";
      case SuspendReason.CONTRACTUAL_TERMINATION:
        return "Rupture conventionnelle";
      case SuspendReason.RESIGNATION:
        return "Démission";
    }
  };
}

export enum Departments {
  D01 = '1',
  D02 = '2',
  D03 = '3',
  D04 = '4',
  D05 = '5',
  D06 = '6',
  D07 = '7',
  D08 = '8',
  D09 = '9',
  D10 = '10',
  D11 = '11',
  D12 = '12',
  D13 = '13',
  D14 = '14',
  D15 = '15',
  D16 = '16',
  D17 = '17',
  D18 = '18',
  D19 = '19',
  D2A = '2A',  
  D2B = '2B',
  D21 = '21',
  D22 = '22',
  D23 = '23',
  D24 = '24',
  D25 = '25',
  D26 = '26',
  D27 = '27',
  D28 = '28',
  D29 = '29',
  D30 = '30',
  D31 = '31',
  D32 = '32',
  D33 = '33',
  D34 = '34',
  D35 = '35',
  D36 = '36',
  D37 = '37',
  D38 = '38',
  D39 = '39',
  D40 = '40',
  D41 = '41',
  D42 = '42',
  D43 = '43',
  D44 = '44',
  D45 = '45',
  D46 = '46',
  D47 = '47',
  D48 = '48',
  D49 = '49',
  D50 = '50',
  D51 = '51',
  D52 = '52',
  D53 = '53',
  D54 = '54',
  D55 = '55',
  D56 = '56',
  D57 = '57',
  D58 = '58',
  D59 = '59',
  D60 = '60',
  D61 = '61',
  D62 = '62',
  D63 = '63',
  D64 = '64',
  D65 = '65',
  D66 = '66',
  D67 = '67',
  D68 = '68',
  D69 = '69',
  D70 = '70',
  D71 = '71',
  D72 = '72',
  D73 = '73',
  D74 = '74',
  D75 = '75',
  D76 = '76',
  D77 = '77',
  D78 = '78',
  D79 = '79',
  D80 = '80',
  D81 = '81',
  D82 = '82',
  D83 = '83',
  D84 = '84',
  D85 = '85',
  D86 = '86',
  D87 = '87',
  D88 = '88',
  D89 = '89',
  D90 = '90',
  D91 = '91',
  D92 = '92',
  D93 = '93',
  D94 = '94',
  D95 = '95',
  D971 = '971',
  D972 = '972',
  D973 = '973',
  D974 = '974',
  D975 = '975',
  D976 = '976',
}

export namespace Departments {
  export const AllDepartments: Departments[] = [
    Departments.D01, 
    Departments.D02, 
    Departments.D03, 
    Departments.D04,  
    Departments.D05, 
    Departments.D06, 
    Departments.D07, 
    Departments.D08,  
    Departments.D09,  
    Departments.D10,  
    Departments.D11,  
    Departments.D12,  
    Departments.D13,  
    Departments.D14,  
    Departments.D15,  
    Departments.D16,  
    Departments.D17,  
    Departments.D18,  
    Departments.D19,  
    Departments.D21,  
    Departments.D22,  
    Departments.D23,  
    Departments.D24,  
    Departments.D25,  
    Departments.D26,  
    Departments.D27,  
    Departments.D28,  
    Departments.D29,  
    Departments.D2A,  
    Departments.D2B,  
    Departments.D30,  
    Departments.D31,  
    Departments.D32,  
    Departments.D33,  
    Departments.D34,  
    Departments.D35,  
    Departments.D36,  
    Departments.D37,  
    Departments.D38,  
    Departments.D39,  
    Departments.D40,  
    Departments.D41,  
    Departments.D42,  
    Departments.D43,  
    Departments.D44,  
    Departments.D45,  
    Departments.D46,  
    Departments.D47,  
    Departments.D48,  
    Departments.D49,  
    Departments.D50,  
    Departments.D51,  
    Departments.D52,  
    Departments.D53,  
    Departments.D54,  
    Departments.D55,  
    Departments.D56,  
    Departments.D57,  
    Departments.D58,  
    Departments.D59,  
    Departments.D60,  
    Departments.D61,  
    Departments.D62,  
    Departments.D63,  
    Departments.D64,  
    Departments.D65,  
    Departments.D66,  
    Departments.D67,  
    Departments.D68,  
    Departments.D69,  
    Departments.D70,  
    Departments.D71,  
    Departments.D72,  
    Departments.D73,  
    Departments.D74,  
    Departments.D75,  
    Departments.D76,  
    Departments.D77,  
    Departments.D78,  
    Departments.D79,  
    Departments.D80,  
    Departments.D81,  
    Departments.D82,  
    Departments.D83,  
    Departments.D84,  
    Departments.D85,  
    Departments.D86,  
    Departments.D87,  
    Departments.D88,  
    Departments.D89,  
    Departments.D90,  
    Departments.D91,  
    Departments.D92,  
    Departments.D93,  
    Departments.D94,  
    Departments.D95,  
    Departments.D971,  
    Departments.D972,  
    Departments.D973,  
    Departments.D974,  
    Departments.D975,
    Departments.D976
  ]

  export const label = (department: Departments) => {
    switch (department) {
      case Departments.D01:
        return "Ain";
      case Departments.D02:
        return "Aisne";
      case Departments.D03:
        return "Allier";
      case Departments.D04:
        return "Alpes-de-Haute-Provence";
      case Departments.D05:
        return "Hautes-Alpes";
      case Departments.D06:
        return "Alpes-Maritimes";
      case Departments.D07:
        return "Ardèche";
      case Departments.D08:
        return "Ardennes";
      case Departments.D09:
        return "Ariège";
      case Departments.D10:
        return "Aube";
      case Departments.D11:
        return "Aude";
      case Departments.D12:
        return "Aveyron";
      case Departments.D13:
        return "Bouches-du-Rhône";
      case Departments.D14:
        return "Calvados";
      case Departments.D15:
        return "Cantal";
      case Departments.D16:
        return "Charente";
      case Departments.D17:
        return "Charente-Maritime";
      case Departments.D18:
        return "Cher";
      case Departments.D19:
        return "Corrèze";
      case Departments.D21:
        return "Côte-d'Or";
      case Departments.D22:
        return "Côtes-d'Armor";
      case Departments.D23:
        return "Creuse";
      case Departments.D24:
        return "Dordogne";
      case Departments.D25:
        return "Doubs";
      case Departments.D26:
        return "Drôme";
      case Departments.D27:
        return "Eure";
      case Departments.D28:
        return "Eure-et-Loir";
      case Departments.D29:
        return "Finistère";
      case Departments.D2A:
        return "Corse-du-Sud";
      case Departments.D2B:
        return "Haute-Corse";
      case Departments.D30:
        return "Gard";
      case Departments.D31:
        return "Haute-Garonne";
      case Departments.D32:
        return "Gers";
      case Departments.D33:
        return "Gironde";
      case Departments.D34:
        return "Hérault";
      case Departments.D35:
        return "Ille-et-Vilaine";
      case Departments.D36:
        return "Indre";
      case Departments.D37:
        return "Indre-et-Loire";
      case Departments.D38:
        return "Isère";
      case Departments.D39:
        return "Jura";
      case Departments.D40:
        return "Landes";
      case Departments.D41:
        return "Loir-et-Cher";
      case Departments.D42:
        return "Loire";
      case Departments.D43:
        return "Haute-Loire";
      case Departments.D44:
        return "Loire-Atlantique";
      case Departments.D45:
        return "Loiret";
      case Departments.D46:
        return "Lot";
      case Departments.D47:
        return "Lot-et-Garonne";
      case Departments.D48:
        return "Lozère";
      case Departments.D49:
        return "Maine-et-Loire";
      case Departments.D50:
        return "Manche";
      case Departments.D51:
        return "Marne";
      case Departments.D52:
        return "Haute-Marne";
      case Departments.D53:
        return "Mayenne";
      case Departments.D54:
        return "Meurthe-et-Moselle";
      case Departments.D55:
        return "Meuse";
      case Departments.D56:
        return "Morbihan";
      case Departments.D57:
        return "Moselle";
      case Departments.D58:
        return "Nièvre";
      case Departments.D59:
        return "Nord";
      case Departments.D60:
        return "Oise";
      case Departments.D61:
        return "Orne";
      case Departments.D62:
        return "Pas-de-Calais";
      case Departments.D63:
        return "Puy-de-Dôme";
      case Departments.D64:
        return "Pyrénées-Atlantiques";
      case Departments.D65:
        return "Hautes-Pyrénées";
      case Departments.D66:
        return "Pyrénées-Orientales";
      case Departments.D67:
        return "Bas-Rhin";
      case Departments.D68:
        return "Haut-Rhin";
      case Departments.D69:
        return "Rhône";
      case Departments.D70:
        return "Haute-Saône";
      case Departments.D71:
        return "Saône-et-Loire";
      case Departments.D72:
        return "Sarthe";
      case Departments.D73:
        return "Savoie";
      case Departments.D74:
        return "Haute-Savoie";
      case Departments.D75:
        return "Paris";
      case Departments.D76:
        return "Seine-Maritime";
      case Departments.D77:
        return "Seine-et-Marne";
      case Departments.D78:
        return "Yvelines";
      case Departments.D79:
        return "Deux-Sèvres";
      case Departments.D80:
        return "Somme";
      case Departments.D81:
        return "Tarn";
      case Departments.D82:
        return "Tarn-et-Garonne";
      case Departments.D83:
        return "Var";
      case Departments.D84:
        return "Vaucluse";
      case Departments.D85:
        return "Vendée";
      case Departments.D86:
        return "Vienne";
      case Departments.D87:
        return "Haute-Vienne";
      case Departments.D88:
        return "Vosges";
      case Departments.D89:
        return "Yonne";
      case Departments.D90:
        return "Territoire de Belfort";
      case Departments.D91:
        return "Essonne";
      case Departments.D92:
        return "Hauts-de-Seine";
      case Departments.D93:
        return "Seine-Saint-Denis";
      case Departments.D94:
        return "Val-de-Marne";
      case Departments.D95:
        return "Val-d'Oise";
      case Departments.D971:
        return "Guadeloupe";
      case Departments.D972:
        return "Martinique";
      case Departments.D973:
        return "Guyane";
      case Departments.D974:
        return "La Réunion";
      case Departments.D976:
        return "Mayotte";
    }
  }
}