import React from 'react';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, Auth } from 'firebase/auth';
import { getFirestore, Firestore, DocumentReference, doc } from 'firebase/firestore';
import { Link } from '@mui/material';

// TODO set to env variables
const firebaseConfiguration = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

/**
 * TODO
 * change this to another thing than "firebaseErrors"
 * because errors may not all come from firebase.
 *
 * Make it global and change the name
 */
export const firebaseErrors: { [code: string]: string | React.ReactElement } = {
  'auth/email-already-in-use': 'Cette addresse mail est déjà utilisée par un utilisateur.',
  'auth/wrong-password': 'Le mot de passe est incorrect.',
  'auth/user-not-found': "Aucun compte utilisateur n'est associé à cette adresse mail.",
  'auth/user-disabled': 'Ce compte à été désactivé.',
  'auth/invalid-action-code': `
    Le mot de passe ne peux pas être réinitialisé.
    Essayez de faire une nouvelle demande de réinitialisation depuis la page 'connexion'
  `,
  'auth/expired-action-code': `
    Le code de réinitialisation est trop ancien.
    Essayez de faire une nouvelle demande de réinitialisation depuis la page 'connexion'
  `,
  'auth/too-many-requests': `
    Ce compte à été temporairement désactivé suite à de trop nombreuses tentatives de connexion non fructueuses.
    Vous pouvez le restorer immédiatement en changeant votre mot de passe ou vous pouvez rééssayer utlérieurement.
  `,
  'auth/weak-password':
    'Ce mot de passe ne peut pas être utilisé car il est trop fragile. Il doit comporter au moins 6 caractères.',
  'auth/requires-recent-login': `vous devez vous réauthentifier pour effectuer cette opération.`,
  'account-locked': (
    <div>
      Votre compte est verrouillé, Il doit être déverrouillé par un administrateur SAM.I.
      <br />
      <br />
      Si vous ne recevez pas de mail de notre part dans les 48h, veuillez utiliser ce formulaire de&nbsp;
      <Link href="https://www.tech4work.fr/contact/" target="_blank" rel="noreferrer">
        contact
      </Link>
      &nbsp;ici en expliquant votre besoin.
    </div>
  ),
  'invalid-auth-code': `
    Le code renseigné n'est pas valide.
    Vous pouvez le trouver dans votre mail d'invitation.
  `,
};

export type DatabaseCollection = 'profile' | 'beneficiary' | 'consultant' | 'followedBeneficiaries' | string;

export function globalGetDocRef<T>(path: string) {
  return doc(db, path) as DocumentReference<T>;
}

const application: FirebaseApp = initializeApp(firebaseConfiguration);
export const auth: Auth = getAuth(application);
export const db: Firestore = getFirestore(application);
