import React from 'react';
import Typography from '@mui/material/Typography';
import { ProfileAvatar } from '../../../Components';
import { Header } from '../../../Components';

interface IHeaderDashboardProps {
  firstname?: string;
}

export const HeaderDashboard = ({ firstname }: IHeaderDashboardProps) => {
  return (
    <Header
      picture={<ProfileAvatar size={75} />}
      title={
        <>
          <Typography variant="h1" className='capital'>Bienvenue {firstname} !</Typography>
          <Typography variant="h3" color="GrayText">
            Que souhaitez-vous faire aujourd&apos;hui ?
          </Typography>
        </>
      }
    />
  );
};
