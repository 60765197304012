/**
 * @param T an interface mapping to the collection schema in the database
 * @param R an interface mapping to the rows schema in the data table
 */
export default abstract class AbstractDataTableService<T, R> {
  /* Data table current page */
  protected _page = 0;
  /* Total results of the data table */
  protected _itemCount = 0;
  public get itemCount(): number {
    return this._itemCount;
  }
  /**
   * Number of results to show in the data table.
   *
   * Can be accessed and configured from outside this class
   * if necessary. In example if a datatable component offers
   * the possibility to change the number of rows shown.
   */
  protected _take;
  public get take(): number {
    return this._take;
  }

  public set take(size: number) {
    this._take = size;
  }

  public set itemCount(count: number) {
    this._itemCount = count;
  }

  constructor(pageSize: number) {
    this._take = pageSize;
  }

  /**
   * Build the rows according to the row schema <R>
   * @param data
   */
  public buildRows(data: T[]): R[] {
    this._itemCount = data.length || 0;
    const rows: R[] = [];
    for (const d of data) {
      rows.push(this.buildRow(d))
    }
    return rows;
  }

  /**
   * Build a row according to the row schema <R>
   * Must be implemented in children classes to match the schema.
   * @param rowInformations
   */
  protected abstract buildRow(rowInformations: T): R;
}
