import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useStateMachine } from 'little-state-machine';

import { Header } from '../../../Components';
import APIAxios, { APIRoutes } from '../../../API/api.axios';
import { GetBeneficiaryDto } from '../../../Types';
import { updateUserState } from '../../../Stores';

type TUserInformationsHeaderProps = {
  beneficiary?: GetBeneficiaryDto;
};

export const UserInformationsHeader = ({ beneficiary }: TUserInformationsHeaderProps) => {
  const {
    actions
  } = useStateMachine({ updateUserState });
  const { enqueueSnackbar } = useSnackbar();
  const [ helped, setHelped ] = useState<boolean>(beneficiary?.isHelpNeeded || false)
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleHelped = async () => {
    try {
      const res = await APIAxios({...APIRoutes.PATCHBeneficiaries(), data: {isHelpNeeded: !beneficiary?.isHelpNeeded}})
      
      if (res.data) {
        actions.updateUserState({beneficiary: res.data})
        setHelped(!helped)

        if (res.data.isHelpNeeded)
          enqueueSnackbar("Votre demande d'aide a bien été envoyée à votre consultant", { variant: "success" })
        else
          enqueueSnackbar("Votre demande d'aide a bien été annulée", { variant: "success" })
      } else {
        enqueueSnackbar("Une erreur est survenue lors de la demande d'aide. Veuillez réessayer plus tard.", { variant: "error" })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Header
      title={
        <Grid display="flex" flexDirection={isSm ? "column" : "row"} justifyContent="space-between" gap={2}>
          <Grid container flexDirection="column">
            <Typography variant="h1" component="h1">
              Mes Informations
            </Typography>

            <Typography variant="h3" color="GrayText">
              Visible par vos consultants
            </Typography>
          </Grid>

            <Button 
                variant="contained" 
                onClick={handleHelped.bind(this)} 
              >
              {helped ?
                "J'annule la demande d'aide"
              :
                "Je me fais aider par le consultant"
              }
            </Button>
        </Grid>
      }
    />
  );
};
