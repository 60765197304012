import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useUserProfile } from '../../../Hooks';
import { BeneficiaryProfileContext } from '../../../Providers';
import { StatusBoe, TypeWorkArrest } from '../../../Types';

export const BeneficiaryActualSituation = () => {
  const { user } = useUserProfile(BeneficiaryProfileContext);
  const beneficiary = user?.beneficiary;
  const translateBoolean = (bool: boolean | undefined): string => {
    return bool ? 'Oui' : 'Non';
  };

  return (
    <Grid container gap={4}>
      <Typography variant="h3">Situation actuelle</Typography>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Bénéficiaire de l&apos;Obligation d&apos;Emploi (BOE) ?
          </Typography>
        </Grid>
        <Typography variant="body1">{translateBoolean(beneficiary?.isBOE)}</Typography>
      </Grid>
      {beneficiary?.isBOE && beneficiary.statusBOE && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="gray">
              Situation
            </Typography>
          </Grid>
          <Typography variant="body1">{StatusBoe.label(beneficiary?.statusBOE)}</Typography>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            En arrêt de travail ?
          </Typography>
        </Grid>
        <Typography variant="body1">{translateBoolean(beneficiary?.isWorkArrest)}</Typography>
      </Grid>
      {beneficiary?.isWorkArrest && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="gray">
                Date d&apos;arrêt
              </Typography>
            </Grid>
            <Typography variant="body1">{beneficiary?.dateWorkArrest ? new Date(beneficiary.dateWorkArrest).toISOString().split('T')[0] : '-'}</Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="gray">
                Type d&apos;arrêt
              </Typography>
            </Grid>
            <Typography variant="body1">
              {beneficiary.typeWorkArrest ? TypeWorkArrest.label(beneficiary.typeWorkArrest) : '-'}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
