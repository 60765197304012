import React, {useCallback, useEffect, useState} from 'react';
import { Button, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { Popup } from '../../Theme';
import { NewHeader } from './new-title.component';
import Close from '../../Assets/cross_close.svg';
import APIAxios, { APIRoutes } from '../../API/api.axios';
import { useSnackbar } from 'notistack';
import { GetBeneficiaryDto, SuspendReason } from '../../Types';
import { FieldErrors } from 'react-hook-form';
import { suspendValidation } from './suspend-beneficiary.validation';

export interface Suspend {
  suspendReason: string;
  suspendDate: string;
}

interface SuspendBenefPopupProps {
  beneficiaryId:string
  beneficiary?: GetBeneficiaryDto
  handleClose: () => void;
  isSuspended?: boolean
}

export const SuspendbenefPopup = (props: SuspendBenefPopupProps) => {

  const { handleClose, beneficiaryId, beneficiary, isSuspended } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [suspend, setSuspend] = useState<Partial<Suspend>>({});
  const [errors, setErrors] = useState<FieldErrors>();

  const handleSuspendBenef = useCallback( async () => {
    if (isSuspended) {
      try {
        await APIAxios({...APIRoutes.PATCHSuspendBenef(beneficiaryId), data: { isSuspended: false }});
        handleClose();
      } catch (err) {
        enqueueSnackbar('Une erreur est survenue. Veuillez réessayer plus tard', { variant: 'error' });
      }
    }
    const suspendErrors = suspendValidation(suspend);
    if (!Object.keys(suspendErrors).length && beneficiaryId && suspend) {
      try {
        const res = await APIAxios({...APIRoutes.PATCHSuspendBenef(beneficiaryId), data:
          {...suspend,
          isSuspended: true}
        })
        handleClose();
      } catch (err) {
        enqueueSnackbar('Une erreur est survenue. Veuillez réessayer plus tard', { variant: 'error' });
      }
    } else {
      setErrors(suspendErrors);
    }
  }, [isSuspended,beneficiaryId, isSuspended, enqueueSnackbar, handleClose, suspend]);

  const updateSuspend = (name: string) => (value: any) => {
    const updateSuspend = {
      ...suspend,
      [name]: value
    };
    setSuspend(updateSuspend)
  }

  return (
    <Popup open={true} maxWidth='sm' fullWidth>
      <Stack direction='column' padding={4} gap={3}>
        <Stack direction='row' justifyContent='space-between'>
          <NewHeader title='Issue de parcours'/>
          <Button onClick={() => handleClose()}>Annuler</Button>
        </Stack>
        <Typography>
          {isSuspended? 'Vous allez désarchiver le parcours de votre bénéficiaire.' :
          'Vous allez archiver le parcours de votre bénéficiaire. Veuillez renseigner son issue.'}
        </Typography>
        {!isSuspended && (
          <>
            <TextField
              fullWidth
              required
              autoFocus
              type="date"
              onChange={(e) => updateSuspend('suspendDate')(e.target.value)}
              color="primary"
              placeholder="Date de fin de parcours"
              label="Date de fin de parcours"
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors?.suspendDate}
              helperText={errors?.suspendDate}
            />
            <TextField
              fullWidth
              select
              label='Votre issue'
              type="text"
              color="primary"
              placeholder="Votre issue"
              onChange={(e) => updateSuspend('suspendReason')(e.target.value)}
              error={!!errors?.suspendReason}
              helperText={errors?.suspendReason}
            >
                {SuspendReason.SuspendReasons.map((key, index) => (
                  <MenuItem value={key} key={index}>
                    {SuspendReason.label(key)}
                  </MenuItem>
                ))}
            </TextField>
          </>
        )}
        <Button variant='contained' color='warning' onClick={() => { handleSuspendBenef()}}>
          {isSuspended ? 'Désarchiver' : 'Enregistrer'}
        </Button>
      </Stack>
    </Popup>
  )
}