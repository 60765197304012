import React, { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectOption } from '../../../Components/Molecules/organism-search.molecule';
import { Button, Grid, IconButton, MenuItem, Radio, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import APIAxios, { APIRoutes } from '../../../API/api.axios';
import { NewHeader } from '../../../Components/Molecules/new-title.component';
import AsyncSelectWithSearchComponent from '../../../Components/Molecules/organism-search.molecule';
import { Popup } from '../../../Theme';
import { CreateConsultantDto, GetConsultantDto, GetOrganismDto, PostOccupiedEnum } from '../../../Types';
import { consultantValidation } from './consultant-validation';
import { useSnackbar } from 'notistack';
import Close from '../../../Assets/cross_close.svg'
import { useError } from '../../../Hooks';

export const CreateConsultantPopup = () => {

  const { conslutantId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()
  const { addError } = useError();

  const formMethods = useForm<Partial<GetConsultantDto>>({ resolver: yupResolver(consultantValidation), mode: 'onChange'});

  const [consultant, setConsultant] = useState<Partial<CreateConsultantDto>>();
  const [isPremium, setIsPremium] = useState(false);
  const [selectedOrganism, setSelectedOrganism] = useState<SelectOption | undefined>();

  const getOrganisms = async (q?: string): Promise<SelectOption[]> => {
    return await APIAxios({...APIRoutes.GETOrganisms(1, q, 50)})
    .then((res) => {
      if (res.data) {
        return res.data.data.map((it: GetOrganismDto) => ({ label: it.name, value: it.id, data: it }));
      }
    })
  }

  const getConsultant = useCallback(async () => {
    if (conslutantId) {
      try {
        const res = await APIAxios({...APIRoutes.GETConsultantById(conslutantId)})
        if (res.data) {
          const createConsultant = res.data;
          createConsultant.organismId = res.data.organism.id;
          setConsultant(createConsultant);
          setIsPremium(res.data.isPremium);
        }
      } catch (err) {

      }
    }
  }, [conslutantId]);

  const updateConsultant = (name: string) => (value: any) => {
    const updateConsultant = {
      ...consultant,
      [name]: value,
    };
    setConsultant(updateConsultant);
  }

  const handleCreateConsultant = useCallback(async () => {
    if (!conslutantId) {
      try {
        const res = await APIAxios({...APIRoutes.POSTCreateConsultant(), data: consultant});
        if (res.data as GetConsultantDto) {
          enqueueSnackbar('Le consultant a bien été créé.', { variant: "success" });
          navigate(-1);
        } else {
          enqueueSnackbar('Une erreur est survenue. Veuillez réessayer plus tard.', { variant: 'error' });
        }
      } catch (err: any) {
        switch (err.response.status) {
          case 400:
            err.response.data.message.forEach((it: string) => {
              switch (it) {
                case ('phoneNumber must be a valid phone number'):
                  addError({ message: "Le numéro de téléphone doit être un numéro français valide"})
                  break;
                default:
                  break
              }
            })
            break;
          case 409:
            addError({message: 'Cet email est déjà associé à un compte.'})
            break;
          default:
            addError({message: 'Une erreur est survenue. Veuillez réessayer plus tard.'})
            break;
        }
      }
    } else {

    }
  }, [conslutantId, consultant, enqueueSnackbar, navigate]);

  useEffect(() => {
    getConsultant();
  }, [getConsultant]);

  useEffect(() => {
    updateConsultant('isPremium')(isPremium);
  }, [isPremium]);


  useEffect(() => {
    updateConsultant('organismId')(selectedOrganism?.data.id);
  }, [selectedOrganism]);

  return (
    <Popup open={true} maxWidth='md' fullWidth>
      <Stack padding={4} gap={2}>
        <Stack justifyContent='space-between' direction='row' alignItems='top'>
          <NewHeader title={`${'Ajouter'} un consultant`} />
          <IconButton onClick={() => navigate(-1)}><img src={Close} alt='' /></IconButton>
        </Stack>
        <Grid container direction='column' gap={2}>
          <Grid item xs>
            <Grid container gap={2}>
              <Grid item xs>
                <TextField
                  fullWidth
                  type="text"
                  color="primary"
                  placeholder="Nom"
                  value={consultant?.lastName}
                  label="Nom"
                  onChange={(e) => updateConsultant("lastName")(e.target.value)}
                    />
              </Grid>
              <Grid item xs>
                <TextField
                  onChange={(e) => updateConsultant("firstName")(e.target.value)}
                  fullWidth
                  type="text"
                  color="primary"
                  value={consultant?.firstName}
                  placeholder="Prénom"
                  label="Prénom"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container gap={2}>
              <Grid item xs>
                    <TextField
                    onChange={(e) => updateConsultant("phoneNumber")(e.target.value)}
                      fullWidth
                      type="text"
                      value={consultant?.phoneNumber || ''}
                      color="primary"
                      placeholder="Téléphone"
                      label="Téléphone"
                    />
              </Grid>
              <Grid item xs>
                <TextField
                  onChange={(e) => updateConsultant("email")(e.target.value)}
                  fullWidth
                  value={consultant?.email || ''}
                  type="text"
                  color="primary"
                  placeholder="Adresse mail"
                  label="Adresse mail"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container gap={2}>
              <Grid item xs>
                <TextField
                  fullWidth
                  select
                  type='text'
                  value={consultant?.postOccupied || ''}
                  color="primary"
                  placeholder='Poste occupé'
                  label='Poste occupé'
                  onChange={(e) => updateConsultant("postOccupied")(e.target.value)} >
                    {PostOccupiedEnum.allPosts.map((it) => (
                      <MenuItem value={it} key={it}>
                        {PostOccupiedEnum.label(it)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              <Grid item xs>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction='row' gap={2}>
          <Grid item xs>
            <Grid container>
              <Grid item xs container gap={2}>
               <Grid item>
                <Typography fontWeight='600' fontSize='13px'>Organisme</Typography>
               </Grid>
               <Grid item>
                <Typography fontSize='12px' fontWeight='400'>
                  Veuillez saisir le nom de l’organisme auquel vous souhaitez 
                  rattacher ce consultant :
                </Typography>
               </Grid>
               <Grid item xs>
                <AsyncSelectWithSearchComponent
                  getOptions={(v) => getOrganisms(v)}
                  placeholder={'Organisme'}
                  value={selectedOrganism}
                  handleChange={(value) => {
                    setSelectedOrganism(value)
                  }}
                 />
               </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs container direction="column" gap={2}>
            <Grid item>
              <Typography fontWeight='600' fontSize='13px'>
                Statut du consultant
              </Typography>
            </Grid>
            <Grid item>
              <Stack direction='column'>
                <Stack direction='row' alignItems='center'>
                  <Radio
                    checked={!!consultant?.isPremium}
                    onClick={() => setIsPremium(!!!consultant?.isPremium)}
                  />
                  <Typography fontSize='13px'>Premium</Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Stack justifyContent='center' alignItems='center'>
          <Button variant='contained' onClick={handleCreateConsultant}>Ajouter</Button>
        </Stack>
      </Stack>
    </Popup>
  )
}