import { GetConsultantDto } from "../../../Types";
import AbstractDataTableService from '../data-table.base.service';

export interface ConsultantRow {
  id: string;
  isDeleted?: boolean;
  name: string;
  postOccupied?: string;
  organism?: string;
}

class ConsultantsDataTableService extends AbstractDataTableService<GetConsultantDto, ConsultantRow> {
  protected buildRow(consultant: GetConsultantDto): ConsultantRow {
    return {
      id: consultant.id,
      isDeleted: consultant.isDeleted,
      name: consultant.firstName + " " + consultant.lastName,
      postOccupied: consultant.postOccupied,
      organism: consultant.organism?.name
    }
  }
}

export const consultantDataTableService = new ConsultantsDataTableService(10);