import { Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginCard } from '../../Components/Organisms/login-card.component';
import Logo from '../../Assets/logo.png';
import { Copyright } from '../../Components';

export const SelectRole = () => {

  const navigate = useNavigate();

  const handleSelectType = (type: string) => {
    navigate(`/login/${type}`)
  };

  return (
    <Grid
      container
      direction="column"
      flexWrap='nowrap'
      justifyContent='start'
      alignItems='center'
      sx={{minHeight: '100vh'}}
      gap={{xs: 3, sm: 10}}
      position='relative'
      padding={{xs: '1rem', sm: '2rem'}}
      paddingBottom={{xs: '4rem'}}
      paddingTop={{xs: '3rem', sm: '8rem'}}>
      <Grid item>
          <Grid 
            container 
            direction="column" 
            spacing={1} 
            justifyContent="center" 
            alignItems="center">
            <Grid item>
              <img src={Logo} style={{maxWidth: '150px'}} alt='logo' />
            </Grid>
            <Grid item>
              <Typography 
                align='center' 
                fontSize={15} 
                fontWeight={500} 
                sx={{color: 'rgba(43, 15, 54)'}}>
                  Votre plateforme collaborative dédiée au maintien dans l'emploi
              </Typography>
            </Grid>
          </Grid>
      </Grid>
      <Grid item padding={2} sx={{zIndex: 1000}}>
        <Grid 
          container 
          direction={{xs: 'column', sm: 'row'}} 
          flexWrap="wrap" 
          spacing={3} 
          justifyContent='center' 
          alignItems='center'>
          <Grid item xs={12} sm={4} md={2.7}>
            <LoginCard type={'beneficiary'} handleClick={handleSelectType} />
          </Grid>
          <Grid item xs={12} sm={4} md={2.7}>
            <LoginCard type={'consultant'} handleClick={handleSelectType} />
          </Grid>
          <Grid item xs={12} sm={4} md={2.7}>
            <LoginCard type={'organism'} handleClick={handleSelectType} />
          </Grid>
        </Grid>
      </Grid>
      <Copyright/>
    </Grid>
  )
}