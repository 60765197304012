import { TUserProfile, TUserState, UserState } from '../../Types';

export const initialUserProfile: TUserProfile = {
  gender: '',
  birthday: '',
  city: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: null,
  postcode: '',
  address: '',
};

export const initialUserState: UserState = {
  isAuth: null,
  id: undefined,
  // profile: initialUserProfile,
  beneficiary: undefined,
  consultant: undefined,
};

