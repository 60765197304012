import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Header } from '../../../Components';
import { usePopup } from '../../../Hooks';
import AddIcon from '@mui/icons-material/Add';

export const ConsultantsHeader = () => {
  const { openPopup } = usePopup('CreateInvitationPopup');
  return (
    <Header
      title={
        <>
          <Typography variant="h1" component="h1">
            Mes consultants
          </Typography>
          <Typography variant="h3" color="GrayText">
            Ce professionnels ont accès à votre fiche informations et parcours pour vous accompagner au mieux
          </Typography>
        </>
      }
      actions={
        <Button color="primary" variant="contained" onClick={() => openPopup()}>
          <AddIcon style={{ marginRight: 8 }} />
          Inviter un consultant
        </Button>
      }
    ></Header>
  );
};
