import React, { useCallback, useEffect, useState } from 'react';
import { Stack, IconButton, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import APIAxios, { APIRoutes } from '../../../API/api.axios';
import { BeneficiaryStatus, ContractEnum, GetBeneficiaryDto, LanguageLevel, LevelSchool, StatusBoe, TypeWorkArrest } from '../../../Types';
import { NewHeader } from '../../../Components/Molecules/new-title.component';
import ArrowLeft from '../../../Assets/arrow_left.svg';
import PersonAvatar from '../../../Assets/person_avatar.svg';
import { SimpleCard } from '../../../Components/Atoms/cimple-card.atom';
import { ReadOnlyField } from '../../../Components';
import { UserLightDto } from '../../../Types/actions.type';


export const BeneficiaryDetailComponent = () => {
  const { beneficiaryId } = useParams();
  const navigate = useNavigate();

  const [beneficiary, setBeneficiary] = useState<GetBeneficiaryDto | undefined>(undefined);

  const getBeneficiary = useCallback(async () => {
    if (beneficiaryId) {
      try {
        const res = await APIAxios({...APIRoutes.GETBeneficiary(beneficiaryId)})
        if (res.data as GetBeneficiaryDto) {
          setBeneficiary(res.data);
        }
      } catch (err) {
      }
    }
  }, [beneficiaryId]);

  useEffect(() => {
    getBeneficiary();
  }, [getBeneficiary]);

  if (!beneficiary) {
    return (
      <div>Chargement ...</div>
    )
  }

  return (
    <Stack spacing={3}>
      <Stack direction="row" alignItems='baseline'>
          <IconButton onClick={() => navigate(-1)}>
            <img src={ArrowLeft} alt='retour' />
          </IconButton>
          <NewHeader title={beneficiary?.firstName + " " + beneficiary?.lastName} />
        </Stack>
        <Grid container direction='row' gap={3}>
          <Grid item xs container gap={3} direction='column'>
            <Grid item xs>
              <SimpleCard title='Informations personnelles'>
                <ReadOnlyField name="Nom" value={beneficiary?.lastName} />
                <ReadOnlyField name='Prénom' value={beneficiary?.firstName} />
                <ReadOnlyField name='Date de naissance' value={beneficiary?.birthday && new Date(beneficiary.birthday).toISOString().split('T')[0]} />
                <ReadOnlyField name='Adresse' value={beneficiary?.address} />
                <ReadOnlyField name='Ville' value={(beneficiary?.postcode ? (beneficiary.postcode + ' '): '') + beneficiary?.city} />
                <ReadOnlyField name='Téléphone' value={beneficiary?.phoneNumber} />
              </SimpleCard>
            </Grid>
            <Grid item xs>
              <SimpleCard title="Situation actuelle"> 
                <ReadOnlyField name='Bénéficiaire de BOE' value={!!beneficiary?.isBOE ? 'Oui' : 'Non'} />
                <ReadOnlyField name='Situation' value={beneficiary?.statusBOE ? StatusBoe.label(beneficiary.statusBOE) : '-'} />
                <ReadOnlyField name='Arrêt de travail' value={!!beneficiary?.isWorkArrest ? 'Oui' : 'Non'} />
                <ReadOnlyField name='Date' value={beneficiary?.dateWorkArrest && new Date(beneficiary.dateWorkArrest).toISOString().split('T')[0]} />
                <ReadOnlyField name="Type d'arrêt" value={beneficiary?.typeWorkArrest ? TypeWorkArrest.label(beneficiary?.typeWorkArrest) : '-'} />
              </SimpleCard>
            </Grid>
          </Grid>
          <Grid item xs>
            <SimpleCard title='Informations Professionnelles'>
              <ReadOnlyField name='Statut' value={beneficiary?.status ? BeneficiaryStatus.label(beneficiary?.status) : '-'} />
              <ReadOnlyField name='Poste Occupé' value={beneficiary?.job} />
              <ReadOnlyField name='Code APE' value={beneficiary?.ape} />
              <ReadOnlyField name='Type de contrat' value={beneficiary?.contract ? ContractEnum.label(beneficiary.contract) : '-'} />
              <ReadOnlyField name='Département du lieu de travail' value={beneficiary?.departmentLocation?.toString()} />
              <ReadOnlyField name='Formation' value={beneficiary?.job} />
              <ReadOnlyField name='Niveau scolaire' value={beneficiary?.levelSchool ? LevelSchool.label(beneficiary?.levelSchool) : '-'} />
              <ReadOnlyField name='Langue' value={beneficiary?.language} />
              <ReadOnlyField name='Niveau de langue' value={beneficiary?.languageLevel ? LanguageLevel.label(beneficiary.languageLevel) : '-'} />
            </SimpleCard>
          </Grid>
          <Grid item xs>
            <SimpleCard title='Consultants'>
              <Grid container direction='row' flexWrap='wrap' spacing={2}>
                <>
                  {beneficiary?.consultantsName?.map((it: UserLightDto) => {
                    return (
                      <Grid item xs={6}>
                        <Stack alignItems='center' gap={1}>
                          <img src={PersonAvatar} alt='' style={{height: '80px', width: '80px'}} />
                          <Stack justifyContent='center' alignItems='center'>
                            <Typography fontSize='13px' fontWeight='500'>{it.firstName + ' ' + it.lastName}</Typography>
                            <Typography fontSize='11px' fontWeight='500' sx={{color: 'rgba(155, 159, 164)'}}>{it.organismName}</Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    )})}
                </>
              </Grid>
            </SimpleCard>
          </Grid>
        </Grid>
    </Stack>
  )
}