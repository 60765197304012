import MuiDrawer from '@mui/material/Drawer';
import { styled, CSSObject, Theme } from '@mui/material/styles';

const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.customShapes.drawerWidth,
  transition: theme.transitions.create('width', {
    easing: 'ease-out',
    duration: '.2s',
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: 'ease-out',
    duration: '.2s',
  }),
  overflowX: 'hidden',
  width: `100px`,
  //   [theme.breakpoints.up('sm')]: {
  //     width: `calc(${theme.spacing(9)} + 2px)`,
  //   },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: theme.customShapes.drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  border: 'none !important',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      // borderRight: `${theme.customShapes.bordersWidth} solid ${theme.palette.divider}`,
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      // borderRight: `${theme.customShapes.bordersWidth} solid ${theme.palette.divider}`,
    },
  }),
}));

export const MobileDrawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: theme.customShapes.drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  border: ``,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      // borderRight: `${theme.customShapes.bordersWidth} solid ${theme.palette.divider}`,
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      // borderRight: `${theme.customShapes.bordersWidth} solid ${theme.palette.divider}`,
    },
  }),
}));
