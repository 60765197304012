import React from 'react';
import { Stack, Typography } from '@mui/material';

interface HeaderProps {
  title: string;
  image?: string;
}

export const NewHeader = (props: HeaderProps) => {
  const { image, title } = props;
  return (
    <Stack direction="row" alignItems='center' spacing={1} paddingBottom={2}>
      {image && (
        <img src={image} alt='' style={{height: '20px', width: '20px'}} />
      )}
      <Typography fontSize='20px' fontWeight='600' component="h1" sx={{color: 'black'}}>
        {title}
      </Typography>
    </Stack>
  )
}