/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useCallback, useState } from 'react';
import { TContainer } from '../Types';

interface IDatatableSearchContext {
  search: string;
  updateSearch: (s: string) => void;
  clearSearch: () => void;
}

export const DatatableSearchContext = createContext<IDatatableSearchContext>({
  search: '',
  updateSearch: () => {},
  clearSearch: () => {}
});

export const DatatableSearchProvider = ({children}: TContainer) => {
  const [search, setSearch] = useState<string>('');

  const updateSearch = (s: string) => setSearch(s);
  const clearSearch = () => setSearch('');

  const contextValue = {
    search,
    updateSearch: useCallback((s: string) => updateSearch(s), []),
    clearSearch: useCallback(() => clearSearch, [])
  }
  return <DatatableSearchContext.Provider value={contextValue}>{children}</DatatableSearchContext.Provider>
}
