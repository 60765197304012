import React from 'react';
import Paper from '@mui/material/Paper';
import { TContainer } from '../../Types';
import { useMediaQuery, useTheme } from '@mui/material';

export const PaperContainer = ({ children }: TContainer) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Paper
      sx={{
        width: '100%',
        boxShadow: theme.customShadows.shadow2,
        padding: isSm ? theme.spacing(1) : theme.spacing(2),
      }}
    >
      {children}
    </Paper>
  );
};
