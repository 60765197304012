import React, { useCallback, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  gridDateComparator,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { ClickableDatatableCell } from "../MyBeneficiaries/Components";
import { DataTableHeader } from "../../Components/Molecules";
import { IUserActionsRow, userActionsDatatableService } from "../../Services";
import { CustomPagination } from "../../Theme";
import { TActionStatus, TUserInformationMode } from "../../Types";
import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import { ProfileAvatar } from "../../Components";
import {
  ActionStatus,
  ActionType,
  GetActionLightDto,
} from "../../Types/actions.type";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useActions } from "../../Hooks";
import { useStateMachine } from "little-state-machine";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiPagination-ul": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    "&.MuiDataGrid-root": {
      border: "none",
    },
    "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
      borderBottom: "1px solid #e9e9e9",
    },
    "&.MuiDataGrid-root .MuiDataGrid-footerContainer": {
      borderTop: "1px solid #e9e9e9",
    },
    "&.MuiDataGrid-root *:focus-within": {
      outline: "none",
    },
    "&.MuiDataGrid-root .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
    },
    "&.MuiDataGrid-root .MuiDataGrid-cell": {
      whiteSpace: "normal",
    },
    [theme.breakpoints.up(1080)]: {
      overflow: "visible",
      paddingLeft: "20px",
      "&.MuiDataGrid-root .MuiDataGrid-virtualScroller": {
        overflow: "visible",
        overflowX: "visible !important",
      },
      "&.MuiDataGrid-root .MuiDataGrid-main": {
        overflow: "visible",
      },
      "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
        overflow: "hidden",
        whiteSpace: "normal",
        textAlign: "center",
      },
      "&.MuiDataGrid-root .MuiDataGrid-cell": {
        overflow: "hidden",
        whiteSpace: "normal",
        borderBottom: "1px solid #e9e9e9",
      },
      "&.MuiDataGrid-root .MuiDataGrid-row:not(:only-of-type)": {
        position: "relative",
        "&:before": {
          position: "absolute",
          left: "-20px",
          top: "calc(50% - 6px)",
          content: '""',
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: theme.palette.primary.main,
        },
        "&:after": {
          position: "absolute",
          height: "98%",
          top: 0,
          left: "calc(-20px + 5px)",
          content: '""',
          borderWidth: "1px",
          borderStyle: "dashed",
          borderColor: theme.palette.primary.main,
        },
      },
      "&.MuiDataGrid-root .MuiDataGrid-row:first-of-type:not(:only-of-type)": {
        position: "relative",
        "&:before": {
          position: "absolute",
          left: "-20px",
          top: "calc(50% - 6px)",
          content: '""',
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: theme.palette.primary.main,
        },
        "&:after": {
          position: "absolute",
          height: "48%",
          top: "50%",
          left: "calc(-20px + 5px)",
          content: '""',
          borderWidth: "1px",
          borderStyle: "dashed",
          borderColor: theme.palette.primary.main,
        },
      },
      "&.MuiDataGrid-root .MuiDataGrid-row:last-of-type:not(:only-of-type)": {
        position: "relative",
        "&:before": {
          position: "absolute",
          left: "-20px",
          top: "calc(50% - 6px)",
          content: '""',
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: theme.palette.primary.main,
        },
        "&:after": {
          position: "absolute",
          height: "48%",
          top: 0,
          left: "calc(-20px + 5px)",
          content: '""',
          borderWidth: "1px",
          borderStyle: "dashed",
          borderColor: theme.palette.primary.main,
        },
      },
      "&.MuiDataGrid-root .MuiDataGrid-row:only-of-type": {
        position: "relative",
        "&:before": {
          position: "absolute",
          left: "-20px",
          top: "calc(50% - 6px)",
          content: '""',
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
  label: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  todo: {
    color: theme.secondaryPalette.orange,
    backgroundColor: theme.secondaryPalette.lightOrange,
  },
  pending: {
    color: theme.secondaryPalette.purple,
    backgroundColor: theme.secondaryPalette.lightPurple,
  },
  done: {
    color: theme.palette.primary.main,
    backgroundColor: theme.secondaryPalette.lightGreen,
  },
  abandoned: {
    color: theme.secondaryPalette.yellow,
    backgroundColor: theme.secondaryPalette.lightYellow,
  },
  refused: {
    color: theme.secondaryPalette.red,
    backgroundColor: theme.secondaryPalette.lightRed,
  },
}));

//TODO set this to its own file (reusable in my actions);
const StatusLabel = ({ status }: { status: ActionStatus }) => {
  const classes = useStyles();

  const getClass = (status: ActionStatus) => {
    if (status === ActionStatus.TO_BE_REALIZED) {
      return classes.todo;
    } else if (status === ActionStatus.IN_PROGRESS) {
      return classes.pending;
    } else if (status === ActionStatus.CANCELLED) {
      return classes.abandoned;
    } else if (status === ActionStatus.REFUSED) {
      return classes.refused;
    } else {
      return classes.done;
    }
  };
  return (
    <Typography
      textAlign="start"
      variant="body2"
      className={`${classes.label} capital ${getClass(status)}`}
    >
      {status ? ActionStatus.label(status) : "-"}
    </Typography>
  );
};

const columns: GridColDef[] = [
  {
    field: "type",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    headerAlign: "left",
    align: "left",
    renderHeader: () => <DataTableHeader headerName="Actions" />,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="body1">
          {ActionType.label(params.value as ActionType)}
        </Typography>
      </>
    ),
  },
  {
    field: "consultant",
    headerName: "Consultant",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 150,
    align: "left",
    headerAlign: "left",
    renderHeader: () => <DataTableHeader headerName="Consultant" />,
    renderCell: (params: GridCellParams) => (
      <Grid container gap={2} flexWrap="nowrap" alignItems="center">
        <ProfileAvatar size={37} />
        <Typography
          variant="body1"
          className="capital"
          whiteSpace="normal"
          textAlign="start"
        >
          {params.value}
        </Typography>
      </Grid>
    ),
  },
  {
    field: "date",
    type: "date",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 100,
    align: "center",
    headerAlign: "center",
    sortComparator: gridDateComparator,
    renderHeader: () => <DataTableHeader headerName="Date" />,
    renderCell: (params: GridCellParams<Date>) => (
      <Typography variant="body1">
        {params?.value ? new Date(params.value).toLocaleDateString() : "-"}
      </Typography>
    ),
  },
  {
    field: "status",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 100,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Statut" />,
    renderCell: (params: GridCellParams<TActionStatus>) => (
      <StatusLabel status={params.value as ActionStatus} />
    ),
  },
  {
    field: "consultOrEdit",
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Voir / Modifier" />,
    renderCell: (params: GridRenderCellParams) => (
      <ClickableDatatableCell action="edit" {...params} />
    ),
  },
];

const columnsRead: GridColDef[] = [
  {
    field: "type",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    headerAlign: "left",
    align: "left",
    renderHeader: () => <DataTableHeader headerName="Actions" />,
    renderCell: (params: GridCellParams) => (
      <>
        <Typography variant="body1" className="capital">
          {ActionType.label(params.value)}
        </Typography>
      </>
    ),
  },
  {
    field: "consultant",
    headerName: "Consultant",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 150,
    align: "left",
    headerAlign: "left",
    renderHeader: () => <DataTableHeader headerName="Consultant" />,
    renderCell: (params: GridCellParams) => (
      <Grid container gap={2} flexWrap="nowrap">
        <ProfileAvatar size={37} />
        <Typography
          variant="body1"
          className="capital"
          whiteSpace="normal"
          textAlign="start"
        >
          {params.value}
        </Typography>
      </Grid>
    ),
  },
  {
    field: "date",
    type: "date",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 100,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Date" />,
    renderCell: (params: GridCellParams<Date>) => (
      <Typography variant="body1">
        {params?.value ? new Date(params.value).toLocaleDateString() : "-"}
      </Typography>
    ),
  },
  {
    field: "consultOrEdit",
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Descriptif" />,
    renderCell: (params: GridRenderCellParams) => (
      <ClickableDatatableCell action="journey-read" {...params} />
    ),
  },
  {
    field: "status",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 100,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Statut" />,
    renderCell: (params: GridCellParams<TActionStatus>) => (
      <StatusLabel status={params.value as ActionStatus} />
    ),
  },
];

type TBeneficiaryJourneyProps = {
  mode: TUserInformationMode;
};

export const BeneficiaryJourney = ({ mode }: TBeneficiaryJourneyProps) => {
  const { singleBenefActions, setBenefId, setPage } = useActions();
  const classes = useStyles();

  const [actionsRows, setActionsRows] = useState<IUserActionsRow[]>();
  const [searchParams] = useSearchParams();
  const uid = searchParams.get("uid");
  setBenefId(uid || undefined);

  const buildActionRows = useCallback((actions: GetActionLightDto[]) => {
    if (actions) {
      const ar = userActionsDatatableService.buildRows(actions);
      setActionsRows(ar);
    }
  }, []);

  useEffect(() => {
    if (singleBenefActions) {
      buildActionRows(singleBenefActions.data);
    }
  }, [buildActionRows, singleBenefActions]);

  if (!actionsRows) return <div>Loading ...</div>;

  if (actionsRows.length === 0)
    return (
      <Typography variant="body1" textAlign="center">
        {mode === "journey-read"
          ? "Vous n'avez pas encore d'action sur votre parcours"
          : "Le bénéficiaire n'a pas encore d'action sur son parcours"}
      </Typography>
    );

  return (
    <DataGrid
      rows={actionsRows}
      rowHeight={85}
      columns={mode === "journey-read" ? columnsRead : columns}
      pageSize={singleBenefActions?.meta.take}
      autoHeight
      disableSelectionOnClick
      paginationMode="server"
      density="comfortable"
      className={classes.root}
      onPageChange={setPage}
      rowCount={singleBenefActions?.meta.itemCount || 0}
      components={{
        Pagination: CustomPagination,
      }}
    />
  );
};
