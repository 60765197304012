import React, { Fragment } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import { PasswordTextField } from '../../Theme';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginValidation } from './login.validation';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { LoginDto } from '../../Types';

const useStyles = makeStyles((theme: Theme) => ({
  connectionButton: {
    margin: theme.spacing(1, 0) + ' !important',
  },
}));

interface LoginFormProps {
  submitForm: (data: LoginDto) => void;
  reset?: boolean;
  reauth?: boolean;
}

export const LoginForm = ({ submitForm, reset, reauth = false }: LoginFormProps) => {
  const classes = useStyles();
  const { register, control, handleSubmit, formState } = useForm<LoginDto>({
    resolver: yupResolver(loginValidation),
    mode: 'onChange',
    context: { reauth }
  });
  const { isValid, errors } = formState;

  return (
    <Fragment>
      {
        !reauth &&
          <TextField
            {...register('email')}
            error={errors.email ? true : false}
            required
            autoFocus
            type="email"
            color="primary"
            placeholder="Mon adresse e-mail"
            helperText={errors.email ? errors.email.message : ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
          ></TextField>
      }
      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        defaultValue=""
        render={({ field }) => (
          <PasswordTextField
            {...field}
            autoComplete='off'
            canReset={reset !== undefined ? reset: true}
            errorText={errors.password ? errors.password.message : ''}
            error={errors.password ? true : false}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSubmit(submitForm)(e);
            }}
          />
        )}
      />
      <FormControl className={classes.connectionButton}>
        <Button disabled={!isValid} color="primary" variant="contained" onClick={handleSubmit(submitForm)}>
          Se connecter
        </Button>
      </FormControl>
    </Fragment>
  );
};
