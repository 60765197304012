import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useUserProfile } from '../../../Hooks';
import { BeneficiaryProfileContext} from '../../../Providers';
import { GenderEnum } from '../../../Types';

export const BeneficiaryPersonalInformations = () => {
  
  const { user: profile } = useUserProfile(BeneficiaryProfileContext)

  return (
    <Grid container gap={4}>
      <Typography variant="h3">Informations personnelles</Typography>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Genre
          </Typography>
        </Grid>
        <Typography variant="body1" className='capital'>{profile?.beneficiary?.gender ? GenderEnum.label(profile?.beneficiary?.gender) : "-"}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Nom
          </Typography>
        </Grid>
        <Typography variant="body1" className='capital'>{profile?.beneficiary?.lastName?.toUpperCase() || '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Prénom
          </Typography>
        </Grid>
        <Typography variant="body1" className='capital'>{profile?.beneficiary?.firstName || '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Date de naissance
          </Typography>
        </Grid>
        <Typography variant="body1">{profile?.beneficiary?.birthday ? new Date(profile.beneficiary.birthday).toISOString().split('T')[0] : '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Adresse
          </Typography>
        </Grid>
        <Typography variant="body1">{profile?.beneficiary?.address || '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Code postal
          </Typography>
        </Grid>
        <Typography variant="body1">{profile?.beneficiary?.postcode || '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Ville
          </Typography>
        </Grid>
        <Typography variant="body1" className='capital'>{profile?.beneficiary?.city || '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Téléphone
          </Typography>
        </Grid>
        <Typography variant="body1">{profile?.beneficiary?.phoneNumber || '-'}</Typography>
      </Grid>
    </Grid>
  );
};
