import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { GenderEnum, GetBeneficiaryDto, GetConsultantDto, GetOrganismDto, PostOccupiedEnum } from '../../../../Types';
import { personalInformations } from './forms.validation';
import { useStateMachine } from 'little-state-machine';
import { FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText, MenuItem, Stack } from '@mui/material';
import APIAxios, { APIRoutes } from '../../../../API/api.axios';
import { useSnackbar } from 'notistack';
import { updateUserState } from '../../../../Stores';
import AsyncSelectWithSearchComponent, { SelectOption } from '../../../Molecules/organism-search.molecule';
import { useError } from '../../../../Hooks';

export const PersonalInformationsForm = () => {
  const {
    state: {
      user: { consultant, beneficiary, organism },
    },
    actions
  } = useStateMachine({ updateUserState });
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, formState, reset, control } = useForm<Partial<GetBeneficiaryDto & GetConsultantDto>>({
    resolver: yupResolver(personalInformations),
    mode: 'onChange',
  });
  const [isModifying, setModifying] = useState<boolean>(false);
  const [selectedOrganism, setSelectedOrganism] = useState<SelectOption | undefined>();
  const [data, setData] = useState<Partial<GetConsultantDto | GetBeneficiaryDto | GetOrganismDto>>(consultant ?? beneficiary ?? organism ?? {})

  const { addError } = useError();

  const getOrganisms = async (q?: string): Promise<SelectOption[]> => {
    return await APIAxios({...APIRoutes.GETOrganisms(1, q, 50)})
    .then((res) => {
      if (res.data) {
        return res.data.data.map((it: GetOrganismDto) => ({ label: it.name, value: it.id, data: it }));
      }
    })
  }

  const updateData = (name: string) => (value: any) => {
    setData({...data, [name]: value})
  };

  // const onSubmit = useCallback(
  //   async (data: Partial<GetBeneficiaryDto & GetConsultantDto>) => {
    const onSubmit = useCallback(
      async () => {
      try {
      if (consultant) {
        try {
          const consult = data;
          (consult as any).organismId = selectedOrganism?.data.id
          let res = await APIAxios({...APIRoutes.PATCHConsultants(), 
            data: consult})
          actions.updateUserState({consultant: res.data});
          setModifying(false);
        } catch (err: any) {
          err.response.data.message.forEach((it: string) => {
            switch (it) {
              case 'postcode must be a number conforming to the specified constraints':
                addError({message: 'Veuillez rentrer un code postal valide.'})
                break
              case 'phoneNumber must be a valid phone number':
                addError({message: 'Veuillez rentrer un numéro de téléphone valide.'})
                break
              default:
                addError({message: "Une erreur est survenue. Veuillez réessayer plus tard."})
                break
            }
          })
        }
      } else {
        try {
          let res = await APIAxios({...APIRoutes.PATCHBeneficiaries(), data: { ...data }})
          actions.updateUserState({beneficiary: res.data});
          setModifying(false);
        } catch (err: any) {
          err.response.data.message.forEach((it: string) => {
            switch (it) {
              case 'postcode must be a number conforming to the specified constraints':
                addError({message: 'Veuillez rentrer un code postal valide.'})
                break
              case 'phoneNumber must be a valid phone number':
                addError({message: 'Veuillez rentrer un numéro de téléphone valide.'})
                break
              default:
                addError({message: "Une erreur est survenue. Veuillez réessayer plus tard."})
                break
            }
          })
        }
      }
      } catch (err) {
      }
    },
    [consultant, data, selectedOrganism?.data.id, actions, addError],
  );

  const handleClick = () => {
    setModifying(!isModifying);
  }

  return (
    <Grid container gap={4}>
      <Grid item container justifyContent="space-between" alignItems="center" flexWrap="nowrap" gap={2}>
        <Typography variant="h3">Informations personnelles</Typography>
        <Button variant="text" color={isModifying ? 'secondary' : 'primary'} onClick={handleClick}>
          {isModifying ? 'Annuler' : 'Modifier'}
        </Button>
      </Grid>
      <Grid container gap={2}>
        {!!!consultant && (
          <div>
            <Typography variant="h3">Je suis</Typography>
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <FormControl
                    disabled={!isModifying} fullWidth component="fieldset" error={!!formState.errors.gender}>
                  <RadioGroup {...field} value={(data as any).gender} row onChange={(e) => updateData(e.target.value)}>
                    <FormControlLabel
                      value={GenderEnum.MALE}
                      control={<Radio />}
                      label={`Un ${GenderEnum.label(GenderEnum.MALE)}`}
                    />
                    <FormControlLabel
                      value={GenderEnum.FEMALE}
                      control={<Radio />}
                      label={`Une ${GenderEnum.label(GenderEnum.FEMALE)}`}
                    />
                  </RadioGroup>
                  {!!formState.errors.gender && <FormHelperText>{formState.errors.gender.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </div>
        )}
        {beneficiary && (
            <Controller
              name="birthday"
              control={control}
              // defaultValue={beneficiary.birthday && new Date(beneficiary.birthday).toISOString().split('T')[0]}
              render={({ field }) => (
                <TextField
                  {...field}
                  value={(data as any).birthday && new Date((data as any).birthday).toISOString().split('T')[0]}
                  disabled={!isModifying}
                  onChange={(e) => {
                    updateData('birthday')(e.target.value)}
                  }
                  error={!!formState.errors.birthday}
                  helperText={formState.errors.birthday ? formState.errors.birthday.message : ''}
                  fullWidth
                  type="date"
                  color="primary"
                  placeholder="Date de naissance"
                  label="Date de naissance"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            )}
          />
          )}
        <Controller
          name="lastName"
          control={control}
          // defaultValue={beneficiary?.lastName ?? consultant?.lastName ?? organism?.lastName}
          render={({ field }) => (
            <TextField
              {...field}
              value={data.lastName}
              onChange={(e) => updateData('lastName')(e.target.value)}
              disabled={!isModifying}
              fullWidth
              type="text"
              color="primary"
              placeholder="Nom"
              label="Nom"
            />
          )}
        />
        <Controller
          name="firstName"
          control={control}
          // defaultValue={beneficiary?.firstName ?? consultant?.firstName ?? organism?.firstName}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={!isModifying}
              value={data.firstName}
              onChange={(e) => {
                updateData('firstName')(e.target.value)
              }}
              fullWidth
              type="text"
              color="primary"
              placeholder="Prénom"
              label="Prénom"
            />
          )}
        />
          {!consultant && (
            <Controller
              name="address"
              control={control}
              // defaultValue={beneficiary?.address || undefined}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!isModifying}
                  value={data.address ?? ''}
                  onChange={(e) => updateData('address')(e.target.value)}
                  fullWidth
                  type="text"
                  color="primary"
                  placeholder="Adresse"
                  label="Adresse"
                />
              )}
            />
        )}
        {!consultant && (
          <Controller
            name="postcode"
            control={control}
            // defaultValue={beneficiary?.postcode || undefined}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!isModifying}
                value={data.postcode}
                onChange={(e) => updateData('postcode')(e.target.value)}
                fullWidth
                type="text"
                color="primary"
                placeholder="Code postal"
                label="Code postal"
              />
            )}
          />
        )}
        {!consultant && (
          <Controller
            name="city"
            control={control}
            // defaultValue={beneficiary?.city || undefined}
            render={({ field }) => (
              <TextField
                {...field}
                onChange={(e) => updateData('city')(e.target.value)}
                value={data.city}
                disabled={!isModifying}
                fullWidth
                type="text"
                color="primary"
                placeholder="Ville"
                label="Ville"
              />
            )}
          />
        )}
        <Controller
          name="phoneNumber"
          control={control}
          // defaultValue={beneficiary?.phoneNumber ?? consultant?.phoneNumber ?? undefined}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={!isModifying}
              value={data.phoneNumber}
              onChange={(e) => updateData('phoneNumber')(e.target.value)}
              fullWidth
              type="text"
              color="primary"
              placeholder="Téléphone"
              label="Téléphone"
            />
          )}
        />
        {consultant && (
          <>
            {/* <Controller
              name="organism.name"
              control={control}
              defaultValue={consultant?.organism?.name || undefined}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!isModifying}
                  fullWidth
                  type="text"
                  color="primary"
                  placeholder="Organisme / Entreprise"
                  label="Organisme / Entreprise"
                />
              )}
            /> */}
            <Stack width='100%'>
              <AsyncSelectWithSearchComponent
                getOptions={(v) => getOrganisms(v)}
                placeholder={'Organisme'}
                disabled={(!isModifying || !!consultant.organism)}
                value={selectedOrganism ?? (consultant.organism ? {data: consultant.organism, value: consultant.organism.id, label: consultant.organism.name } : undefined)}
                handleChange={(value) => {
                  setSelectedOrganism(value)
                }}
              />
            </Stack>
            <Controller
              name="postOccupied"
              control={control}
              // defaultValue={consultant?.postOccupied || undefined}
              shouldUnregister={true}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!isModifying}
                  select
                  value={(data as any).postOccupied ?? ''}
                  onChange={(e) => updateData('postOccupied')(e.target.value)}
                  fullWidth
                  type="text"
                  color="primary"
                  placeholder="Poste occupé"
                  label="Poste occupé"
                >
                  {PostOccupiedEnum.allPosts.map((it) => (
                    <MenuItem value={it} key={it}>
                      {PostOccupiedEnum.label(it)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </>
        )}
      </Grid>
      <Grid container>
        <Button 
          disabled={!isModifying} 
          variant="contained" 
          color="primary" 
          fullWidth 
          onClick={(data) => {
            onSubmit()
          }}>
          Enregistrer les modifications
        </Button>
      </Grid>
    </Grid>
  );
};
