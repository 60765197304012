import { useContext } from "react";
import { ActionsContext } from "../../Providers";

export const useActions = () => {
  // const { actions, count, filterByStatus, statusFilters, filterByTypeOrName, typeOrNameFilter, statusCounts, filterByDate, dateFilter } =
  //   useContext(ActionsContext);
  // return { actions, count, filterByStatus, statusFilters, filterByTypeOrName, typeOrNameFilter, statusCounts, filterByDate, dateFilter };

  const {
    actions,
    setPage,
    setBenefId,
    filterByStatus,
    statusFilters,
    filterByTypeOrName,
    typeOrNameFilter,
    filterByDate,
    dateFilter,
    beneficiaryId,
    handleUpdate,
    singleBenefActions,
  } = useContext(ActionsContext);
  return {
    actions,
    setPage,
    setBenefId,
    filterByStatus,
    statusFilters,
    filterByTypeOrName,
    typeOrNameFilter,
    filterByDate,
    dateFilter,
    beneficiaryId,
    handleUpdate,
    singleBenefActions,
  };
};
