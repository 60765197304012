import React, { Fragment, useState } from 'react';
import { credentialsValidation } from './regular.validation';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ArrowBack from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import { TAccountCreateData } from '../../../../Types';
import { PasswordTextField } from '../../../../Theme';
import { useStateMachine } from 'little-state-machine';
import { updateLoginState } from '../../../../Stores/Login';
import { IAccountCreateFormsCredentialsProps } from '../interfaces.form';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    margin: theme.spacing(1, 0) + ' !important',
  },
}));

export const CredentialsForm = ({ setCurrentStep, handleSignup }: IAccountCreateFormsCredentialsProps) => {
  const classes = useStyles();
  const {
    state: {
      loginSession: { createAccountValues },
    },
    actions,
  } = useStateMachine({ updateLoginState });
  const { register, formState, getValues, control, handleSubmit } = useForm<Partial<TAccountCreateData>>({
    resolver: yupResolver(credentialsValidation),
    mode: 'onChange',
  });
  const { isValid, errors } = formState;
  const [searchParams] = useSearchParams();
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const email = searchParams.get('email');

  const onBlurSaveMail = () => {
    actions.updateLoginState({
      createAccountValues: {
        email: getValues('email'),
      },
    });
  };

  const onSubmit = (data: Partial<TAccountCreateData>) => {
    actions.updateLoginState({
      createAccountValues: {
        email: getValues('email'),
      },
    });
    const formData: Partial<TAccountCreateData> = {
      ...data,
      ...createAccountValues,
      // isLocked: createAccountValues.isConsultant,
      // isInvited: createAccountValues.isConsultant,
    };
    handleSignup(formData);
  };

  return (
    <Fragment>
      <Grid container alignItems="center" gap={2}>
        <IconButton onClick={() => setCurrentStep(1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="subtitle1">Retour</Typography>
      </Grid>
      <TextField
        {...register('email')}
        defaultValue={createAccountValues.email || email || ''}
        error={errors.email ? true : false}
        required
        autoFocus
        type="email"
        color="primary"
        placeholder="email"
        onBlur={onBlurSaveMail}
        helperText={errors.email ? errors.email.message : ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailOutlined />
            </InputAdornment>
          ),
        }}
      ></TextField>
      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        defaultValue=""
        render={({ field }) => (
          <PasswordTextField
            {...field}
            errorText={errors.password ? errors.password.message : ''}
            error={errors.password ? true : false}
          />
        )}
      />
      <Controller
        name="passwordConfirmation"
        control={control}
        rules={{ required: true }}
        defaultValue=""
        render={({ field }) => (
          <PasswordTextField
            {...field}
            // onChange={(e) => setPasswordConfirmation(e.currentTarget.value)}
            errorText={errors.passwordConfirmation ? errors.passwordConfirmation.message : ''}
            error={errors.passwordConfirmation ? true : false}
          />
        )}
      />
      <Typography variant="subtitle2" component='div'>
        En cliquant sur le bouton ci-dessous, j&apos;accepte les <Link to='/terms/terms-of-service'>Conditions Générales d&apos;Utilisation</Link>
        &nbsp;ainsi que la <Link to='/terms/privacy-policy'>Politique de Confidentialité</Link> de la plateforme.
      </Typography>
      <Button
        className={classes.actionButton}
        disabled={!isValid}
        variant="contained"
        color="primary"
        onClick={handleSubmit(onSubmit)}
      >
        Créer mon compte
      </Button>
    </Fragment>
  );
};
