import React, { useState, useCallback, useMemo, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';
import { useFormContext } from 'react-hook-form';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { Grid, Theme } from '@mui/material';

type TApeSearch = {
  ape: string;
  nom: string;
  naf?: string;
}

type TApeSearchProps = {
  value: any;
  onChange: (...events: any[]) => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    padding: theme.spacing(1, 0),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.secondaryPalette.lightGreen
    }
  }
}))

export const ApeSearchField = ({value, onChange, disabled}: TApeSearchProps) => {
  const { formState } = useFormContext();
  const [searchText, setSearchText] = useState<string>('');
  const [searchValue, setSearchValue] = useState<TApeSearch | null>(null);
  const [options, setOptions] = useState<readonly TApeSearch[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [searchByCompany, setSearchByCompany] = useState<boolean>(false);
  const classes = useStyles();

  const searchCodes = useCallback(
    async (search: string) => {
      if (!search?.trim().length) return;
      const res = await Promise.all([
        axios.get(`${ process.env.REACT_APP_APE_BACKEND_URL }/v1/${searchByCompany ? 'entreprise' : 'metier' }/${ search }`)
      ]);
      const resOptions = [...res[0]?.data?.message ] || [];
      let newOptions: TApeSearch[] = [];
      if (searchValue) {
        newOptions = [searchValue];
      }
      if (resOptions?.length) {
        newOptions = [...newOptions, ...resOptions];
      }
      setLoading(false);
      setOptions(newOptions);
    }, [searchValue, searchByCompany]
  )

  const search = useMemo(() => debounce((search: string) => searchCodes(search), 400), [searchCodes]);

  useEffect(() => {
    if (searchText === '') {
      setOptions(searchValue ? [searchValue] : []);
      return undefined;
    }
    if (!searchValue && !disabled) {
      search(searchText);
    }
  }, [searchValue, searchText, search, searchByCompany, disabled]);

  return (
    <Autocomplete
      disabled={disabled}
      sx={{
        '&.MuiAutocomplete-root .MuiAutocomplete-endAdornment': {
          top: 'unset !important',
        },
      }}
      fullWidth
      options={options}
      isOptionEqualToValue={() => true}
      noOptionsText={
        isLoading
          ? 'Chargement'
          : searchText?.length
          ? 'Aucun résultat trouvé'
          : 'Entrez le nom de votre métier ou le nom de votre entreprise'
      }
      getOptionLabel={(option) => {
        if (value && !options?.length) {
          return value.toUpperCase();
        } else if (searchValue) {
          return searchValue.ape.toUpperCase();
        } else if (!option?.ape && !option?.nom) {
          return '';
        }
        return `${option.ape} ${option.nom} ${option.naf || ''}`;
      }}
      renderOption={(props, option) => (
        <li {...props} className={`${'capital margin-left-small'} ${classes.option}`} key={Math.random()}>{`${option.ape} ${option.nom} ${
          option.naf || ''
        }`}</li>
      )}
      onChange={(event, newValue: TApeSearch | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setSearchValue(newValue);
        onChange(newValue?.ape ? newValue.ape : '');
      }}
      value={value}
      renderInput={(params) => (
        <Grid>
          <p
            onClick={!disabled ? () => setSearchByCompany(!searchByCompany) : () => {}}
            style={{
              cursor: !disabled ? 'pointer' : '',
              color: '#4F4F4F',
              fontSize: '12px',
              fontWeight: 'bold',
              marginBottom: '10px',
              marginLeft: '10px',
              marginTop: '-10px',
              opacity: disabled ? 0.5 : 1,
              textDecoration: 'underline',
            }}
          >
            Rechercher par {searchByCompany ? 'entreprise' : 'métier'}
          </p>

          <TextField
            {...params}
            fullWidth
            error={!!formState.errors.ape}
            helperText={formState.errors.ape ? formState.errors.ape.message : ''}
            type="text"
            color="primary"
            placeholder="Code APE"
            label="Code APE"
          />
        </Grid>
      )}
      onInputChange={(e, newValue) => {
        if (newValue !== value) {
          setLoading(!!newValue?.length);
          setSearchText(newValue);
        }
      }}
    />
  );
}
