import React, { Fragment } from 'react';
import { invitedCredentialsValidation } from './invited.validation';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ArrowBack from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import { TAccountCreateData } from '../../../../Types';
import { PasswordTextField } from '../../../../Theme';
import { useStateMachine } from 'little-state-machine';
import { updateLoginState } from '../../../../Stores/Login';
import { IAccountCreateFormsCredentialsProps } from '../interfaces.form';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PersonOutlined from '@mui/icons-material/PersonOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    margin: theme.spacing(1, 0) + ' !important',
  },
}));

export const InvitedCredentialsForm = ({ setCurrentStep, handleSignup }: IAccountCreateFormsCredentialsProps) => {
  const classes = useStyles();
  const {
    state: {
      loginSession: { createAccountValues },
    },
    actions,
  } = useStateMachine({ updateLoginState });
  const { register, formState, getValues, control, handleSubmit } = useForm<Partial<TAccountCreateData>>({
    resolver: yupResolver(invitedCredentialsValidation),
    mode: 'onChange',
  });
  const { isValid, errors } = formState;

  const onBlurSaveField = (fieldName: string) => {
    actions.updateLoginState({
      createAccountValues: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [fieldName]: getValues(fieldName as any),
      },
    });
  };

  const onSubmit = (data: Partial<TAccountCreateData>) => {
    const formData: Partial<TAccountCreateData> = {
      ...data,
      ...createAccountValues,
    };
    handleSignup(formData);
  };

  return (
    <Fragment>
      <Grid container alignItems="center" gap={2}>
        <IconButton onClick={() => setCurrentStep(1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="subtitle1">Retour</Typography>
      </Grid>
      <TextField
        {...register('email')}
        defaultValue={createAccountValues.email}
        error={errors.email ? true : false}
        required
        type="email"
        color="primary"
        placeholder="email"
        helperText={errors.email ? errors.email.message : ''}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <EmailOutlined />
            </InputAdornment>
          ),
        }}
      ></TextField>
      {/* TODO gerer les resets du state si l'utilisateur change d'onglet etc... */}
      <TextField
        {...register('firstName')}
        defaultValue={createAccountValues.firstName || ''}
        error={errors.firstName ? true : false}
        required
        autoFocus
        type="text"
        color="primary"
        placeholder="Prénom"
        onBlur={() => onBlurSaveField('firstName')}
        helperText={errors.firstName ? errors.firstName.message : ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutlined />
            </InputAdornment>
          ),
        }}
      ></TextField>
      <TextField
        {...register('lastName')}
        defaultValue={createAccountValues.lastName || ''}
        error={errors.lastName ? true : false}
        required
        // autoFocus
        type="text"
        color="primary"
        placeholder="Nom de famille"
        onBlur={() => onBlurSaveField('lastname')}
        helperText={errors.lastName ? errors.lastName.message : ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutlined />
            </InputAdornment>
          ),
        }}
      ></TextField>
      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        defaultValue=""
        render={({ field }) => (
          <PasswordTextField
            {...field}
            errorText={errors.password ? errors.password.message : ''}
            error={errors.password ? true : false}
          />
        )}
      />
      <Controller
        name="passwordConfirmation"
        control={control}
        rules={{ required: true }}
        defaultValue=""
        render={({ field }) => (
          <PasswordTextField
            {...field}
            errorText={errors.passwordConfirmation ? errors.passwordConfirmation.message : ''}
            error={errors.passwordConfirmation ? true : false}
          />
        )}
      />
      <Button
        className={classes.actionButton}
        disabled={!isValid}
        variant="contained"
        color="primary"
        onClick={handleSubmit(onSubmit)}
      >
        Créer mon compte
      </Button>
    </Fragment>
  );
};
