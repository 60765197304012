import { styled } from '@mui/material/styles';
import MuiListItemButton, { ListItemButtonProps as MuiListItemButtonProps } from '@mui/material/ListItemButton';

interface ListItemProps extends MuiListItemButtonProps {
  open?: boolean;
}

export const ListItemButton = styled(MuiListItemButton, {
  shouldForwardProp: (prop) => prop !== 'open',
})<ListItemProps>(({ theme, open }) => ({
  minHeight: theme.customShapes.fixedSize48px,
  ...(!open && {
    justifyContent: 'center',
  }),
  ...(open && {
    justifyContent: 'space-between',
  }),
}));
