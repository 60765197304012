import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import { ProfileAvatar } from '../../../Atoms';
import { useStateMachine } from 'little-state-machine';
import { GetInvitationDto } from '../../../../Types';
import { usePopup } from '../../../../Hooks';
import APIAxios, { APIRoutes } from '../../../../API/api.axios';

export const UserInvitation = () => {
  const {
    state: {
      user: {
        consultant
      }
    }
  } = useStateMachine();
  const { closePopup } = usePopup('InvitationPopup');
  const [currentInvitation, setCurrentInvitation] = useState<GetInvitationDto | undefined>(undefined);
  const [invitations, setInvitations] = useState<GetInvitationDto[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleInvitation = useCallback(async (accept: boolean) => {
      try {
        await APIAxios({...APIRoutes.POSTInvitation(currentInvitation?.id || '', accept ? "accept" : "decline")})
        setInvitations(invitations.filter((it) => (it.id !== currentInvitation?.id )));
      } catch (err) {

      }
  }, [currentInvitation?.id, invitations]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (invitations?.length) {
      setCurrentInvitation(invitations[0]);
    } else {
      closePopup();
      setCurrentInvitation(undefined);
    }
  }, [invitations, closePopup, isLoading]);

  const getInvitations = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await APIAxios({...APIRoutes.GETAuthorizations()});
      if (res.data) {
        setInvitations(res.data)
        setIsLoading(false);
      }
    } catch (err) {
    }
  }, []);

  useEffect(() => {
    getInvitations();
  }, [getInvitations])

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      container
      padding={isSm ? theme.spacing(2) : theme.spacing(6)}
      justifyContent="center"
      textAlign="center"
      gap={5}
    >
      {/* content */}
      <Grid item container justifyContent="center">
        <ProfileAvatar size={100} />
      </Grid>
      <Grid container direction="column" alignContent="center" justifyContent="center" gap={2}>
        <Typography variant={isSm ? 'h4' : 'h1'} component="div">
          {consultant ? (
            <div>
              Le bénéficiaire <span className="capital">{currentInvitation?.invitant}</span> souhaite partager son parcours
              avec vous.
            </div>
          ) : (
            <div>
              Le consultant <span className="capital">{currentInvitation?.invitant}</span> souhaite accéder à votre compte
              pour suivre votre parcours.
            </div>
          )}
        </Typography>
        {invitations[0]?.message && (
          <Typography
            variant="subtitle2"
            component="div"
            sx={{
              margin: (theme) => theme.spacing(4, 'auto'),
              width: '80%',
              whiteSpace: 'pre-wrap',
              textAlign: 'start',
              padding: (theme) => theme.spacing(3),
              background: (theme) => theme.palette.grey[100],
              borderRadius: '8px',
            }}
          >
            {invitations[0].message}
          </Typography>
        )}
        <Typography variant="subtitle1">
          {consultant &&
            `Vous pourrez avoir accès à son parcours, ajouter des actions ou voir celles qui ont déjà
          été réalisées. Vous pourrez aussi voir sa fiche bénéficiaire.`}
          {!consultant &&
            `Les consultants autorisés peuvent ajouter des actions à votre parcours, voir les actions déjà réalisées,
          en cours et à réaliser. Ils ont aussi accès à votre fiche informations pour vous accompagner au mieux !`}
        </Typography>
      </Grid>
      {/* footer */}
      <Grid item container xs={12} justifyContent="center" marginTop={4} gap={3}>
        <Button variant="outlined" color="secondary" size="large" onClick={() => handleInvitation(false)}>
          Refuser
        </Button>
        <Button variant="contained" onClick={() => handleInvitation(true)} size="large">
          Autoriser
        </Button>
      </Grid>
    </Grid>
  );
}
