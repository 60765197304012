import React, { useCallback, useEffect, useState } from "react";
import {
  PaperContainer,
  ResponsiveContainer,
  DataTableHeader,
  UserInvitationFormPopup,
  ProfileAvatar,
} from "../../Components";
import { CustomPagination, Popup } from "../../Theme";
import { BeneficiariesHeader, ClickableDatatableCell } from "./Components";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import {
  followedBeneficiariesDataTableService,
  IMyBeneficiariesRow,
} from "../../Services";
import { DatatableSearchProvider } from "../../Providers";
import { useDatatableSearch, usePopup } from "../../Hooks";
import { Outlet } from "react-router-dom";
import {
  Badge,
  Button,
  Grid,
  Stack,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  GetBeneficiaryListDto,
  GetOrganismAdminDto,
  StatusBoe,
  TypeWorkArrest,
} from "../../Types";
import APIAxios, { APIRoutes } from "../../API/api.axios";
import { useSnackbar } from "notistack";
import { useStateMachine } from "little-state-machine";
import { NewBenefDataTable } from "./new-beneficiary.table-component";
import { NewHeader } from "../../Components/Molecules/new-title.component";
import BeneficiaryBlack from "../../Assets/person_black.svg";
import Search from "../../Assets/search.svg";
import { SelectOption } from "../../Components/Molecules/organism-search.molecule";
import { FilterPopup } from "../../Components/Molecules/filter-popup.molecule";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Nom / prénom",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    headerAlign: "left",
    align: "left",
    renderHeader: () => <DataTableHeader headerName="Nom / Prénom" />,
    renderCell: (params: GridCellParams) => (
      <Grid container gap={2} flexWrap="nowrap" alignItems="center">
        <ProfileAvatar size={37} />
        <Typography
          variant="body1"
          className="capital"
          whiteSpace="normal"
          textAlign="start"
        >
          {params.value}
        </Typography>
      </Grid>
    ),
  },
  {
    field: "age",
    headerName: "Âge",
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Âge" />,
    renderCell: (params: GridCellParams) => (
      <Typography variant="body1">{params.value || 0}</Typography>
    ),
  },
  {
    field: "statusBoe",
    headerName: "Situation",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 250,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Situation" />,
    renderCell: (params: GridCellParams) => {
      const statusText =
        (params.row.isBOE ? "BOE" : "Non BOE") +
        (params.row.typeWorkArrest ? (" / " + TypeWorkArrest.label(params.row.typeWorkArrest)) : "")
      return (
        <Typography variant="body1" className="capital" lineHeight={1.25}>
          {statusText.length > 70
            ? statusText.slice(0, 70) + "..."
            : statusText}
        </Typography>
      );
    },
  },
  {
    field: "dateWorkArrest",
    headerName: "Date d'arrêt de travail",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    align: "center",
    headerAlign: "center",
    renderHeader: () => (
      <DataTableHeader headerName="Date d'arrêt de travail" />
    ),
    renderCell: (params: GridCellParams) => (
      <Typography variant="body1">
        {params.value && new Date(params.value).toISOString().split("T")[0]}
      </Typography>
    ),
  },
  {
    field: "isSuspended",
    headerName: "Statut",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 150,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Statut" />,
    renderCell: (params: GridCellParams) => (
      <Typography
        fontSize="10px"
        fontWeight="500"
        style={{
          backgroundColor: (params.value)
            ? "rgb(255, 238, 238)"
            : "rgb(240, 255, 238)",
          color: (params.value) ? "rgb(192, 74, 74)" : "rgb(128, 193, 131)",
          borderRadius: "6px",
          padding: "6px",
        }}
      >
        {params.value ? "Clôturé" : "Actif"}
      </Typography>
    ),
  },
  {
    field: "info",
    headerName: "Fiche info",
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Fiche info" />,
    renderCell: (params: GridRenderCellParams) => (
      <Badge
        variant="dot"
        color="secondary"
        badgeContent={params.row.isHelpNeeded ? 1 : 0}
        overlap="circular"
      >
        <ClickableDatatableCell action="informations" {...params} />
      </Badge>
    )
  },
  {
    field: "journey",
    headerName: "Parcours",
    sortable: false,
    disableColumnMenu: true,
    align: "center",
    headerAlign: "center",
    renderHeader: () => <DataTableHeader headerName="Parcours" />,
    renderCell: (params: GridRenderCellParams) => (
      <ClickableDatatableCell action="journey" {...params} />
    ),
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  disabledRow: {
    color: theme.palette.grey[500],
  },
}));

const DataTable = () => {
  const classes = useStyles();

  const [data, setData] = useState<IMyBeneficiariesRow[] | undefined>(
    undefined
  );
  const { search } = useDatatableSearch();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);

  const [beneficiaries, setBeneficiaries] = useState<
    GetBeneficiaryListDto | undefined
  >(undefined);

  const getBeneficiaries = useCallback(async () => {
    try {
      const res = await APIAxios({
        ...APIRoutes.GETBeneficiaries(page, search),
      });
      if (res.data as GetBeneficiaryListDto) {
        setBeneficiaries(res.data);
        
        const data = followedBeneficiariesDataTableService.buildRows(
          res.data.data
        );
        
        setData(data);
      } else {
        enqueueSnackbar(
          "Une erreur est survenue. Veuillez réessayer plus tard."
        );
      }
    } catch (err) {
      enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
  }, [page, search, enqueueSnackbar]);

  useEffect(() => {
    getBeneficiaries();
  }, [getBeneficiaries]);

  if (data === undefined) return <div>Chargement ...</div>;

  if (!data.length)
    return (
      <>
        <Typography variant="h3">
          Il semble que vous n&apos;aillez pas de bénéficiaire.
        </Typography>
        <Typography variant="subtitle1">
          Vous pouvez en ajouter depuis votre dashboard ou en cliquant
          directement sur le bouton ci-dessus &apos;Inviter un
          bénéficiaire&apos;
        </Typography>
      </>
    );

  return (
    <DataGrid
      getRowClassName={(params) => {
        return params.row.isDeleted ? classes.disabledRow : "";
      }}
      rows={data}
      columns={columns}
      onPageChange={setPage}
      pageSize={beneficiaries?.meta.take}
      autoHeight
      disableSelectionOnClick
      density="comfortable"
      sx={{
        "&.MuiDataGrid-root .MuiDataGrid-columnSeparator": {
          visibility: "hidden",
        },
        "&.MuiDataGrid-root": {
          border: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
          borderBottom: "1px solid #e9e9e9",
        },
        "&.MuiDataGrid-root .MuiDataGrid-footerContainer": {
          borderTop: "1px solid #e9e9e9",
        },
        "&.MuiDataGrid-root *:focus-within": {
          outline: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
          overflow: "hidden",
          whiteSpace: "normal",
          textAlign: "center",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell": {
          overflow: "hidden",
          whiteSpace: "normal",
          textAlign: "center",
          borderBottom: "1px solid #e9e9e9",
        },
      }}
      rowCount={beneficiaries?.meta.itemCount}
      components={{
        Pagination: CustomPagination,
      }}
    />
  );
};

interface HeaderProps {
  name?: string;
  setFilterPopUpOpen: () => void;
}

const NewBenefHeader = ({ name, setFilterPopUpOpen }: HeaderProps) => {
  const { search, updateSearch } = useDatatableSearch();

  return (
    <>
      <NewHeader title="Bénéficiaires" image={BeneficiaryBlack} />
      <Grid container direction="row" gap={2}>
        <Grid item xs>
          <TextField
            fullWidth
            value={search}
            onChange={(e) => updateSearch(e.target.value)}
            placeholder="Rechercher un(e) consultant(e)"
            InputProps={{
              startAdornment: <img src={Search} alt="" />,
            }}
          />
        </Grid>
        <Grid item>
          <Button
            sx={{ width: "300px", backgroundColor: "rgba(90, 173, 178, 0.1)" }}
            onClick={() => {
              setFilterPopUpOpen();
            }}
          >
            {name || "Filtrer par organisme"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export const MyBeneficiaries = () => {
  const { popupOpen, closePopup } = usePopup("CreateInvitationPopup");
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    state: {
      user: { admin },
    },
  } = useStateMachine();
  const { search, updateSearch } = useDatatableSearch();

  const [selectedOrganism, setSelectedOrganism] = useState<
    SelectOption | undefined
  >();
  const [filterPopupOpen, setFilterPopUpOpen] = useState(false);

  const getOrganisms = async (q?: string): Promise<SelectOption[]> => {
    return await APIAxios({ ...APIRoutes.GETOrganisms(1, q, 50) }).then(
      (res) => {
        if (res.data) {
          return res.data.data.map((it: GetOrganismAdminDto) => ({
            label: it.name,
            value: it.id,
            data: it,
          }));
        }
      }
    );
  };

  return (
    <>
      <Popup
        open={popupOpen()}
        maxWidth="md"
        onClose={closePopup}
        transitionDuration={0}
        fullScreen={fullscreen}
      >
        <UserInvitationFormPopup />
      </Popup>
      {filterPopupOpen && (
        <FilterPopup
          title="Chercher un organisme"
          placeholder="Nom de l'organisme"
          selectedOption={selectedOrganism}
          handleSelect={(value) => {
            setSelectedOrganism(value);
            setFilterPopUpOpen(false);
          }}
          handleClose={() => setFilterPopUpOpen(false)}
          getOptions={getOrganisms}
        />
      )}
      <ResponsiveContainer>
        <DatatableSearchProvider>
          {admin ? (
            <Stack width="100%" height="100%" gap={3}>
              <NewBenefHeader
                name={selectedOrganism?.data.name}
                setFilterPopUpOpen={() => setFilterPopUpOpen(true)}
              />
              <NewBenefDataTable organismId={selectedOrganism?.data.id} />
            </Stack>
          ) : (
            <>
              <BeneficiariesHeader />
              <PaperContainer>
                <DataTable />
              </PaperContainer>
            </>
          )}
        </DatatableSearchProvider>
        <Outlet></Outlet>
      </ResponsiveContainer>
    </>
  );
};
