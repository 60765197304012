import * as Yup from 'yup';
import { CreateOrganismDto } from '../../../Types';
import { transformError } from '../../../Utils/yup.utils';

export const organismValidation = (organism: Partial<CreateOrganismDto>) => {
  try {
    const schema =  Yup.object().shape({
      lastName: Yup.string().required('Ce champ est requis').default(''),
      firstName: Yup.string().required('Ce champ est requis').default(''),
      name: Yup.string().required('Ce champ est requis').default(''),
      email: Yup.string().required('Ce champ est requis').default('')
    })
    schema.validateSync(organism, { abortEarly: false });
    return {};
  } catch (err) {
    return transformError(err as any);
  }
}