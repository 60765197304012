import { styled } from '@mui/material/styles';

type DrawerHeaderProps = {
  open?: boolean;
};

export const DrawerHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerHeaderProps>(({ theme, open }) => ({
  display: 'flex',
  height: theme.customShapes.topbarHeight,
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  ...(open && {
    justifyContent: 'flex-end',
  }),
  ...(!open && {
    justifyContent: 'center',
  }),
}));
