/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { FirebaseError } from 'firebase/app';
import React, { useState, useCallback, ReactNode } from 'react';

interface IErrorContext {
  error: FirebaseError | null;
  addError: (error: FirebaseError | any | null) => void;
  removeError: () => void;
}

export const ErrorContext = React.createContext<IErrorContext>({
  error: null,
  addError: () => {},
  removeError: () => {},
});

interface IErrorProviderProps {
  children: ReactNode;
}

export const ErrorProvider = ({ children }: IErrorProviderProps) => {
  const [error, setError] = useState<FirebaseError | null>(null);

  const removeError = () => setError(null);

  const addError = (error: FirebaseError | any) => setError(error);

  const contextValue = {
    error,
    addError: useCallback((error) => addError(error), []),
    removeError: useCallback(() => removeError(), []),
  };

  return <ErrorContext.Provider value={contextValue}>{children}</ErrorContext.Provider>;
};
