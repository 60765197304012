import React, { useEffect, useState } from'react';
import { Button, IconButton, Stack } from '@mui/material';
import { Popup } from '../../Theme';
import { NewHeader } from './new-title.component';
import AsyncSelectWithSearchComponent, { SelectOption } from './organism-search.molecule';
import Close from '../../Assets/cross_close.svg'

interface FilterPopupProps {
  title: string;
  handleSelect: (value?: any) => void;
  handleClose: () => void;
  getOptions: (search?: string) => Promise<SelectOption[]>;
  selectedOption?: SelectOption;
  placeholder: string;
}

export const FilterPopup = (props: FilterPopupProps) => {

  const { title, placeholder, handleSelect, handleClose, getOptions, selectedOption } = props;

  const [selectedValue, setSelectedValue] = useState<SelectOption | undefined>();

  useEffect(() => {
    setSelectedValue(selectedOption);
}, [selectedOption]);

  return (
    <Popup open={true} maxWidth='xs' fullWidth>
      <Stack direction='column' padding={4} gap={3}>
        <Stack direction='row' justifyContent='space-between'>
          <NewHeader title={title} />
          <IconButton onClick={handleClose} sx={{padding: '0px', paddingBottom: '16px'}}><img alt='' src={Close} /></IconButton>
        </Stack>
        <Stack direction='column' paddingBottom={8}>
          <AsyncSelectWithSearchComponent
            placeholder={placeholder}
            value={selectedValue}
            getOptions={getOptions}
            handleChange={setSelectedValue}
            />
        </Stack>
        <Stack justifyContent='center' alignItems='center'>
          <Button variant='contained' onClick={() => { handleSelect(selectedValue) }} disabled={selectedValue === selectedOption}>Valider</Button>
        </Stack>
      </Stack>
    </Popup>
  );
};