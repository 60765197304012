import React, { useCallback, useState } from 'react';
import { NewHeader } from '../../Components/Molecules/new-title.component';
import { TAccountCreateData, TUserRole } from '../../Types';
import Account from '../../Assets/account_black.svg';
import { Button, IconButton, Stack, TextField } from '@mui/material';
import { SimpleCard } from '../../Components/Atoms/cimple-card.atom';
import Password from '../../Assets/password.svg';
import Email from '../../Assets/mail.svg';
import { useStateMachine } from 'little-state-machine';
import APIAxios, { APIRoutes } from '../../API/api.axios';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { PasswordTextField } from '../../Theme';
import { EditOutlined } from '@mui/icons-material';

interface NewSettingsProps {
  role: TUserRole;
}

const changePasswordValidation = Yup.object().shape({
  email: Yup.string().required('Ce champ est requis').email("Le format de l'e-mail est incorrecte"),
  password: Yup.string().required('Ce champ est requis'),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Les mots de passes doivent être identiques'),
});

export const NewSettings = ({role}: NewSettingsProps) => {
  const { state: { user: { organism, admin }}} = useStateMachine();
  const { enqueueSnackbar } = useSnackbar();

  const [isEditing, setIsEditing] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordConfirm , setPasswordConfirm] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm<Partial<TAccountCreateData>>({
    resolver: yupResolver(changePasswordValidation),
    mode: 'onChange',
  });

  const handleChangePassword = async (data: Partial<TAccountCreateData>) => {
    try {
      const res = await APIAxios({...APIRoutes.POSTPassword(), data: { password: data.password}});
      if (res.data) {
        setIsEditing(false);
        enqueueSnackbar('Le mot de passe a bien été modifié', { variant: "success" })
      }
    } catch (error) {
      enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" });
      setIsEditing(true);
    }
  };

  return (
    <Stack width='100%'>
        <NewHeader image={Account} title='Compte' />
      <Stack maxWidth='400px' gap={1}>
        <SimpleCard title='Informations du compte' 
          action={
          <IconButton onClick={() => setIsEditing(!isEditing)} color='primary' sx={{height: '15px', marginBottom: '10px'}}>
            <EditOutlined />
          </IconButton>}
          >
          <Stack direction='column' gap={1}>
            <Controller
              name="email"
              control={control}
              defaultValue={admin?.email ??  organism?.email}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled
                  InputProps={{
                    startAdornment: <img src={Email} alt='' />
                  }}
                  />
              )}
            />
            <PasswordTextField
              {...register('password')}
              disabled={!isEditing}
              value={isEditing ? password : '************'}
              onChange={(e) => setPassword(e.target.value)}
              errorText={errors.password ? errors.password.message : ''}
              error={errors.password ? true : false}
            />
            {isEditing && (
              <Controller
                name='passwordConfirmation'
                control={control}
                rules={{required: true}}
                defaultValue=""
                render={({ field }) => (
                  <PasswordTextField
                    {...field}
                    errorText={errors.passwordConfirmation ? errors.passwordConfirmation.message : ''}
                    error={errors.passwordConfirmation ? true : false}
                  />
                )}
               />
            )}
            </Stack>
            <Button disabled={!isEditing} onClick={handleSubmit(handleChangePassword)} variant='light' color='secondary'>Modifier le mot de passe</Button>
        </SimpleCard>
      </Stack>
    </Stack>
  )
}