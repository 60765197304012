import { Grid, IconButton, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxHeight: '100vh',
    height: '100%'
  },
  content: {
    padding: theme.spacing(3, 4),
    height: '90%',
    overflowY: 'scroll'
  },
  fixedPadding: {
    padding: '1rem',
  },
  list: {
    '& li:not(:first-child)': {
      margin: '2rem 0',
    },
    '& li *': {
      margin: '.5rem 0',
    },
  },
  listHyphen: {
    '& li': {
      listStyleType: '"- "',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  fixedMarginBottom: {
    marginBottom: '2rem',
  },
}));

export const TermsOfService = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.container}>
      <Grid container flexWrap='nowrap' alignItems='center'>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Grid container margin='0 auto' paddingY="20px" alignItems='center' direction='column'>
          <Typography variant="h4">Conditions générales d&apos;utilisation SAM.I</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.content} gap={3}>
      <Typography variant="body1" component="div">
              Bienvenue sur la plateforme SAM.I, un service créé et développé TECH FOR WORK, société par actions 
              simplifiée ayant son siège 2 rue Danton, 77330 Ozoir-la-Ferrière et enregistrée sous le numéro SIREN 
              899 652 481 au RCS de Melun.
              <br />
              <br />
              Ces présentes conditions générales d’utilisation (ci-après <strong>« CGU »</strong>) ont vocation à régir votre utilisation 
              de la plateforme SAM.I (ci-après <strong>« SAM.I »</strong> ou la <strong>« Plateforme »</strong> disponible sur le site 
              internet suivant : https://sami.tech4work.fr/select-role
              <br />
              <br />
              Toute utilisation de SAM.I et de ses Services implique l’adhésion sans réserve aux présentes CGU.
              <br />
              <br />
              <br />
              <strong>Article 1. Définitions</strong>
              <br />
              <br />
              <strong>Action : </strong>Désigne tout action d’un Consultant sur la fiche de suivi d’un 
              Bénéficiaire permettant d’avoir des informations sur son parcours.
              <br/>
              <strong>Dispositif : </strong>Désigne la nature et le type de droit commun ou spécifique 
              que le Consultant renseignera sur la fiche du Bénéficiaire.
              <br/>
              <strong>Bénéficiaire : </strong>Désigne tout Utilisateur inscrit sur SAM.I et disposant 
              d’une fiche de suivi contenant ses informations personnelles et les différentes actions de son parcours. 
              <br/>
              <strong>Compte : </strong>Désigne le compte personnel d’un Utilisateur créé à partir des informations personnelles 
              qu’il consent de transmettre à la Plateforme et auquel il peut accéder grâce à ses identifiants personnels.
              <br/>
              <strong>Consultant : </strong>Désigne tout professionnel, inscrit sur la Plateforme, autorisé par un Bénéficiaire à 
              suivre son parcours et à ajouter des actions sur sa fiche de suivi.
              <br/>
              <strong>Donnée à caractère personnel : </strong>Désigne toute information concernant une personne physique 
              identifiée ou identifiable telle que définie par le Règlement Général sur la Protection des Données (RGPD).
              <br/>
              <strong>Fiche de suivi : </strong>Désigne l’ensemble des informations personnelles et de parcours attachés au 
              compte d’un Bénéficiaire. La fiche de suivi permet au Consultant de connaître la situation du Bénéficiaire à une date donnée.
              <br/>
              <strong>Services : </strong>Désigne la ou les fonctionnalités mises à disposition des Utilisateurs.
              <br/>
              <strong>Utilisateur : </strong>Désigne toute personne parcourant la Plateforme et/ou créant un compte personnel sur la Plateforme.
              <br/>
              <br/>
              <strong>Article 2. Acceptation des présentes CGU</strong>
              <br/>
              <br/>
              En créant son compte personnel sur la Plateforme SAM.I, en cochant la case « Je certifie avoir lu et accepté sans réserve les CGU de 
              [la Plateforme] », l’Utilisateur déclare et garantit avoir lu et accepté les présentes CGU et s’engage à les respecter. 
              <br/>
              <br/>
              <br/>
              Les présentes CGU peuvent être modifiées et remplacées à tout moment. La date de la dernière modification figure à la fin des présentes CGU. 
              <br/>
              <br/>
              Les CGU sont téléchargeables gratuitement par les Utilisateurs des Services. Elles sont également référencées en bas de chaque page 
              de la Plateforme au moyen d’un lien hypertexte et peuvent ainsi être consultées à tout moment.
              <br/>
              Les Conditions Générales d'Utilisation s'appliquent entre les Utilisateurs et TECH FOR WORK à l'exclusion de tout autre contrat 
              s'agissant de l'accès et de l'utilisation des Services.
              <br/>
              <br/>
              <br/>
              <strong>Article 3. Présentation de la Plateforme et des Services</strong>
              <br/>
              <br/>
              <strong>3.1. Informations relatives à la Platefome</strong>
              <br/>
              <br/>
              <br/>
              En vertu de l’article 6 de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux Utilisateurs 
              l’identité des différents intervenants dans le cadre de la réalisation de SAM.I et de son suivi :
              <br/>
              Propriétaire : TECH FOR WORK
              <br/>
              Responsable de publication : TECH FOR WORK
              <br/>
              Webmaster : TECH FOR WORK
              <br/>
              Hébergeur : OVH.
              <br/>
              <br/>
              <strong>3.2. Conditions d’accès et caractéristiques techniques</strong>
              <br/>
              <br/>
              L’utilisation de SAM.I est réservée aux personnes physiques âgées de 18 ans ou plus disposant de la capacité juridique pleine 
              et entière. Toute inscription sur SAM.I par une personne mineure est strictement interdite. En accédant, utilisant ou vous 
              inscrivant sur la Plateforme, vous déclarez et garantissez avoir 18 ans ou plus et être juridiquement capable.
              <br/>
              Pour pouvoir utiliser les Services, l’Utilisateur doit avoir un accès Internet. Les coûts de l’accès à Internet sont supportés 
              par l’Utilisateur. Il lui appartient de mettre à jour son environnement matériel et logiciel pour accéder et utiliser les Services.
              <br/>
              <br/>
              <strong>3.3. Description de la Plateforme et des Services </strong>
              <br/>
              <br/>
              SAM.I est un service collaboratif de mise en relation entre un Bénéficiaire et ses Consultants afin de simplifier le retour dans l’emploi.
              <br/>
              <br/>
              À tout moment, de nouveaux Services, ressources et fonctionnalités peuvent être ajoutés sur la Plateforme ou de nouvelles versions 
              de la Plateforme publiées. Les nouveaux Services seront régis par les présentes CGU ainsi que toute condition complémentaire que 
              la Plateforme est susceptible de publier au regard de ces Services.
              <br/>
              <br/>
              La création d’un Compte, permet à Utilisateur, en fonction de son statut d’accéder à :
              <ul style={{listStyle: 'none'}}>
                <li>- un parcours Bénéficiaire, ou,</li>
                <li>- un parcours Consultant.</li>
              </ul>
              <strong>Article 4. Parcours Bénéficiaire </strong>
              <br/>
              <br/>
              Pour la création d’un Compte Bénéficiaire, l’Utilisateur doit fournir au moment de son inscription :
              <br/>
              <ul style={{listStyle: 'none'}}>
                <li>- son adresse mail,</li>
                <li>- son mot de passe,</li>
                <li>- son nom et prénom,</li>
                <li>- sa date de naissance,</li>
                <li>- son adresse,</li>
                <li>- son numéro de téléphone,</li>
                <li>- son statut,</li>
                <li>- son type de contrat de travail et le poste occupé,</li>
                <li>- son code APE,</li>
                <li>- son domaine et son niveau de formation,</li>
                <li>- sa langue et son niveau de langue,</li>
                <li>- s’il est bénéficiaire de l’obligation emploi,</li>
                <li>- s’il est en arrêt de travail ; le cas échéant depuis combien de temps et quel type d’arrêt de travail.</li>
              </ul>
              <br/>
              <br/>
              <strong>4.2 Fiche de suivi du Bénéficiaire</strong>
              <br/>
              Immédiatement après la création de son compte, le Bénéficiaire dispose d’une fiche de suivi contenant les informations 
              sur sa situation professionnelle qu’il a transmis.
              <br/>
              <br/>
              Cette fiche de suivi ne pourra être visualisée par un Consultant, qu’après autorisation exprès du Bénéficiaire. 
              Aucun autre Utilisateur ne peut visualiser ces informations personnelles.
              <br/>
              <br/>
              La fiche de suivi du Bénéficiaire permet à des Consultants indépendants ou salariés d’entreprises et organismes 
              sociaux d’avoir un accès centralisé aux informations relatives à au statut administratif et à au parcours professionnel 
              du Bénéficiaire. Les Consultants n'y ont accès que sur invitation ou autorisation du Bénéficiaire. 
              <br/>
              <br/>
              Le Bénéficiaire peut envoyer une invitation à un Consultant afin (i) de lui donner accès à sa Fiche de suivi ou (ii) 
              de lui demander de s’inscrire sur la Plateforme si ce n’est pas déjà le cas.
              <br/>
              <br/>
              <strong>4.3 Suppression de son compte Bénéficiaire</strong>
              Le Bénéficiaire peut, à tout moment, supprimer son compte en cliquant sur la rubrique « Supprimer mon compte » de son profil.
              <br/>
              <br/>
              <strong>Article 5. Parcours Consultant</strong>
              <br/>
              Le Consultant peut selon les cas de figure soit être un salarié d’une entreprise ou d’un organisme d’accompagnement social 
              et professionnel, adhérent à la Plateforme SAM.I., soit un Consultant indépendant qui s’inscrit sur la Plateforme à la 
              suite d’une invitation d’un Bénéficiaire à le suivre via la Plateforme.
              <br/>
              <br/>
              <strong>5.1 Compte consultant</strong>
              <br/>
              Pour la création d’un Compte Consultant, l’Utilisateur doit fournir au moment de son inscription :
              <ul>
                <li>- une adresse email,</li>
                <li>- un mot de passe,</li>
                <li>- son nom et prénom,</li>
                <li>- son adresse,</li>
                <li>- son numéro de téléphone,</li>
                <li>- son entreprise/organisme,</li>
                <li>- son poste</li>
              </ul>
              <br/>
              <br/>
              <strong>5.2 Accès à la fiche de suivi du Bénéficiaire par un Consultant</strong>
              <br/>
              Le Consultant doit, pour avoir accès à la fiche de suivi d’un Bénéficiaire, soit :
              <br/>
              <ul style={{listStyleType: 'none'}}>
                <li>
                  <strong>a)</strong>si le Bénéficiaire est déjà inscrit sur SAM.I, avoir envoyé une invitation d’accès au Bénéficiaire, ou 
                </li>
                <li>
                  <strong>b)</strong>si le Bénéficiaire n’est pas déjà inscrit sur SAM.I, avoir envoyé, par mail depuis la Plateforme, 
                  au Bénéficiaire une demande de création de compte en cliquant sur « Envoyer une demande d’inscription ».
                </li>
              </ul>
              <br/>
              Le Bénéficiaire invité par un Consultant à créer un Compte sur SAM.I reçoit sur sa boite mail un lien d’inscription 
              le renvoyant vers la Plateforme pour créer un compte Bénéficiaire.
              <br/>
              <br/>
              Cette demande d’accès à la fiche de suivi du Bénéficiaire par le Consultant reste en attente jusqu’à l’autorisation 
              d’accès du Bénéficiaire.
              <br/>
              <br/>
              <strong>5.3 Ajout et modification d’actions</strong>
              <br/>
              Le Consultant peut, une fois que le Bénéficiaire lui a autorisé l’accès à sa fiche de suivi, ajouter une action à effectuer 
              sur sa fiche de suivi telle que par exemple, un bilan de compétences à effectuer, un essai encadré, une visite de pré-reprise etc.
              <br/>
              <br/>
              Le Consultant peut également ajouter une description détaillée de l’action qu’il enregistre sur la fiche Bénéficiaire.
              <br/>
              <br/>
              Attention, toutefois, le champ de description de l'action ne doit contenir aucune information soumise au secret médical. 
              Le Consultant engage son entière responsabilité s'il ne respecte pas cette règle.
              <br/>
              <br/>
              Le statut d’une action peut être modifié à tout moment. La date de la dernière modification est indiquée pour permettre 
              une traçabilité des modifications apportées par le Consultant sur la fiche de suivi du Bénéficiaire.
              <br/>
              <br/>
              Le statut de l’action est choisi par le Consultant entre « A réaliser », « En cours », ou « Réalisée ».
              <br/>
              <br/>
              Le Consultant doit mettre à jour la fiche du Bénéficiaire dès qu’une action est créée ou lors de la modification 
              du statut d’une action.
              <br/>
              <br/>
              Le Consultant peut consulter la liste de ses Bénéficiaires dans l’onglet « Mes Bénéficiaires ».
              <br/>
              <br/>
              <strong>5.4 Suppression du compte Consultant</strong>
              <br/>
              Le Consultant peut, à tout moment, supprimer son compte en cliquant sur la rubrique « Supprimer mon compte » de son profil.
              <br/>
              <br/>
              Le compte Consultant sera automatiquement supprimé par TECH FOR WORK au jour où le Consultant quitte l’entreprise 
              ou l’organisme social adhérent à la Plateforme, supprimant ainsi tous les accès du Consultant à la Plateforme.
              <br/>
              <br/>
              <strong>Article 6. Engagements de l’Utilisateur</strong>
              <br/>
              <br/>
              <strong>6.1</strong>L’Utilisateur doit s’assurer que l'adresse email qu’il renseigne est valide.
              <br/>
              <br/>
              L’Utilisateur s'engage notamment à ne pas créer une fausse identité de nature à induire 
              TECH FOR WORK, les autres Utilisateurs ou les tiers en erreur et à ne pas usurper l'identité d'une autre 
              personne. L'Utilisateur s'engage à mettre immédiatement à jour, en cas de modification, les données qu'il 
              a communiquées lors de sa première connexion sur son Compte.
              <br/>
              <br/>
              Dans l'hypothèse où l'Utilisateur fournirait des informations fausses, inexactes, obsolètes, incomplètes 
              ou de nature à induire en erreur, TECH FOR WORK pourra, immédiatement sans préavis ni indemnité, suspendre 
              l’accès au Compte et lui refuser l'accès, de façon temporaire ou définitive à tout ou partie des Services.
              <br/>
              <br/>
              Il incombe à l’Utilisateur de garder son mot de passe confidentiel et en sécurité. Il sera tenu responsable 
              de toute utilisation de son mot de passe ainsi que de toute activité émanant de son Compte, que cette activité 
              ait été ou non autorisée par ce dernier. L’Utilisateur devra informer immédiatement TECH FOR WORK de toute 
              utilisation non autorisée de son Compte, de son identifiant et mot de passe.
              <br/>
              <br/>
              L’Utilisateur peut modifier son mot de passe communiqué à partir de son Compte dans la rubrique 
              [« Mot de passe oublié »] et modifier à tout moment les informations de son profil en cliquant sur 
              la rubrique « Modifier mon profil ».
              <br/>
              <br/>
              TECH FOR WORK se réserve le droit de suspendre ou résilier un Compte si, sur ce Compte, interviennent 
              certaines activités qui, à son entière discrétion, semblent constituer, ou pourraient constituer une 
              violation des présentes CGU, ou encore une atteinte aux droits des tiers ou de toute législation et 
              réglementation en vigueur. 
              <br/>
              <br/>
              Chaque Utilisateur s’engage à avertir immédiatement TECH FOR WORK de toute utilisation non autorisée de 
              son mot de passe et de son login ou de toute connexion en violation de la sécurité de l’accès à son compte.
              <br/>
              <strong>6.2</strong>L’Utilisateur s’engage à faire un usage strictement personnel des Services. Il s’interdit 
              en conséquence de céder, concéder ou transférer tout ou partie de ses droits ou obligations au titre des 
              présentes à un tiers, de quelque manière que ce soit.
              <br/>
              <br/>
              Les Utilisateurs s’engagent à ne pas utiliser ni mettre en œuvre de robot ou tout autre moyen automatisé pour 
              accéder à la Plateforme. Chaque Utilisateur s’engage à diffuser des contenus et informations exemptes de virus, 
              chevaux de Troie, vers, bombes, ou tout autre outil/moyen logique destinés à endommager, nuire, intercepter ou 
              entraver tout système de la Plateforme, et /ou des données ou informations diffusées.
              <br/>
              <br/>
              Les Utilisateurs ne peuvent pas utiliser les coordonnées visibles des autres Utilisateurs, ou collecter des 
              informations sur les Utilisateurs, pour faciliter l’envoi de communications de masse non sollicitées tels que 
              spam ou permettre à des tiers l’utilisation desdites informations sur les Utilisateurs.
              <br/>
              <br/>
              <strong>6.3</strong>L’Utilisateur s’engage de manière générale à :
              (i) utiliser les Services dans le respect des lois et règlements applicable et respecter les présentes CGU ;
              <br/>
              (ii) ne pas utiliser les Services d’une façon qui serait susceptible de nuire à la réputation de TECH FOR WORK ;
              <br/>
              (iii) à ce que les Données qu’il communique dans le cadre de l’utilisation des Services ne portent pas 
              préjudice aux droits des tiers et à être autorisé à les diffuser.
              <br/>
              <br/>
              <strong>Article 7. Responsabilité et garanties de TECH FOR WORK</strong>
              <br/>
              TECH FOR WORK met en place les moyens et les mesures nécessaires à la bonne marche et au maintien de la continuité 
              et de la qualité de ses Services étant précisé que TECH FOR WORK est tenue à une obligation de moyens, à l’exclusion 
              de toute obligation de résultat, ce que l’Utilisateur reconnait et accepte expressément.
              <br/>
              <br/>
              L’Utilisateur reconnaît que le rôle de TECH FOR WORK, à travers la Plateforme SAM.I, se limite à celui d’un simple 
              intermédiaire et prestataire technique.
              <br/>
              <br/>
              L’Utilisateur reconnaît que TECH FOR WORK ne peut être tenue responsable d’une interruption des Services ou d’un retard 
              d’exécution ne relevant pas de son contrôle et, en particulier, que la fourniture des Services dépend de la fiabilité, 
              de la disponibilité et de la continuité de connexions d’un certain nombre de parties tierces (exploitants du réseau des 
              télécommunications, l’Internet public, les équipements de l’Utilisateur, etc.) ainsi que de l’exactitude et de l’intégrité 
              des données fournies par l’Utilisateur.
              <br/>
              <br/>
              TECH FOR WORK pourra être amenée à suspendre les Services à des fins de maintenance.
              <br/>
              <br/>
              Il est entendu entre les Parties que TECH FOR WORK n’est en aucun cas tenu responsable de réparer d’éventuels dommages indirects 
              subis par les Utilisateurs à l’occasion de l’utilisation de la Plateforme et de ses Services. 
              <br/>
              <br/>
              TECH FOR WORK ne garantit pas aux Utilisateurs que les Services, étant standards et nullement proposés à la seule intention 
              d’un Utilisateur donné en fonction de ses propres contraintes personnelles, répondront spécifiquement à ses besoins et attentes.
              <br/>
              <br/>
              En aucun cas, TECH FOR WORK n’effectue de vérification ou de contrôle de cohérence ou de pertinence (notamment au plan 
              scientifique, médical ou sanitaire) des données saisies par l’Utilisateur.
              <br/>
              <br/>
              En aucun cas, TECH FOR WORK n’est responsable de toute utilisation de la Plateforme, des Services qui y sont proposés 
              et des données qui y sont diffusées, à quelque titre que ce soit, pour quelque décision que ce soit.
              <br/>
              <br/>
              Les avis, recommandations, informations et données diffusées par les Consultants s ne sont en aucune façon contrôlés, 
              approuvés, validés ou homologués par TECH FOR WORK. Toute utilisation de la Plateforme, des Services qui y sont proposés 
              et des données qui y sont diffusées s’effectue aux risques et périls des Utilisateurs.
              <br/>
              <br/>
              <strong>Article 8. Propriété intellectuelle</strong>
              <br/>
              La structure générale de la Plateforme et tout autre élément la composant (tels que la charte graphique, le nom de domaine, 
              le logo etc) sont la propriété exclusive de TECH FOR WORK.
              <br/>
              <br/>
              Toute reproduction et représentation, totale ou partielle de la Plateforme et/ou des éléments les composant par quelque 
              procédé que ce soit, sans l’autorisation expresse de TECH FOR WORK sont dès lors interdites et constitueraient une contrefaçon 
              sanctionnée par les articles L. 335-2 et suivants du Code de la propriété intellectuelle.
              <br/>
              <br/>
              En particulier, l’Utilisateur s’engage à ne pas :
              <br/>
              (i) tenter d’accéder ou copier les codes sources de la Plateforme ;
              <br/>
              (ii) utiliser la Plateforme à d’autres fins que celles d’utiliser les Services ;
              <br/>
              (iii) créer des copies de la Plateforme ;
              <br/>
              (iv) reproduire, corriger, extraire, modifier, traduire en toutes langues ou tous langages, réutiliser, arranger, 
              adapter, décompiler (à l’exception et dans la seule mesure où la loi applicable l’autorise de manière expresse), 
              ni incorporer la Plateforme dans un autre logiciel ou créer des travaux dérivés sur la base de la Plateforme quels 
              qu’en soient le moyen et le support ;
              <br/>
              (v) faire des tests d’intrusion ou tenter d’obtenir un déni de service sur les Services.
              <strong>Article 9. Suppression d’un compte </strong>
              <br/>
              <strong>9.1 Suppression par TECH FOR WORK</strong>
              <br/>
              En cas de faute grave de l’Utilisateur, telle que l’utilisation d’un ou plusieurs Services (a) contraire aux 
              stipulations des présentes CGU, (b) contraire aux lois et réglementations applicables (c), susceptible de porter 
              préjudice à la réputation de TECH FOR WORK ou à l’intégrité physique ou mentale d’un autre Utilisateur, TECH 
              FOR WORK se réserve le droit de résilier, par tout moyen, tout ou partie des Services avec un préavis de sept 
              (7) jours sans indemnité.
              <br/>
              <br/>
              <strong>9.2 Suppression par l’Utilisateur</strong>
              <br/>
              L’Utilisateur peut résilier les présentes CGU à tout moment en supprimant son Compte en cliquant sur la rubrique 
              « Supprimer mon compte » de son profil.
              <br/>
              <br/>
              <strong>9.3 Conséquences de la suppression</strong>
              <br/>
              Toute suppression d’un Compte par TECH FOR WORK ou l’Utilisateur entraîne automatiquement (i) la fin du droit d’accès 
              à la Plateforme et ses Services par l’Utilisateur, (ii) la suppression ou l’anonymisation de toutes ses Données à caractère personnel.
              <br/>
              <br/>
              TECH FOR WORK est néanmoins autorisée à conserver une copie de ces données pour toutes fins imposées par la loi applicable.
              <br/>
              <br/>
              <br/>
              <strong>Article 10. Protection des données à caractère personnel </strong>
              <br/>
              La politique de protection des Données à caractère personnel disponible sur [la Plateforme décrit les rôles et 
              les obligations respectifs de l’Utilisateur, lorsqu’il est Consultant et lorsqu’il est Bénéficiaire, et de TECH 
              FOR WORK concernant le traitement des Données à caractère personnel dans le cadre de l’exploitation et l’utilisation 
              de de la plateforme SAM.I. En acceptant les présentes CGU, l’Utilisateur et TECH FOR WORK s’engagent à respecter 
              les termes et conditions de ladite politique de protection des données à caractère personnel.
              <br/>
              <br/>
              <strong>Article 11. Force Majeure</strong>
              <br/>
              Sont considérés comme des cas de force majeure, les situations dont les éléments constitutifs sont caractérisés 
              au sens de l’article 1218 du Code civil et de la jurisprudence de la Cour de cassation. En cas de survenance d’un 
              tel évènement, la partie victime devra en informer immédiatement par écrit l’autre Partie de leur survenance ainsi 
              que de leur disparition.
              <br/>
              <br/>
              Le cas de force majeure suspend les obligations de la Partie concernée pendant le temps où jouera la force majeure. 
              Néanmoins, les Parties s’efforceront d’en minimiser dans toute la mesure du possible les conséquences. 
              <br/>
              <br/>
              <strong>Article 12. Modalités de résolution des litiges</strong>
              <br/>
              <br/>
              Les présentes CGU sont régies par le droit français.
              <br/>
              <br/>
              Toute réclamation relative à l’utilisation des Services et/ou à l’exécution des présentes CGU pourra être adressée 
              à l’adresse suivante : support@tech4work.fr afin qu’elle puisse être traitée dans les meilleurs délais.
              <br/>
              <br/>
              En cas d’échec des tentatives de résolution amiable, toutes contestations à la validité, l’interprétation 
              et/ou l’exécution des présentes CGU devront être portées même en cas de pluralité des défendeurs ou d’appel 
              en garantie, devant les tribunaux compétents.
              <br/>
              <br/>
              Dernière modification : 23/04/2023
            </Typography>
      </Grid>
    </Grid>
  );
};
