import React from 'react';
import Grid from '@mui/material/Grid';
import { TContainer } from '../../Types';

export const ResponsiveContainer = ({ children }: TContainer) => {
  return (
    <Grid container gap={3} justifyContent="center" maxWidth={1480} margin="0 auto">
      {children}
    </Grid>
  );
};
