import React from 'react';
import Typography from '@mui/material/Typography';

type DataTableHeaderProps = {
  headerName: string;
}

export const DataTableHeader = ({headerName}: DataTableHeaderProps) => {
  return <Typography variant='h4' color='GrayText'>{headerName}</Typography>
}


export const NewDataTableHeader = ({headerName}: DataTableHeaderProps) => {
  return <Typography variant='body1' fontSize={10} sx={{fontSize: '10px !important'}}>{headerName}</Typography>
}