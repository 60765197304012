import { UserState } from '../../Types';
import { GlobalState } from 'little-state-machine';
import { initialUserState } from '.';
import { GetActionDto } from '../../Types/actions.type';

export const updateUserState = (state: GlobalState, userState: Partial<UserState>) => {
  return {
    ...state,
    user: {
      ...state.user,
      ...userState,
    },
  };
};

export const updateUserActions = (state: GlobalState, actions: GetActionDto[]) => {
  return {
    ...state,
    user: {
      ...state.user,
      actions: {
        ...actions
      }
    }
  }
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const resetUserState = (state: GlobalState, userState: Partial<UserState>) => {
  return {
    ...state,
    user: initialUserState,
  };
};
