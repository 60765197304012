import { styled } from '@mui/system';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import theme from '..';

export const Tab = styled(TabUnstyled)(
  () => `
  transition: all .2s ease-out
  font-family: Inter;
  color: white;
  cursor: pointer;
  font-size: 18px;
  background-color: ${theme.secondaryPalette.lightGreen};
  color: ${theme.palette.primary.main};
  padding: 12px 16px;
  border: none;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: center;
  z-index: 0;


  &:not(:first-of-type) {
    margin: 0 6px;
  }

  &:hover {
    background-color: ${theme.palette.primary.light};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    color: #fff;
    outline: none;
    // background-color: ${theme.palette.primary.dark};
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${theme.palette.background.default};
    box-shadow: 0px 12px 66px #8B8B8B29;
    color: ${theme.palette.primary.main};
    font-weight: bold;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`,
);

export const TabPanel = styled(TabPanelUnstyled)(
  () =>
    `
      width: 100%;
      font-family: ${theme.typography.fontFamily};
      font-size: 15px;
    `,
);

export const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  display: flex;
  align-items: center;
  align-content: space-between;
`;
