import React from 'react';
import { Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Slide1 from '../../Assets/carousel/slide1.svg';
import Slide2 from '../../Assets/carousel/slide2.svg';
import Slide3 from '../../Assets/carousel/slide3.svg';
import Slide4 from '../../Assets/carousel/slide4.svg';

const useStyles = makeStyles((theme: Theme) => ({
  host: {
    width: '90%',
    margin: 0,
    '& .carousel.carousel-slider': {
      overflowY: 'visible',
      overflowX: 'hidden',
      '& .dot': {
        margin: '0 6px',
        backgroundColor: theme.palette.grey[600],
        boxShadow: 'none',
      },
      '& .dot.selected': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
      },
    },
  },
}));

const images = [
  {
    title: 'Une plateforme collaborative',
    src: Slide1
  },
  {
    title: 'Maintien et retour en emploi réussi',
    src: Slide2
  },
  {
    title: 'Collaboration et coordination',
    src: Slide3
  },
  {
    title: 'Accompagnement pour un projet réussi',
    src: Slide4
  }
];

export const CarouselComponent = () => {
  const classes = useStyles();
  return (
    <Carousel
      className={classes.host}
      showThumbs={false}
      showArrows={false}
      showStatus={false}
      autoPlay
      infiniteLoop
      interval={4000}
    >
      {images.map((img, index) => (
        <div key={index}>
          <Typography variant="h3" marginBottom={(theme) => theme.spacing(1)}>
            {img.title}
          </Typography>
          <div style={{ padding: '2rem 0' }}>
            <img src={img.src}></img>
          </div>
        </div>
      ))}
    </Carousel>
  );
};
