import React from 'react';
import MatomoTracking from './MatomoTracking';
import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './Theme';
import { SnackbarProvider } from 'notistack';
import { AppRoutes } from './Routes/Routes';
import { StateMachineProvider } from 'little-state-machine';
import { _createStore } from './Stores';
import { ErrorProvider } from './Providers';
import { ErrorNotifier } from './Components/Organisms/Popups/Error/error.notifier';
import { PopupProvider } from './Providers';
import {CheckCircleOutline, ErrorOutline, HighlightOff} from "@mui/icons-material";

_createStore();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MatomoTracking siteId="tech4work.matomo.cloud" trackerUrl="2" />
      <CssBaseline />
      <StateMachineProvider>
      <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          iconVariant={{
            success: <CheckCircleOutline style={{marginRight: 10}}/>,
            error: <HighlightOff style={{marginRight: 10}}/>,
            info: <ErrorOutline style={{marginRight: 10}}/>,
          }}
          anchorOrigin={{vertical: "top", horizontal: "center"}}>
        <PopupProvider>
          <ErrorProvider>
            <ErrorNotifier />
            <AppRoutes />
          </ErrorProvider>
        </PopupProvider>
        </SnackbarProvider>
      </StateMachineProvider>
    </ThemeProvider>
  );
}

export default App;
