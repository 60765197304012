import { useContext } from "react"
import { ReauthContext } from "../../Providers"

export const useReauth = () => {
  const {reauthNeeded, setReauthNotNeeded, setNeedReauth} = useContext(ReauthContext);

  return {
    reauthNeeded,
    setNeedReauth,
    setReauthNotNeeded,
  };
}
