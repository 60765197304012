/**
 * Updates LoginSessionState used to deal with role selection / information persitence in Login page
 * for Account creation forms
 */

import { GlobalState } from 'little-state-machine';
import { TLoginSessionState } from '../../Types';
import { initialLoginSessionState } from '../../Stores';

export const updateLoginState = (state: GlobalState, loginSessionState: Partial<TLoginSessionState>) => {
  return {
    ...state,
    loginSession: {
      ...state.loginSession,
      ...loginSessionState,
      createAccountValues: {
        ...state.loginSession.createAccountValues,
        ...loginSessionState.createAccountValues,
      },
    },
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const resetLoginState = (state: GlobalState, loginSessionState: Partial<TLoginSessionState>) => {
  return {
    ...state,
    loginSession: initialLoginSessionState,
  };
};
