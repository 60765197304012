import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { TPrivateRouteProps } from '../Types';
import { useStateMachine } from 'little-state-machine';
import { MainLayout } from '../Layouts';

export const PrivateRoute = ({ redirectTo }: TPrivateRouteProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    state: {
      user: {
        isAuth,
        consultant,
      },
    },
  } = useStateMachine();

  useEffect(() => {
    /**
     * If user is of type beneficiary and try to access consultant resource
     * OR
     * If user is of type consultant and try to access beneficiary route
     * -> Redirect to its valid location
     */
    const [currentQueryLocation, subPath] = location.pathname.replace('/', ' ').trim().split('/');
    if (consultant !== null) {
      if (consultant && currentQueryLocation !== 'consultant') {
        navigate(`/consultant/${subPath}`);
      } else if (!consultant && currentQueryLocation === 'consultant') {
        navigate(`/beneficiary/${subPath}`);
      }
    }
  }, [consultant, location, navigate]);

  return isAuth ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to={redirectTo} />
  );
};
