import { GetBeneficiaryLightDto } from '../../../Types';
import AbstractDataTableService from '../data-table.base.service';

export interface BeneficiaryRow {
  id: string;
  name: string;
  statusBOE?: string;
  consultants: string;
  isSuspended: boolean;
}

class ConsultantsBeneficiariesDataTableService extends AbstractDataTableService<GetBeneficiaryLightDto, BeneficiaryRow> {
  protected buildRow(beneficiary: GetBeneficiaryLightDto): BeneficiaryRow {
    return {
      id: beneficiary.id,
      name: beneficiary.lastName + " " + beneficiary.firstName,
      statusBOE: beneficiary.statusBOE || undefined,
      consultants: "",
      isSuspended: beneficiary.isSuspended,
    }
  }
}
export const consultantsBeneficiariesDataTableService = new ConsultantsBeneficiariesDataTableService(10);