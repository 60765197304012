/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { useStateMachine } from 'little-state-machine';
import { useSearchParams } from 'react-router-dom';

import { IAction, UserState, GetConsultantDto } from '../Types';
import AbstractProfileService from '../Services/User/base.profile.service';
import APIAxios, { APIRoutes } from '../API/api.axios';
import SnackError from '../Utils/error.utils';
import { GetActionDto } from '../Types/actions.type';
import { updateUserState } from '../Stores';

export interface IProfileContextProps<T> {
  id: string;
  user: Partial<T> | null;
  actions: IAction[] | null;
  selectedAction: GetActionDto | null;
  selectAction: (actionId?: string, callback?: () => void) => void;
  availableDevices: Map<string, string> | null;
}


export const BeneficiaryProfileContext = createContext<IProfileContextProps<UserState>>({
  id: '',
  user: null,
  actions: null,
  selectedAction: null,
  selectAction: () => {},
  availableDevices: null,
});

export const ConsultantProfileContext = createContext<IProfileContextProps<UserState>>({
  id: '',
  user: null,
  actions: null,
  selectedAction: null,
  selectAction: () => {},
  availableDevices: null,
});

export const ConsultantLightProfileContext = createContext<IProfileLightContextProps>({
  user: undefined,
})

export interface IProfileLightContextProps {
  user?: GetConsultantDto;
}

type TUserProfileProps<T> = {
  specializedProfileService: AbstractProfileService<T>;
  children: ReactNode;
  userId?: string;
};

export function UserProfileProvider<T>({ specializedProfileService, children, userId }: TUserProfileProps<T>) {
  const {
    state: {
      user: { id: stateUserId },
    },
    actions
  } = useStateMachine({ updateUserState });
  const [searchParams] = useSearchParams();
  const [id, setId] = useState<string | undefined>();
  const [actionsState, setActions] = useState<IAction[] | null>();
  const [availableDevices, setAvailableDevices] = useState<Map<string, string>>();
  const [selectedAction, setSelectedAction] = useState<GetActionDto | null>();

  const [user, setUser] = useState<Partial<UserState> | null>();

  const updateSelectedAction = useCallback(
    async (actionId?: string, callback?: () => void) => {
      if (actionId) {
        try {
          const res = await APIAxios({...APIRoutes.GETAction(actionId)})
          setSelectedAction(res.data);
          callback?.();
        } catch (err) {
        }
      } else {
        setSelectedAction(null);
      }
    },
    [],
  );

  // Updates the uid of the user in the context
  useEffect(() => {
    if (userId) {
      setId(userId);
    } else {
      if (!id) {
        if (searchParams.get('uid') && user?.consultant) {
          setId(searchParams.get('uid') as string);
        } else if (stateUserId && user?.consultant === null) {
          setId(stateUserId);
        }
      }
    }
  }, [searchParams, stateUserId, id, userId]);

  // Fetches the user's profile and specialized profile
  const fetchProfile = useCallback(async () => {
    const uid = searchParams.get("uid");
    
    if (!uid) {
      try {
        const user = await APIAxios({...APIRoutes.GETMe()})

        actions.updateUserState(user.data as Partial<UserState>);

        setUser(user.data as Partial<UserState>);
      } catch (err) {
        throw new SnackError(
          "Une erreur est survenue. Veuillez réessayer plus tard.",
          "error"
        );
      }
    } else {
      try {
        const res = await APIAxios({...APIRoutes.GETBeneficiary(uid)})
        setUser({beneficiary: res.data});
      } catch (err) {
        throw new SnackError(
          "Une erreur est survenue. Veuillez réessayer plus tard.",
          "error"
        );
      }
    }
  }, [id]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const contextValue = {
    id: id as string,
    user: user || null,
    actions: actionsState as IAction[],
    selectedAction: selectedAction as GetActionDto | null,
    availableDevices: availableDevices as Map<string, string>,
    selectAction: useCallback((actionId?: string, callback?: () => void) => updateSelectedAction(actionId, callback), [updateSelectedAction]),
  };

  if (!!user?.consultant) {
    return <ConsultantProfileContext.Provider value={contextValue}>{children}</ConsultantProfileContext.Provider>;
  }
  return <BeneficiaryProfileContext.Provider value={contextValue}>{children}</BeneficiaryProfileContext.Provider>;
}
