import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { makeStyles } from '@mui/styles';
import { TScreenTabAction } from '../../Types';

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    maxWidth: 'unset',
    transition: 'all .2s ease-in',
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    textTransform: 'capitalize',
    fontSize: theme.typography.fontSize,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '&.Mui-selected': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey[200],
    },
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      gap: '10px',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
}));

type TTabActionsComponentProps = {
  actions: TScreenTabAction[]
};

export const TabActionsComponent = ({actions}: TTabActionsComponentProps) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    setCurrentTab(actions.findIndex((tab) => tab.value === value));
  };
  return (
    <TabContext value={actions[currentTab].value}>
      <Grid container justifyContent="space-between" textAlign="start" gap={2}>
        <Grid item xs={12} md={5} marginBottom={2}>
          <Tabs
            orientation="vertical"
            scrollButtons={true}
            value={actions[currentTab].value}
            onChange={handleTabChange}
            className={classes.tabs}
          >
            {actions.map((tab) => (
              <Tab key={tab.value} value={tab.value} label={tab.title} className={classes.tab}></Tab>
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12} md={6} container>
          {actions.map((tab, index) => (
            <TabPanel sx={{ padding: theme => theme.spacing(0), maxWidth: '100%' }} key={index} value={tab.value}>
              {tab.component}
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </TabContext>
  );
}
