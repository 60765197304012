import React, { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldErrors } from '../../../Utils/yup.utils';
import { Button, Grid, IconButton, Stack, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import APIAxios, { APIRoutes } from '../../../API/api.axios';
import { NewHeader } from '../../../Components/Molecules/new-title.component';
import { Popup } from '../../../Theme';
import { CreateOrganismDto, GetOrganismDto } from '../../../Types';
// import { consultantValidation } from './consultant-validation';
import { useSnackbar } from 'notistack';
import Close from '../../../Assets/cross_close.svg'
import { organismValidation } from './organism-creation.validation';
import FileSelect from '../../../Components/Atoms/select-file.atom';
import Photo from '../../../Assets/photo.svg'

interface CreateOrganismProps {
  handleUpdate?: (organism: GetOrganismDto) => void;
  handleClose?: () => void;
  id?: string;
}

export const CreateOrganismPopup = (props: CreateOrganismProps) => {

  const { handleUpdate, handleClose, id } = props;

  const { organismId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [organism, setOrganism] = useState<Partial<CreateOrganismDto>>();

  const [errors, setErrors] = useState<FieldErrors>();

  const getOrganism = useCallback(async () => {
    if (organismId) {
      try {
        const res = await APIAxios({...APIRoutes.GETOrganism(organismId)})
        if (res.data) {
          setOrganism(res.data);
        }
      } catch (err) {
        
      }
    }
  }, [organismId]);

  useEffect(() => {
    getOrganism();
  }, [getOrganism]);

  const updateOrganism = (name: string) => (value: any) => setOrganism({...organism, [name]: value})


  const handleCreateOrganism = useCallback(async () => {
    if (organism) {
      const organismErrors = organismValidation(organism);
      if (!Object.keys(organismErrors).length) {
        try {
          const formData = new FormData();
          // organism.avatar && formData.append('avatar', organism.avatar);
          organism?.address && formData.append('address', organism.address);
          organism?.email && formData.append('email', organism.email);
          organism?.city && formData.append('city', organism.city);
          organism?.postcode && formData.append('postcode', organism.postcode);
          organism?.firstName && formData.append('firstName', organism.firstName);
          organism?.lastName && formData.append('lastName', organism.lastName);
          organism?.name && formData.append('name', organism.name);
          organism?.phoneNumber && formData.append('phoneNumber', organism.phoneNumber);

          if (id && handleUpdate) {
            const res = await APIAxios({...APIRoutes.PATCHOrganismById(id, formData)})
            const obj = res.data as GetOrganismDto;
            if (obj) {
              enqueueSnackbar(`L'organisme ${organism?.name} a bien été modifié`, { variant: 'success' });
              handleUpdate(obj)
            }
          } else {
            const res = await APIAxios({...APIRoutes.POSTCreateOrganism(formData)})
            const obj = res.data as GetOrganismDto
            if (obj) {
              enqueueSnackbar(`L'organisme ${organism?.name} a bien été créé`, { variant: 'success' });
              navigate(-1);
            }
          }
        } catch (err: any) {
          if (err.response.status === 409) {
            enqueueSnackbar('Cet e-mail est associé à un compte existant', { variant: 'error' });
          } else {
            err.response.data.message.forEach((it: string) => {
              switch (it) {
                case 'postcode must be a number conforming to the specified constraints':
                  enqueueSnackbar('Le code postal est invalide', { variant: 'error' });
                  break
                case 'email must be an email':
                  enqueueSnackbar("Le format de l'adresse e-mail est invalide", { variant: 'error' });
                  break
                default:
                  enqueueSnackbar('Une erreur est survenue. Veuillez réessayer plus tard.', { variant: 'error' });
                  break  
              }
            });
          }
        }
      } else {
        setErrors(organismErrors);
      }
    }
  }, [enqueueSnackbar, navigate, organism]);

  return (
    <Popup open={true} maxWidth='md' fullWidth>
      <Stack padding={4} gap={3}>
        <Stack justifyContent='space-between' direction='row' alignItems='top'>
          <NewHeader title={`${id ? 'Modifier' : 'Ajouter'} un organisme`} />
          <IconButton onClick={() => handleClose ? handleClose() : navigate(-1)}><img src={Close} alt='' /></IconButton>
        </Stack>
        {/*<Stack direction='column' justifyContent='center' alignItems='center' gap={1}>*/}
        {/*  <Stack justifyContent='center' alignItems='center' style={{height: '83px', width: '83px', backgroundColor: 'rgba(90, 173, 178, 0.1)', borderRadius: '8px'}}>*/}
        {/*    {organism?.profilePictureUrl || organism?.avatar ? (*/}
        {/*      <img alt='' style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px'}} src={organism.avatar ? URL.createObjectURL(organism.avatar) : organism?.profilePictureUrl} />*/}
        {/*    ) : (*/}
        {/*      <img src={Photo} alt='' />*/}
        {/*    )}*/}
        {/*  </Stack>*/}
        {/*  <FileSelect handleFileChange={updateOrganism('avatar')} label="Ajouter un logo" />*/}
        {/*</Stack>*/}
        <Grid container gap={2} direction='column'>
          <Grid item container gap={2}>
            <Grid item xs>
              <TextField
                fullWidth
                type="text"
                color="primary"
                placeholder="Nom"
                value={organism?.lastName}
                helperText={errors?.lastName}
                error={!!errors?.lastName}
                // label="Nom"
                onChange={(e) => updateOrganism("lastName")(e.target.value)}
                />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                type="text"
                color="primary"
                placeholder="Prénom"
                value={organism?.firstName}
                helperText={errors?.firstName}
                error={!!errors?.firstName}
                // label="Prénom"
                onChange={(e) => updateOrganism("firstName")(e.target.value)}
                />
            </Grid>
          </Grid>
          <Grid item container gap={2}>
            <Grid item xs>
              <TextField
                fullWidth
                type="text"
                color="primary"
                placeholder="Nom de l'organisme"
                value={organism?.name}
                helperText={errors?.name}
                error={!!errors?.name}
                // label="Nom de l'organisme"
                onChange={(e) => updateOrganism("name")(e.target.value)}
                />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                type="text"
                color="primary"
                placeholder="Adresse"
                value={organism?.address}
                label={organism?.email ? undefined : "Adresse"}
                onChange={(e) => updateOrganism("address")(e.target.value)}
                />
            </Grid>
          </Grid>
          <Grid item container gap={2}>
            <Grid item xs>
              <TextField
                fullWidth
                type="text"
                color="primary"
                placeholder="Code postal"
                value={organism?.postcode}
                label={organism?.email ? undefined : "Code postal"}
                onChange={(e) => updateOrganism("postcode")(e.target.value)}
                />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                type="text"
                color="primary"
                placeholder="Ville"
                value={organism?.city}
                label={organism?.email ? undefined : "Ville"}
                onChange={(e) => updateOrganism("city")(e.target.value)}
                />
            </Grid>
          </Grid>
          <Grid item container gap={2}>
            <Grid item xs>
              <TextField
                fullWidth
                type="text"
                color="primary"
                placeholder="Numéro de téléphone"
                value={organism?.phoneNumber}
                label={organism?.email ? undefined : "Numéro de téléphone"}
                onChange={(e) => updateOrganism("phoneNumber")(e.target.value)}
                />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                type="text"
                color="primary"
                placeholder="Adresse mail de contact"
                value={organism?.email}
                label={organism?.email ? undefined : "Adresse mail de contact"}
                helperText={errors?.email}
                error={!!errors?.email}
                onChange={(e) => updateOrganism("email")(e.target.value)}
                />
            </Grid>
          </Grid>
        </Grid>
        <Stack justifyContent='center' alignItems='center'>
          <Button variant='contained' onClick={handleCreateOrganism}>Ajouter</Button>
        </Stack>
      </Stack>
    </Popup>
  )
}