import React, { useMemo } from 'react';
import { TScreenSidepanelAction } from '../../Types';
import { ListItemButton } from '../../Theme';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useMediaQuery, useTheme } from '@mui/material';
import {updateSidepanelOpen } from '../../Stores';

import { useNavigate, useLocation } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';

interface SidepanelActionsProps {
  open: boolean;
  configuration: TScreenSidepanelAction[];
}

export const SidepanelActions = ({ open, configuration }: SidepanelActionsProps) => {
  const { state: { user: { consultant, beneficiary, admin, organism } }} = useStateMachine();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { actions } = useStateMachine({updateSidepanelOpen});
  const navigate = useNavigate();
  const location = useLocation();

  const currentActiveIndex = useMemo(() => {
    return configuration.findIndex(
      (c) => c.navigateTo === location.pathname ||
        c.siblingRoutes?.some((r) => {
          return location.pathname.indexOf(r) !== -1
        })
      );
  }, [location, configuration]);

  const handleNavigation = (navigateTo: string) => {
    navigate(navigateTo);
    if (isSm) {
      actions.updateSidepanelOpen({
        isSidePanelOpen: false
      });
    }
  };

  return (
    <List
      style={{
        padding: theme.spacing(6, 3),
        gap: '20px',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      {configuration.map((sidepanelAction, index) => (
        <ListItemButton
          open={open}
          className={index === currentActiveIndex ? 'active' : ''}
          key={index}
          onClick={() => handleNavigation(sidepanelAction.navigateTo)}
        >
          <ListItemIcon
            sx={{
              minWidth: 'unset',
              width: '48px',
            }}
          >
            {index === currentActiveIndex ? sidepanelAction.activeIcon : sidepanelAction.icon}
          </ListItemIcon>
          {(open || admin || organism) && <ListItemText sx={{color: (!consultant && !beneficiary && index !== currentActiveIndex) ? 'rgb(155, 159, 164)' : ''}} primary={sidepanelAction.title} />}
        </ListItemButton>
      ))}
    </List>
  );
};
