import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ProfileAvatar } from '../../../Components';
import { useNavigate } from 'react-router-dom';

interface IMyActionsBeneficiaryProps {
  name: string | undefined;
  userId: string;
}

export const MyActionBeneficiary = ({ name, userId }: IMyActionsBeneficiaryProps) => {
  const navigate = useNavigate();
  if (!name) {
    return <Typography>-</Typography>;
  }

  const handleRedirectToUser = () => {
    if (userId) {
      navigate(`../my-beneficiaries/journey?uid=${ userId }`);
    }
  }

  return (
    <Grid item container gap={1} alignItems="center" flexWrap="nowrap" style={{
      cursor: userId ? 'pointer': 'default'
    }}>
      <ProfileAvatar size={37} />
      <Grid item xs={8} container justifyContent='flex-start' alignItems='center' onClick={handleRedirectToUser}>
        <Typography variant="h5" textAlign='start' className='capital'>{name}</Typography>
      </Grid>
    </Grid>
  );
};
