import React, { forwardRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import Lock from '../../Assets/password.svg';

const usePasswordTextFieldStyles = makeStyles((theme: Theme) => ({
  formHelperTextError: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  errorText: {
    color: theme.palette.error.main,
  },
  resetPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > span': {
      cursor: 'pointer',
      textAlign: 'end',
    }
  },
}));

interface PasswordTextFieldProps extends StandardTextFieldProps {
  errorText?: string;
  error?: boolean;
  canReset?: boolean;
  startAdornment?: string;
}

const PasswordTextField = forwardRef<HTMLInputElement, PasswordTextFieldProps>(
  ({ canReset, errorText, error, ...other }: PasswordTextFieldProps, ref) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const navigate = useNavigate();
    const passwordTextFieldStyles = usePasswordTextFieldStyles();

    const handleForgetPassword = () => {
      navigate('/lost-password');
    };

    return (
      <FormControl>
        <TextField
          {...other}
          inputRef={ref}
          error={error}
          variant="outlined"
          placeholder="Mot de passe"
          autoComplete="new-password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            startAdornment: (
              <img src={Lock} alt='' />
            ),
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  disabled={other?.disabled}
                  disableFocusRipple
                  color="default"
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {!showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
        <FormHelperText component="div" className={error ? passwordTextFieldStyles.formHelperTextError : ''}>
          {error && <div className={passwordTextFieldStyles.errorText}>{errorText}</div>}
          {canReset && (
            <div className={passwordTextFieldStyles.resetPassword}>
              <span onClick={handleForgetPassword}>Mot de passe oublié ?</span>
            </div>
          )}
        </FormHelperText>
      </FormControl>
    );
  },
);

PasswordTextField.displayName = 'PasswordTextField';

export default PasswordTextField;
