/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useCallback, useState } from "react";
import { TContainer } from "../Types";

interface IReauthContext {
  reauthNeeded: boolean;
  setNeedReauth: () => void;
  setReauthNotNeeded: () => void;
}

export const ReauthContext = createContext<IReauthContext>({
  reauthNeeded: false,
  setNeedReauth: () => {},
  setReauthNotNeeded: () => {}
})

export const ReauthProvider = ({children}: TContainer) => {
  const [needReauth, setReauth] = useState<boolean>(false);

  const contextValue = {
    reauthNeeded: needReauth,
    setNeedReauth: useCallback(() => setReauth(true), []),
    setReauthNotNeeded: useCallback(() => setReauth(false), []),
  };

  return <ReauthContext.Provider value={contextValue}>{children}</ReauthContext.Provider>
}
