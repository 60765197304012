import React, { useMemo } from 'react';
import { useError } from '../../../../Hooks';
import { Popup } from '../../../../Theme';
import { firebaseErrors } from '../../../../Configuration/firebase.config';
import { InformationContent } from '../../../Molecules';

export const ErrorNotifier = () => {
  const { error, removeError } = useError();

  const getErrorDescription = (code: string) => {
    return firebaseErrors[code];
  };

  const popupTitle = useMemo(() => {
    return error?.code === 'account-locked' ? 'Information' : 'Erreur';
  }, [error?.code])

  const popupType = useMemo(() => {
    return error?.code === 'account-locked' ? 'info' : 'error';
  }, [error?.code]);

  const handleDismissError = () => removeError();

  return (
    <Popup open={!!error} maxWidth='xs'>
      <InformationContent
        buttonAction={handleDismissError}
        buttonText="Ok"
        message={error?.code ? getErrorDescription(error.code) : error?.message as string}
        title={popupTitle}
        type={popupType}
      />
    </Popup>
  );
};
