import React from 'react';
import { TUserRole } from '../../../Types';
import { Grid, IconButton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxHeight: '100vh',
    height: '100%',
  },
  content: {
    padding: theme.spacing(3, 4),
    height: '90%',
    overflowY: 'scroll',
  },
  fixedPadding: {
    padding: '1rem',
  },
  list: {
    '& li:not(:first-child)': {
      margin: '2rem 0',
    },
    '& li *': {
      margin: '.5rem 0',
    },
  },
  listHyphen: {
    '& li': {
      listStyleType: '"- "',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  fixedMarginBottom: {
    marginBottom: '2rem',
  },
}));

type TPrivacyPolicyProps = {
  userRole: TUserRole;
}

export const PrivacyPolicy = ({userRole}: TPrivacyPolicyProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.container}>
      <Grid container flexWrap="nowrap" alignItems="center">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
      </Grid>
      <Grid container className={classes.content} gap={3}>
          <>
            <Typography variant="body1" component="div">
            Notre société (ci-après « <strong>T4W</strong> »), attache une importance toute particulière à la protection 
            de vos données personnelles (ci-après « <strong>Données Personnelles</strong> » ou « <strong>Données</strong> »), telles que 
            définies par le Règlement (UE) 2016/679 relatif à la protection des Données Personnelles et 
            par la loi n° 78-17 du 6 janvier 1978 modifiée, dite « Informatique et Libertés » (ci-après 
            dénommés ensemble la « <strong>Règlementation</strong> »).
            <br/>
            <br/>
            La Politique de Confidentialité ci-dessous détaille la politique de T4W en matière de données 
            personnelles pour toutes les activités ayant lieu sur l’application Sam.i (ci-après l’« <strong>Application</strong> »).
            <br/>
            <br/>
            Cette Politique de Confidentialité s’applique à toutes les informations fournies à T4W lors de l’utilisation 
            de l’Application, Elle décrit nos pratiques de collecte, d’utilisation, de conservation, de divulgation 
            et de protection des Données Personnelles.
            <br/>
            <br/>
            En utilisant l’Application, vous consentez au traitement des données dans les conditions prévues dans 
            la présente Politique de confidentialité. 
            <br/>
            <br/>
            Nous vous invitons à lire cette Politique de confidentialité très attentivement ainsi que les différentes 
            notifications qui pourraient vous être communiquées au moment de la collecte afin d’être pleinement informé 
            sur la collecte et sur les utilisations de vos Données Personnelles.
            <br/>
            <br/>
            Les termes commençant par une majuscule et n’étant pas définis au terme du présent document ont le même 
            sens et signification que les termes définis dans la politique générale d’utilisation de T4W accessible 
            au lien suivant [insérer le lien].
            <br/>
            <br/>
            <strong>1. Identification du responsable de traitement</strong>
            <br/>
            <br/>
            En fonction des finalités pour lesquelles les Données sont transmises et du profil avec lequel 
            vous évoluez sur l’Application, T4W agit soit en qualité de responsable de traitement, soit en 
            qualité de sous-traitant des Données Personnelles.
            <br/>
            <br/>
            <strong>1.1 Traitements de données effectués par T4W en qualité de responsable de traitement</strong>
            <br/>
            <br/>
            Lorsque, conformément à la politique générale d’utilisation de T4W, vous êtes Bénéficiaires et 
            que vous créez un compte sur l’Application et que vous l’utilisez pour rechercher et contacter 
            des Consultants ou que vous êtes professionnels non-inscrits sur l’Application, invité par un 
            Bénéficiaire pour créer un compte sur l’Application, le responsable de traitement de toutes vos 
            Données est la société TechForWork dont le siège social est sis 2 rue danton 77330 OZOIR-LA-FERRIERE. 
            <br/>
            <br/>
            Pour plus d’informations sur notre société, veuillez consulter nos Mentions Légales.
            <br/>
            <br/>
            <strong>1.2 Traitements de données effectués par T4W en qualité de sous-traitant</strong>
            <br/>
            <br/>
            Lorsque, conformément à la politique générale d’utilisation de T4W, vous êtes Consultants et que vous 
            utilisez l’Application dans le cadre d’un abonnement souscrit par un organisme dont vous êtes membre ou 
            à titre indépendant pour des fins de suivi de vos Bénéficiaires, échanges avec d’autres Consultants ou 
            lorsque vous échangez avec vos Bénéficiaires, T4W intervient en qualité de sous-traitant des Consultants 
            ou des organismes abonnés auxquels vous appartenez.
            <br/>
            <br/>
            Pour plus d’informations sur les traitements de données personnelles effectués dans le cadre de l’utilisation 
            des Services, l’Utilisateur peut se rapprocher de l’organisme abonné ou d’un Consultant représentant. 
            <br/>
            <br/>
            <strong>2.2 Le délégué à la protection des données (« DPO »)</strong>
            <br/>
            <br/>
            Le délégué à la protection des données de T4W est Alexandra Mazeyrac, Impact Lawyers, 26 avenue George V, 75008 Paris.
            <br/>
            <br/>
            <strong>3. Utilisation des Données Personnelles par T4W</strong>
            <br/>
            <br/>
            <strong>3.1 Exploitation de l’Application</strong>
            <br/>
            <br/>
            Durant votre visite, T4W collecte, enregistre automatiquement et stocke temporairement les informations suivantes 
            relatives à votre ordinateur et votre visite :
            <ul style={{listStyle: 'none'}}>
              <li>- Le domaine et l'hôte depuis lequel vous accédez à Internet</li>
              <li>- L'adresse Internet du site internet à partir duquel vous avez directement accédé à l’Application, le cas échéant</li>
              <li>- La date et l'heure auxquelles vous êtes arrivé sur l’Application et combien de temps vous avez passé sur le site et 
                les pages que vous avez visitées</li>
              <li>- Votre adresse IP (Internet Protocol)</li>
              <li>- Le système d'exploitation et le logiciel de navigation de votre ordinateur</li>
            </ul>
            Ces informations sont utilisées pour vous permettre d’accéder à notre Application et à des fins statistiques. Le 
            traitement s’inscrit donc dans le cadre de l’article 6(1)(f) du Règlement européen sur la protection des données 
            (Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016, « RGPD »), en vertu duquel le 
            traitement est licite s’il correspond à l’intérêt légitime du responsable de traitement.
            <br/>
            <br/>
            <strong>3.2 Inscription à l’Application</strong>
            Pour la création d’un Compte Bénéficiaire suite à l’invitation d’un consultant abonné au service, l’Utilisateur 
            fournit au moment de son inscription :
            <ul style={{listStyle: 'none'}}>
              <li>- son adresse mail,</li>
              <li>- son mot de passe,</li>
              <li>- sa date de naissance,</li>
              <li>- son adresse,</li>
              <li>- son numéro de téléphone,</li>
              <li>- son statut,</li>
              <li>- son type de contrat de travail et le poste occupé,</li>
              <li>- son code APE,</li>
              <li>- son domaine et son niveau de formation,</li>
              <li>- sa langue et son niveau de langue,</li>
              <li>- s'il est bénéficiaire de l'obligation emploi,</li>
              <li>- s'il est en arrêt de travail ; le cas échéant depuis combien de temps et quel type d'arrêt de travail.</li>
            </ul>
            Pour la création d’un Compte Consultant, l’Utilisateur fournit au moment de son inscription :
            <ul style={{listStyle: 'none'}}>
              <li>- une adresse mail,</li>
              <li>- un mot de passe,</li>
              <li>- son nom et prénom,
              <li>- son adresse,</li>
              <li>- son numéro de téléphone,</li>
              <li>- son entreprise/organisme,</li>
              <li>- son poste</li>
              </li>
            </ul>
            Ces Données sont collectées par T4W pour vous permettre de bénéficier des Services accessibles via 
            l’Application. Le traitement s’inscrit donc dans le cadre de l’article 6(1)(b) du RGPD, en vertu duquel 
            le traitement est licite s’il est nécessaire à l’exécution d’un contrat auquel la personne est partie.
            <br/>
            <br/>
            Le Bénéficiaire, en outre, est informé par T4W <strong>que l’inscription à l’Application</strong>, compte tenu des finalités 
            de l’Application et des Services proposés via l’Application, <strong>implique le traitement de données sensibles relatifs 
            à l’état de santé des Bénéficiaires</strong> ce que le Bénéficiaire consent expressément. Si le Bénéficiaire refuse que des 
            données de santé soient traitées par T4W il lui appartiendra de ne pas créer de compte ou de supprimer son compte.
            <br/>
            <br/>
            Le traitement des Données de santé s’inscrit donc dans le cadre de l’article 9.2 (a) du RGPD en vertu duquel le traitement 
            de données de santé est licite s’il est fondé sur le consentement exprès de la personne concernée.
            <br/>
            <br/>
            <strong>3.3 Invitation d’un Consultant par un Bénéficiaire</strong>
            <br/>
            <br/>
            Aux fins de permettre aux Bénéficiaires d’inviter leurs professionnels accompagnant sur l’Application et faciliter 
            ainsi le suivi de leur profils, T4W collecte les données suivantes :
            <ul style={{listStyle: 'none'}}>
              <li>- Adresse mail du consultant</li>
            </ul>
            <br/>
            Ces Données sont traitées par T4W pour lui permettre d’adresser une invitation à suivre le profil d’un Bénéficiaire via 
            l’Application. 
            <br/>
            <br/>
            Le traitement s’inscrit donc dans le cadre de l’article 6(1)(b) du RGPD, en vertu duquel le traitement est 
            licite s’il est nécessaire à l’exécution d’un contrat auquel la personne est partie, dans l’éventualité où 
            le Consultant choisirait de s’inscrire sur l’Application à la suite de l’invitation ou le traitement s’inscrit 
            dans le cadre de l’article 6(1)(f) du RGPD, en vertu duquel le traitement est licite s’il est fondé sur l’intérêt 
            légitime du responsable de traitement ou d’un tiers, dans l’éventualité où le Consultant ne donnerait pas suite à l’invitation.
            <br/>
            <br/>
            <strong>3.4 Création et partage d’une fiche de suivi</strong>
            <br/>
            <br/>
            Le Bénéficiaire dispose d’une fiche de suivi contenant les informations sur sa situation professionnelle 
            qu’il a transmis. La fiche de suivi contient l’ensemble des informations communiquées par le Bénéficiaire 
            lors de son inscription ainsi que les recommandations / actions partagées par son Consultant sur sa situation 
            professionnelle et l’historique des Consultants qu’il a contacté lors de l’utilisation de l’Application. 
            <br/>
            <br/>
            Cette fiche de suivi ne pourra être visualisée par un Consultant, qu’après autorisation exprès du Bénéficiaire. 
            Aucun autre Utilisateur ne peut visualiser ces informations personnelles.
            <br/>
            <br/>
            La fiche de suivi du Bénéficiaire permet à des Consultants indépendants ou salariés d’entreprises 
            et organismes sociaux d’avoir un accès centralisé aux informations relatives à au statut administratif 
            et au parcours professionnel du Bénéficiaire. Les Consultants n'y ont accès que sur invitation ou autorisation 
            du Bénéficiaire. 
            <br/>
            <br/>
            Le Consultant peut, une fois que le Bénéficiaire lui a autorisé l’accès à sa fiche de suivi, ajouter 
            une action à effectuer sur sa fiche de suivi telle que par exemple, un bilan de compétences à effectuer, 
            un essai encadré, une visite de pré-reprise etc.
            <br/>
            <br/>
            Le Consultant peut également ajouter une description détaillée de l’action qu’il enregistre sur la fiche Bénéficiaire.
            <br/>
            <br/>
            La fiche de suivi est susceptible de contenir des données sensibles sur la situation personnelle ou professionnelle 
            d’un Bénéficiaire est notamment de permettre à T4W de connaître l’identité des Consultants suivant le Bénéficiaire via 
            l’Application (donc de savoir indirectement les difficultés susceptibles d’affecter la carrière d’un Bénéficiaire) et 
            les raisons pour lesquelles un Bénéficiaire est en arrêt de travail. 
            <br/>
            <br/>
            Ces Données sont traitées avec le consentement exprès du Bénéficiaire. 
            <br/>
            <br/>
            Le traitement des Données de santé s’inscrit donc dans le cadre de l’article 9.2 (a) du RGPD en vertu duquel le traitement 
            de données de santé est licite s’il est fondé sur le consentement exprès de la personne concernée. 
            <br/>
            <br/>
            <strong>3.5 Gestion des abonnements des organismes abonnés ou des Consultants indépendants </strong>
            <br/>
            <br/>
            Aux fins de permettre la gestion de la relation contractuelle avec ses abonnés organismes ou Consultants 
            indépendants, T4W est susceptible de traiter des données d’identification (nom, prénom, adresse email, organisme 
            de rattachement, profession).
            <br/>
            <br/>
            Ces données sont collectées par T4W aux fins de lui permettre de gérer la relation contractuelle et la facturation 
            des Services. Ce traitement des Données s’inscrit donc dans le cadre de l’article 6(1)(b) du RGPD, en vertu duquel 
            le traitement est licite s’il est nécessaire à l’exécution d’un contrat auquel la personne est partie.
            <br/>
            <br/>
            <strong>4. Destinataire ou catégories de destinataires</strong>
            <br/>
            <br/>
            Vos Données sont destinées à T4W ou aux Consultants auxquels vous consentez expressément l’accès à votre fiche de suivi. 
            Afin de répondre à vos demandes ou aux demandes relatives à nos services, nous pourrons être amenés à partager vos données 
            à nos partenaires de service. Vos données pourront également être communiquées au DPO de T4W et à tous conseils externes et auditeurs.
            <br/>
            <br/>
            Par ailleurs, afin de vous fournir nos services et gérer vos Données, nous pouvons utiliser des tiers, lesquels agissent en qualité de 
            sous-traitants. 
            <br/>
            <br/>
            <strong>5. Lieu du traitement de vos données personnelles</strong>
            <br/>
            <br/>
            T4W traite vos données personnelles sur le territoire de l’Union européenne et ne procède pas à un transfert de vos 
            Données personnelles en dehors de ce territoire. 
            <br/>
            <br/>
            <strong>6. Vos droits</strong>
            <br/>
            <br/>
            Vous disposez d’un droit d’accès, de rectification, de suppression de vos données personnelles, ainsi que d’un 
            droit d’en restreindre le traitement.
            <br/>
            <br/>
            Si le traitement est basé sur l’intérêt légitime de T4W, vous disposez également du droit de vous opposer au traitement.
            <br/>
            <br/>
            Dans la mesure où le traitement de vos données personnelles est basé sur votre consentement, vous avez la possibilité 
            de retirer votre consentement à tout moment, sans que cela n’affecte la licéité des traitements réalisés préalablement 
            au retrait de votre consentement.
            <br/>
            <br/>
            Conformément à l’article 12.5 du RGPD, T4W pourra refuser de donner droit à votre demande si cette dernière est manifestement 
            infondée ou excessive.
            <br/>
            <br/>
            Conformément à l’article 40-1 de la Loi informatique et libertés, vous pouvez définir les directives relatives à la conservation, 
            l’effacement ou la communication de vos données personnelles après votre décès. S’il s’agit de directives générales concernant 
            l’ensemble de vos données personnelles, celles-ci peuvent être enregistrées auprès d’un tiers de confiance numérique certifié par 
            la Commission Nationale de l’Informatique et de Libertés (CNIL). S’il s’agit de directives particulières concernant les données 
            personnelles collectées par T4W, celles-ci sont enregistrées auprès de cette dernière. Vous pouvez modifier ou révoquer vos directives 
            à tout moment.
            <br/>
            <br/>
            Vous disposez d’un droit de formuler une réclamation auprès d’une autorité de contrôle compétente au sens du Règlement européen 
            sur la protection des données et des lois applicables si vous considérez que vos données personnelles sont traitées en contradiction 
            avec le Règlement européen sur la protection des données. Pour cela, vous pouvez contacter l’autorité de contrôle compétente dans 
            l’Etat membre de l’Union Européenne de votre lieu de résidence habituel ou du lieu de l’infraction alléguée.
            <br/>
            <br/>
            Notre autorité de contrôle est la Commission Nationale Informatique et Libertés (CNIL), dont vous trouverez les coordonnées à 
            l’adresse suivante :www.cnil.fr.
            <br/>
            <br/>
            <strong>7. Personne à contacter pour exercer vos droits</strong>
            <br/>
            Pour exercer vos droits, vous pouvez nous contacter aux coordonnées suivantes :
            <br/>
            Par e-mail : contact@tech4work.fr
            <br/>
            <br/>
            Par courrier :
            <br/>
            T4W - 2 rue danton 77330 OZOIR-LA-FERRIERE.
            <br/>
            <br/>
            <strong>8. Durée de conservation de vos données personnelles</strong>
            <br/>
            <br/>
            Les données personnelles collectées par T4W sont conservées pendant la durée de votre inscription 
            sur l’Application ou jusqu’au retrait de votre consentement au traitement de vos Données. 
            <br/>
            A la suppression de votre compte, conformément à la politique générale d’utilisation de T4W, vos 
            Données sont supprimées dans un délai de trente jours. 
            <br/>
            <br/>
            Certaines Données pourront être conservées par voie d’archivage au-delà de cette durée à des fins 
            de preuve conformément aux délais de prescription légale. L’archivage implique que ces Données seront 
            extraites et conservées sur un support distinct et sécurisé.
            <br/>
            <br/>
            <strong>9. Modification des termes de la Politique de confidentialité</strong>
            <br/>
            <br/>
            Nous sommes susceptibles de modifier la présente Politique de Confidentialité. Les nouveaux termes 
            prendront effet dans le mois suivant la publication. Le cas échéant, toute modification importante 
            pourra vous être notifiée par e-mail.
            <br/>
            <br/>
            T4W vous encourage à consulter la Politique de Confidentialité chaque fois que vous utilisez l’Application, 
            afin de rester informé des pratiques mises en place en matière d’information et des moyens que vous pouvez 
            utiliser pour protéger votre vie privée.
            <br/>
            <br/>
            <strong>10. Loi et Juridiction applicable</strong>
            <br/>
            <br/>
            La présente Politique de Confidentialité est régie par la loi française et tout litige à son sujet 
            relèvera de la compétence des Tribunaux territorialement compétents en application des règles édictées 
            par le code de procédure civile français.
            <br/>
            <br/>
            Dernière modification le 23/04/2023
            </Typography>
          </>
      </Grid>
    </Grid>
  );
}
