import React from 'react';
import Typography from '@mui/material/Typography';
import { Header } from '../../../Components';

export const MyActionsHeader = () => {
  return (
    <Header
      title={
        <>
          <Typography variant="h1" component="h1">
            Mes actions
          </Typography>
          <Typography variant="h3" color="GrayText">
            Suivez et gérez les actions de vos bénéficiaires
          </Typography>
        </>
      }
    ></Header>
  );
};
