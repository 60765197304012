import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { FirebaseAuthContext } from "../Services/Auth";
import { Login, Signup } from "../Pages";
import { PrivateRoute } from "./PrivateRoute";
import {
  Dashboard,
  UserInformations,
  MyBeneficiaries,
  Settings,
  MyConsultants,
  MyActions,
  ResetPassword,
} from "../Pages";
import { UserInformationsHeader } from "../Pages/UserInformations/Components";
import { TabActionsConfiguration } from "../Configuration";
import { BeneficiaryInformationsHeader } from "../Components/Organisms/Beneficiary";
import { BeneficiaryJourney } from "../Pages/BeneficiaryJourney";
import { TabActionsComponent } from "../Components";
import { ApplicationTerms } from "../Pages/ApplicationTerms";
import { useError } from "../Hooks";
import { ActionsProvider, ProfileCompletionProvider } from "../Providers";
import { SelectRole } from "../Pages/SelectRole/select-role.component";
import { ConsultantDetail } from "../Pages/MyConsultants/ConsultantDetail/consultant-detail.component";
import { OrganismsTableComponent } from "../Pages/Organisms/Table/organisms-table.component";
import { OrganismDetail } from "../Pages/Organisms/OrganismDetail/organism-detail.component";
import { NewSettings } from "../Pages/AccountSettings/new-settings.component";
import { BeneficiaryDetailComponent } from "../Pages/MyBeneficiaries/BeneficiaryDetail/beneficiary-detail.component";
import { CreateConsultantPopup } from "../Pages/MyConsultants/ConsultantDetail/create-consultant.component";
import { CreateOrganismPopup } from "../Pages/Organisms/CreateOrganism/create-organism.component";
import { useStateMachine } from "little-state-machine";

export const AppRoutes = () => {
  const { addError } = useError();
  const {
    state: {
      user: { beneficiary },
    },
  } = useStateMachine();

  return (
    <FirebaseAuthContext addError={addError}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Navigate to="/select-role" />} />
          <Route path="/*" element={<Navigate to="/select-role" />} />
          <Route path="select-role" element={<SelectRole />}></Route>
          <Route path="login/:type" element={<Login />}></Route>
          <Route path="signup/:type" element={<Signup />}></Route>
          <Route path="lost-password" element={<ResetPassword />}></Route>
          <Route
            path="organism"
            element={<PrivateRoute redirectTo="/login/organism" />}
          >
            <Route path="consultants" element={<MyConsultants />} />
            <Route
              path="account-settings"
              element={<NewSettings role="organism" />}
            />
          </Route>
          <Route
            path="admin"
            element={<PrivateRoute redirectTo="/login/admin" />}
          >
            <Route path="consultants" element={<MyConsultants />} />
            <Route path="consultants">
              <Route path="create" element={<CreateConsultantPopup />} />
              <Route path=":consultantId" element={<ConsultantDetail />} />
            </Route>
            <Route path="organisms" element={<OrganismsTableComponent />} />
            <Route path="organisms">
              <Route path=":organismId" element={<OrganismDetail />}>
                <Route path="edit" element={<CreateOrganismPopup />} />
              </Route>
              <Route path="create" element={<CreateOrganismPopup />} />
            </Route>
            <Route
              path="account-settings"
              element={<NewSettings role="admin" />}
            />
            <Route path="beneficiaries" element={<MyBeneficiaries />} />
            <Route path="beneficiaries">
              <Route
                path=":beneficiaryId"
                element={<BeneficiaryDetailComponent />}
              />
            </Route>
          </Route>
          <Route
            path="beneficiary"
            element={
              <PrivateRoute redirectTo="/login/beneficiary"></PrivateRoute>
            }
          >
            <Route
              path="dashboard"
              element={
                <ProfileCompletionProvider>
                  <Dashboard userRole="beneficiary" />
                </ProfileCompletionProvider>
              }
            />
            <Route
              path="account-settings"
              element={<Settings userRole="beneficiary" />}
            />
            <Route
              path="journey"
              element={
                <UserInformations
                  fetchProfile
                  content={
                    <ActionsProvider
                      uid={beneficiary?.id ? (beneficiary?.id as string) : ""}
                      userRole={"beneficiary"}
                    >
                      <BeneficiaryJourney mode="journey-read" />
                    </ActionsProvider>
                  }
                  header={<BeneficiaryInformationsHeader mode="journey-read" />}
                />
              }
            />
            <Route path="my-consultants" element={<MyConsultants />} />
            <Route
              path="my-informations"
              element={
                // <ProfileCompletionProvider>
                <UserInformations
                  fetchProfile
                  content={
                    <TabActionsComponent
                      actions={
                        TabActionsConfiguration.UserInformations.tabActions
                      }
                    />
                  }
                  header={<UserInformationsHeader beneficiary={beneficiary} />}
                />
                // </ProfileCompletionProvider>
              }
            />
            <Route path="*" element={<Navigate to="dashboard" />} />
          </Route>
          <Route
            path="consultant"
            element={
              <PrivateRoute redirectTo="/login/consultant"></PrivateRoute>
            }
          >
            <Route index element={<Dashboard userRole="consultant" />} />
            <Route
              path="dashboard"
              element={<Dashboard userRole="consultant" />}
            />
            <Route
              path="account-settings"
              element={<Settings userRole="consultant" />}
            />
            <Route path="my-beneficiaries">
              <Route index element={<MyBeneficiaries />} />
              <Route path="all" element={<MyBeneficiaries />} />
              <Route
                path="informations"
                element={
                  <UserInformations
                    fetchProfile
                    content={
                      <TabActionsComponent
                        actions={
                          TabActionsConfiguration.MyBeneficiaryUserInformation
                            .tabActions
                        }
                      />
                    }
                    header={
                      <BeneficiaryInformationsHeader mode="informations" />
                    }
                  />
                }
              />
              <Route
                path="journey"
                element={
                  <UserInformations
                    fetchProfile
                    content={
                      <ActionsProvider uid={""} userRole={"beneficiary"}>
                        <BeneficiaryJourney mode="journey" />
                      </ActionsProvider>
                    }
                    header={<BeneficiaryInformationsHeader mode="journey" />}
                  />
                }
              />
            </Route>
            <Route path="my-actions" element={<MyActions />} />
            <Route path="*" element={<Navigate to="dashboard" />} />
          </Route>
          <Route path="terms/terms-of-service" element={<ApplicationTerms />} />
          <Route path="terms/privacy-policy" element={<ApplicationTerms />} />
        </Routes>
      </BrowserRouter>
    </FirebaseAuthContext>
  );
};
