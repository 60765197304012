import React from 'react';
import Grid, { GridSize } from '@mui/material/Grid';
import { TContainer } from '../../Types';

type TFloatingContainer = TContainer & {
  xs: boolean | GridSize;
  md: boolean | GridSize;
}

export const FloatingContainer = ({children, xs, md}: TFloatingContainer) => {
  return <Grid item container gap={10} maxWidth={1100} xs={xs} md={md} justifyContent="stretch" alignSelf='flex-start'>
    {children}
  </Grid>
}
