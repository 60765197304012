import * as Yup from 'yup';

export const personalInformations = Yup.object().shape({
  //TODO implement custom date parser to check it's ok
  gender: Yup.string().required().typeError('Ce champ est obligatoire'),
  firstName: Yup.string().typeError('Ce champ ne doit contenir que des lettres').notRequired().nullable(),
  lastName: Yup.string().typeError('Ce champ ne doit contenir que des lettres').notRequired().nullable(),
  birthday: Yup.string()
    .notRequired()
    .nullable(),
    // .when('$isConsultant', {
    //   is: true,
    //   then: (schema) => schema.notRequired().nullable().nullable(),
    // }),
  address: Yup.string().typeError('Ce champ ne doit contenir que des lettres').default('').notRequired().nullable(),
  postcode: Yup.string().typeError('Ce champ ne doit contenir que des lettres').default('').notRequired().nullable(),
  city: Yup.string().typeError('Ce champ ne doit contenir que des lettres').default('').notRequired().nullable(),
  phoneNumber: Yup.string().notRequired().nullable(),
});

export const professionalInformations = Yup.object().shape({
  ape: Yup.string().typeError('Ce champ ne doit contenir que des lettres').notRequired().nullable(),
  contract: Yup.string().typeError('Ce champ ne doit contenir que des lettres').notRequired().nullable(),
  job: Yup.string().typeError('Ce champ ne doit contenir que des lettres').notRequired().nullable(),
  language: Yup.string().typeError('Ce champ ne doit contenir que des lettres').notRequired().nullable(),
  languageLevel: Yup.string().typeError('Ce champ ne doit contenir que des lettres').notRequired().nullable(),
  levelSchool: Yup.string().typeError('Ce champ ne doit contenir que des lettres').notRequired().nullable(),
  postOccupied: Yup.string().typeError('Ce champ ne doit contenir que des lettres').notRequired().nullable(),
  status: Yup.string().typeError('Ce champ ne doit contenir que des lettres').notRequired().nullable(),
  departmentLocation: Yup.string().typeError('Ce champ ne doit contenir que des lettres').notRequired().nullable(),
  formation: Yup.string().typeError('Ce champ ne doit contenir que des lettres').notRequired().nullable(),
});

export const actualSituation = Yup.object().shape({
  isWorkArrest: Yup.boolean(),
  dateWorkArrest: Yup.string().when('isWorkArrest', {
    is: true,
    then: Yup.string().required('Ce champ est requis'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  typeWorkArrest: Yup.string().when('isWorkArrest', {
    is: true,
    then: Yup.string().required('Ce champ est requis'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  isBOE: Yup.boolean(),
  statusBOE: Yup.string().when('isBOE', {
    is: true,
    then: Yup.string().required('Ce champ est requis'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
});
