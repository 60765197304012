import {
  AnySchema,
  array,
  mixed,
  number,
  object,
  string,
  ValidationError,
} from "yup";

export interface FieldErrors {
  [field: string]: string;
}

export const fieldSchemas: { [key: string]: AnySchema } = {
  requiredString: string().required("Ce champ est obligatoire"),
  stringNotRequired: string().nullable(),

  requiredPositiveNumber: number()
    .typeError("Un nombre positif est attendu")
    .required("Ce champ est obligatoire"),
  requiredNotEmptyArray: array()
    .required("Ce champ est obligatoire")
    .min(1, "Ce champ est obligatoire"),
  logoFile: mixed().when("logo", {
    is: (logo: string) => !logo,
    then: string().required("Ce champ est obligatoire"),
  }),
  email: string()
    .required("Ce champ est obligatoire")
    .email("Adresse mail invalide"),
  phoneNumber: string()
    .required("Ce champ est obligatoire")
    .matches(
      /^[+][(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      "Numéro de téléphone invalide"
    ),
  emailNotRequired: string().email("Adresse mail invalide"),
  password: string().required("Ce champ est obligatoire"),
};

export const transformError = (err: ValidationError): FieldErrors =>
  err.inner.reduce((formError, innerError) => {
    const oldError = formError[innerError.path!];

    return {
      ...formError,
      [innerError.path!]: oldError || innerError.message,
    };
  }, {} as FieldErrors);

export const fieldValidation = (fieldSchema: string, value: string): string => {
  try {
    fieldSchemas[fieldSchema].validateSync(value, { abortEarly: false });
    return "";
  } catch (err) {
    return (err as ValidationError).message;
  }
};