import { useCallback, useContext } from 'react';
import { PopupContext, TPopupMode } from '../../Providers';

export const usePopup = (popupId: string) => {
  const { handleOpen, handleClose, getPopupMode, isOpen } = useContext(PopupContext);

  const openPopup = useCallback((mode?: TPopupMode, action?: string) => {
    handleOpen(popupId, mode, action);
  }, [handleOpen, popupId])

  const closePopup = useCallback(() => {
    handleClose(popupId);
  }, [handleClose, popupId])

  const popupMode = useCallback(() => getPopupMode(popupId) as TPopupMode, [getPopupMode, popupId]);

  const popupOpen = useCallback(() => isOpen(popupId), [isOpen, popupId])

  return { openPopup, closePopup, popupMode, popupOpen };
};
