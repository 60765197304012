import React , {useEffect, useState, useCallback} from 'react';
import { usePopup } from '../../../../Hooks';
import { Box, Button, Grid, TextField, Typography, useTheme } from '@mui/material';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import { useStateMachine } from 'little-state-machine';
import APIAxios, { APIRoutes } from '../../../../API/api.axios';
import { resetUserState, resetLoginState } from '../../../../Stores';
import { useSnackbar } from 'notistack';

export const DetetAccountPopup = () => {
  const { closePopup } = usePopup('DeleteAccount');
  const { enqueueSnackbar } = useSnackbar();
  
  const { state: { user: { consultant, beneficiary, organism }}, actions} = useStateMachine({resetUserState, resetLoginState});
  const theme = useTheme();
  const [email, setEmail] = useState<string>('');
  const [isMailValid, setMailValid] = useState<boolean>(false);
  const vEmail = consultant?.email || beneficiary?.email || organism?.email;

  useEffect(() => {
    setMailValid(vEmail === email);
  }, [email, vEmail]);

  const handleDeleteAccount = useCallback(
    async () => {
      try {
        await APIAxios({...APIRoutes.DELETEAccount()});
        closePopup();
        localStorage.removeItem('accessToken');
        actions.resetUserState();
        actions.resetLoginState();
      } catch (error) {
        enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" });
      }
    }, [actions, closePopup, enqueueSnackbar]
  )

  return (
    <Grid
      maxWidth={300}
      width="auto"
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap={theme.spacing(4)}
      margin={theme.spacing(4)}
      textAlign="center"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '120px',
          height: '120px',
          borderRadius: '50%',
          backgroundColor: (theme) => theme.secondaryPalette.lightRed,
        }}
      >
        <PriorityHighRoundedIcon color="error" style={{ fontSize: '60px' }} />
      </Box>
      <Typography variant="h2">Vous êtes sur le point de supprimer votre compte</Typography>
      <Typography variant="body1">
        Cette action est irréversible. Si vous êtes sûr, renseignez votre email dans le champ ci-dessous, puis validez.
      </Typography>
      <TextField
        fullWidth
        placeholder="email"
        label="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button variant="contained" color="error" fullWidth disabled={!isMailValid} onClick={handleDeleteAccount}>
        Valider
      </Button>
    </Grid>
  );
}
