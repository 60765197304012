import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useError } from '../../../Hooks';
import { useReauth } from '../../../Hooks/Auth';
import { LoginForm } from '../../../Pages/Login/login.form';
import { reauthUserWithCredentials } from '../../../Services/Auth/auth.service';
import { TPasswordCredentials } from '../../../Types';

export const ReauthUser = () => {
  const { addError } = useError();
  const { setReauthNotNeeded } = useReauth();
  const onSubmit = async (data: TPasswordCredentials) => {
    try {
      // reauth user here
      const success = await reauthUserWithCredentials(data);
      if (success) {
        setReauthNotNeeded();
      }
    } catch (error) {
      addError(error);
    }
  };

  return (
    <Grid container direction="column" gap={2}>
      <Typography variant="subtitle1" color="#FF6575">
        Vous devez vous réauthentifier afin de pouvoir effectuer cette opération
      </Typography>
      <LoginForm reset={false} reauth submitForm={onSubmit} />
    </Grid>
  );
};
