import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const MyActionDescription = ({ description }: { description: string }) => {
  return (
    <Grid item container height={60} alignItems="center">
      <Typography
        style={{
          maxWidth: '100%',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 4,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'start',
        }}
        variant="subtitle2"
        component="div"
      >
        {description}
      </Typography>
    </Grid>
  );
};
