import React from 'react';
import { Stack, Typography } from '@mui/material';

interface CardProps {
  children: JSX.Element | JSX.Element[];
  title?: string;
  action?: JSX.Element | JSX.Element[];
}

export const SimpleCard = ({children, title, action}: CardProps) => {
  return (
    <Stack padding={{xs: 1, sm: 2}} sx={{backgroundColor: 'white', borderRadius: '8px'}} gap={2}>
      {title && (
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography sx={{fontWeight: '600', color: 'black', paddingBottom: '0.7rem'}}>{title}</Typography>
          {action && action}
        </Stack>
      )}
      {children}
    </Stack>
  )
}