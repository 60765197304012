import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, Theme } from '@mui/material';
import { GridColDef, GridCellParams, GridRenderCellParams, DataGrid } from '@mui/x-data-grid';
import { ProfileAvatar } from '../../Components';
import { GetBeneficiaryLightDto, GetBeneficiaryListDto, StatusBoe } from '../../Types';
import { ClickableDatatableCell } from './Components';
import { useSnackbar } from 'notistack';
import { useDatatableSearch } from '../../Hooks';
import { makeStyles } from '@mui/styles';
import APIAxios, { APIRoutes } from '../../API/api.axios';
import { CustomPagination, CustomPagination2 } from '../../Theme';
import { useParams } from 'react-router-dom';
import { UserLightDto } from '../../Types/actions.type';

type BeneficiaryRow = {
  id: string;
  firstName: string;
  lastName: string;
  consultants?: string;
  age?: string;
  statusBOE?: string;
  dateWorkArrest?: string;
  isDeleted?: string;
}

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Nom / prénom',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography fontSize="10px">Nom / Prénom</Typography>,
    renderCell: (params: GridCellParams) => (
      <Grid container gap={2} flexWrap='nowrap' alignItems='center'>
        <ProfileAvatar size={37} />
        <Typography fontSize='12px' fontWeight='500' whiteSpace='normal' textAlign='start'>
          {/* {params.row.firstName + ' ' + params.row.lastName} */}
          {params.value}
        </Typography>
      </Grid>
    ),
  },
  {
    field: 'statusBOE',
    headerName: 'Situation',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => <Typography fontSize="10px">Situation</Typography>,
    renderCell: (params: GridCellParams) => (
      <Typography fontSize="12px">
        {
          (params.row.statusBOE ? (StatusBoe.label(params.row.statusBOE) + ' / ') : '') +
          (params.row.isBOE ? 'BOE' : 'Non BOE') + 
          (params.row.isWorkArrest ? ' / Arret Maladie' : '')
        }
      </Typography>
    ),
  },
  {
    field: 'consultants',
    headerName: "Consultant(s)",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    maxWidth: 350,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => <Typography fontSize="10px">Consultant(s)</Typography>,
    renderCell: (params: GridCellParams) => 
      <Typography 
      fontSize='12px'
      sx={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}>{params.value}</Typography>,
  },
  {
    field: 'isSuspended',
    headerName: 'Statut',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    align: 'left',
    headerAlign: 'left',
    renderCell: (params: GridCellParams) => 
    <Typography
      fontSize='10px'
      fontWeight='500'
      style={{
        backgroundColor: (params.value || params.row.isDeleted) ? 'rgb(255, 238, 238)' : 'rgb(240, 255, 238)',
        color: (params.value || params.row.isDeleted) ? 'rgb(192, 74, 74)' : 'rgb(128, 193, 131)',
        borderRadius: '6px',
        padding: '6px'}}>
      {params.value ? 'Clôturé' : params.row.isDeleted ? 'Supprimé' : 'Actif'}
    </Typography>
  },
  {
    field: 'info',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams) => params.row.isDeleted ? 
      undefined : 
      <ClickableDatatableCell action='beneficiary-detail' {...params} />,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  disabledRow: {
    color: theme.palette.grey[500]
  },
}));

interface NewBenefDataTableProps {
  organismId?: string;
}

export const NewBenefDataTable = (props: NewBenefDataTableProps) => {
  const { organismId } = props;
  const { search } = useDatatableSearch();
  const { enqueueSnackbar } = useSnackbar();
  const { consultantId } = useParams();

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [data, setData] = useState<GetBeneficiaryListDto | undefined>(undefined);
  const [rowData, setRowData] = useState<BeneficiaryRow[] | undefined>(undefined);

  const getBeneficiaries = useCallback(async () => {
    try {
      const res = await APIAxios({...APIRoutes.GETBeneficiaries(page + 1, search, consultantId, organismId)})
      if (res.data as GetBeneficiaryListDto) {
        const newData = res.data.data.map((it: GetBeneficiaryLightDto) => {
          var consultants = ""
          it.consultants.forEach((it: UserLightDto, index) => {
            consultants = consultants.concat((index !== 0 ? ', ' : '') + it.firstName + ' ' + it.lastName);
          })
          return {
            ...it,
            consultants: consultants,
            name: it.firstName + ' ' + it.lastName
          }
      });
        setData(res.data);
        setRowData(newData);
      } else {
        enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.")
      }
    } catch (err) {
      enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.")
    }
  }, [consultantId, enqueueSnackbar, organismId, page, search]);

  useEffect(() => {
    getBeneficiaries();

  }, [getBeneficiaries, organismId]);

  if (rowData === undefined || data === undefined) return <div>Chargement ...</div>;
  if (!data.data.length) return <div>Pas de bénéficiaires pour le moment.</div>;

  return (
    <DataGrid
      getRowClassName={(params) => {
        return params.row.isDeleted ? classes.disabledRow : ''
      }}
      rows={rowData}
      columns={columns}
      onPageChange={setPage}
      pageSize={data.meta.take}
      autoHeight
      disableSelectionOnClick
      density='standard'
      rowCount={data.meta.itemCount}
      paginationMode='server'
      components={{
        Pagination: CustomPagination
      }}
      sx={{
        '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
          visibility: 'hidden',
        },
        '&.MuiDataGrid-root': {
          border: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-virtualScroller': {
          marginTop: '32px !important',
          backgroundColor: 'white !important',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
          backgroundColor: 'white !important',
          height: '35px !important',
          maxHeight: '35px !important',
          minHeight: '35px !important',
          fontSize: '10px',
          fontWeight: '500',
        },
        '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
        },
        '.& MuiDataGrid-columnHeaders': {
          fontSize: '10px',
          fontWeight: '500',
        },
        '&.MuiDataGrid-root *:focus-within': {
          outline: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
          overflow: 'hidden',
          whiteSpace: 'normal',
          color: 'rgb(155, 159, 164)',
          footWeight: '500',
          display: 'flex',
          justifyContent: 'start',
          fontSize: '10px',
          textAlign: 'start',
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'start',
          whiteSpace: 'normal',
          textAlign: 'start',
          borderTop: '1px solid rgb(233, 233, 233)',
          borderBottom: '0px'
        },
      }}
     />
  )
}