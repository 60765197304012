import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useUserProfile } from '../../../Hooks';
import { BeneficiaryProfileContext } from '../../../Providers';
import { BeneficiaryStatus, ContractEnum, LanguageLevel, LevelSchool } from '../../../Types';

export const BeneficiaryProfesionalInformations = () => {
  const { user: beneficiaryProfile } = useUserProfile(BeneficiaryProfileContext);

  return (
    <Grid container gap={4}>
      <Typography variant="h3">Informations professionnelles</Typography>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Statut
          </Typography>
        </Grid>
        <Typography variant="body1">{beneficiaryProfile?.beneficiary?.status ? BeneficiaryStatus.label(beneficiaryProfile?.beneficiary?.status) : '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Poste occupé
          </Typography>
        </Grid>
        <Typography variant="body1">{beneficiaryProfile?.beneficiary?.job || '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Code APE
          </Typography>
        </Grid>
        <Typography variant="body1">{beneficiaryProfile?.beneficiary?.ape || '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Type de contrat
          </Typography>
        </Grid>
        <Typography variant="body1">{beneficiaryProfile?.beneficiary?.contract ? ContractEnum.label(beneficiaryProfile?.beneficiary?.contract) : '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Département lieu de travail
          </Typography>
        </Grid>
        <Typography variant="body1">{beneficiaryProfile?.beneficiary?.departmentLocation || '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Formation
          </Typography>
        </Grid>
        <Typography variant="body1">{beneficiaryProfile?.beneficiary?.job || '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Niveau scolaire
          </Typography>
        </Grid>
        <Typography variant="body1">{beneficiaryProfile?.beneficiary?.levelSchool ? LevelSchool.label(beneficiaryProfile?.beneficiary?.levelSchool) : '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Langue
          </Typography>
        </Grid>
        <Typography variant="body1">{beneficiaryProfile?.beneficiary?.language || '-'}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="gray">
            Niveau de langue
          </Typography>
        </Grid>
        <Typography variant="body1">{beneficiaryProfile?.beneficiary?.languageLevel ? LanguageLevel.label(beneficiaryProfile?.beneficiary?.languageLevel) : '-'}</Typography>
      </Grid>
    </Grid>
  );
};
