import * as Yup from 'yup';

export const codeVerificationValidation = Yup.object().shape({
  email: Yup.string().required('Ce champ est requis').email("Format de l'email incorrecte").default(''),
  invitationCode: Yup.string().required('Ce champ est requis').default(''),
});

export const invitedCredentialsValidation = Yup.object().shape({
  email: Yup.string()
    .required('Ce champ est requis')
    .email("Format de l'email incorrecte")
    .default(''),
  firstName: Yup.string()
    .required('Ce champ est requis')
    .default(''),
  lastName: Yup.string()
    .required('Ce champ est requis')
    .default(''),
  password: Yup.string()
    .required('Ce champ est requis')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, 'Le mot de passe doit contenir au moins huit caractères, une lettre, un numéro et un caractère spécial.')
    .default(''),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Les mots de passes doivent être identiques'),
});
