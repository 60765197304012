import React from 'react';
import {
  ActualSituationForm,
  PersonalInformationsForm,
  ProInformationsForm
} from '../Components/Organisms/Forms';
import {
  BeneficiaryActualSituation,
  BeneficiaryPersonalInformations,
  BeneficiaryProfesionalInformations
} from '../Components/Organisms/Beneficiary';
import { TScreenTabAction } from '../Types';




export namespace TabActionsConfiguration {
  export namespace UserInformations {
    export const tabActions: TScreenTabAction[] = [
      {
        title: 'Informations personnelles',
        value: 'personal',
        component: <PersonalInformationsForm />,
      },
      {
        title: 'Informations professionnelles',
        value: 'profesional',
        component: <ProInformationsForm />,
      },
      {
        title: 'Situation actuelle',
        value: 'actual',
        component: <ActualSituationForm />,
      },
    ];
  }

  export namespace MyBeneficiaryUserInformation {
    export const tabActions: TScreenTabAction[] = [
      {
        title: 'Informations personnelles',
        value: 'personal',
        component: <BeneficiaryPersonalInformations />,
      },
      {
        title: 'Informations professionnelles',
        value: 'profesional',
        component: <BeneficiaryProfesionalInformations />,
      },
      {
        title: 'Situation actuelle',
        value: 'actual',
        component: <BeneficiaryActualSituation />,
      },
    ];
  }
}
