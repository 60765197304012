import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  UserInvitationFormPopup,
  ProfileAvatar,
  NewDataTableHeader
} from '../../Components';
import { Popup } from '../../Theme';
import { ConsultantsHeader } from './Components';
import { useDatatableSearch, usePopup } from '../../Hooks';
import { Grid, Pagination, Typography, useMediaQuery, Theme, useTheme, Stack } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Consultants } from '../../Components';
import { ConsultantListDto, GetOrganismAdminDto, PostOccupiedEnum } from '../../Types';
import APIAxios, { APIRoutes } from '../../API/api.axios';
import { useSnackbar } from 'notistack';
import { useStateMachine } from 'little-state-machine';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { consultantDataTableService, ConsultantRow } from "../../Services/DataTable/Consultants"
import { CustomPagination } from '../../Theme';
import { DatatableSearchProvider } from '../../Providers';
import { OrganismConsultantsHeader } from './Components/header.my-consultants-organism';
import { ClickableDatatableCell } from '../MyBeneficiaries/Components';
import { FilterPopup } from '../../Components/Molecules/filter-popup.molecule';
import { SelectOption } from '../../Components/Molecules/organism-search.molecule';


const columns: GridColDef[] = [
  {
    field: "name",
    headerName: 'Nom / Prénom',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography fontSize="10px">Nom / Prénom</Typography>,
    renderCell: (params: GridCellParams) => (
      <Grid container gap={2} flexWrap='nowrap' alignItems='center'>
        <ProfileAvatar size={25} />
        <Typography 
          fontSize='12px'
          fontWeight='500'
          className="capital" 
          whiteSpace='normal' 
          textAlign='start'>
          {params.value}
        </Typography>
      </Grid>
    ),
  },
  {
    field: 'organism',
    headerName: 'Organisme',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => <NewDataTableHeader headerName="Organisme" />,
    renderCell: (params: GridCellParams) => 
    <Typography fontSize='12px' fontWeight='400'>{params.value ?? '-'}</Typography>,
  },
  {
    field: 'postOccupied',
    headerName: 'Poste',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => <NewDataTableHeader headerName="Poste" />,
    renderCell: (params: GridCellParams) => 
    <Typography fontSize='12px' fontWeight='400'>{params.value ? PostOccupiedEnum.label(params.value) : '-'}</Typography>,
  },
  {
    field: 'info',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => <NewDataTableHeader headerName="" />,
    renderCell: (params: GridRenderCellParams) => 
    <ClickableDatatableCell action="consultant-read" {...params} />
  },
];

const organismColumns: GridColDef[] = [
  {
    field: "name",
    headerName: 'Nom / Prénom',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    headerAlign: 'left',
    align: 'left',
    renderHeader: () => <Typography fontSize="10px">Nom / Prénom</Typography>,
    renderCell: (params: GridCellParams) => (
      <Grid container gap={2} flexWrap='nowrap' alignItems='center'>
        <ProfileAvatar size={25} />
        <Typography 
          fontSize='12px'
          fontWeight='500'
          className="capital" 
          whiteSpace='normal' 
          textAlign='start'>
          {params.value}
        </Typography>
      </Grid>
    ),
  },
  {
    field: 'organism',
    headerName: 'Organisme',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => <NewDataTableHeader headerName="Organisme" />,
    renderCell: (params: GridCellParams) => 
    <Typography fontSize='12px' fontWeight='400'>{params.value ?? '-'}</Typography>,
  },
  {
    field: 'postOccupied',
    headerName: 'Poste',
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    align: 'center',
    headerAlign: 'center',
    renderHeader: () => <NewDataTableHeader headerName="Poste" />,
    renderCell: (params: GridCellParams) => 
    <Typography fontSize='12px' fontWeight='400' >{params.value ? PostOccupiedEnum.label(params.value) : '-'}</Typography>,
  },
];
// const useStyles = makeStyles((theme: Theme) => ({
//   disabledRow: {
//     color: theme.palette.grey[500]
//   },
// }));

const useStyles = makeStyles((theme: Theme) => ({
  disabledRow: {
    color: theme.palette.grey[500]
  },
}));

interface ConsultantsDataTableProps {
  organismId?: string;
}

export const ConsultantsDataTable = (props: ConsultantsDataTableProps) => {

  const { state: { user: { organism }}} = useStateMachine();
  const { organismId } = props;
  const classes = useStyles();

  const { search } = useDatatableSearch();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState<ConsultantRow[] | undefined>(undefined);
  const [page, setPage] = useState(0);

  const [beneficiaries, setBeneficiaries] = useState<ConsultantListDto | undefined>(undefined);

  const getConsultants = useCallback(async () => {
    try {
      const res = await APIAxios({...APIRoutes.GETConsultants(page + 1, search, organismId)})
      if (res.data as ConsultantListDto) {
        setBeneficiaries(res.data);
        const data = consultantDataTableService.buildRows(res.data.data);
        setData(data);
      } else {
        enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.")
      }
    } catch (err) {
      enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.")
    }
  }, [enqueueSnackbar, organismId, page, search]);

  useEffect(() => {
    getConsultants();
  }, [getConsultants]);

  if (data === undefined) return <div>Chargement ...</div>;

  if (!data.length) return (
    <>
      <Typography variant="h3">Il semble que vous n&apos;aillez pas de consultants.</Typography>
    </>
  );

  return (
    <DataGrid
      getRowClassName={(params) => {
        return params.row.isDeleted ? classes.disabledRow : ''
      }}
      rows={data}
      columns={organism ? organismColumns : columns}
      onPageChange={setPage}
      pageSize={beneficiaries?.meta.take}
      filterModel={{
        items: [
          { columnField: 'name', operatorValue: 'contains', value: search },
        ],
      }}
      autoHeight
      paginationMode='server'
      disableSelectionOnClick
      density='standard'
      sx={{
        '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
          visibility: 'hidden',
        },
        '&.MuiDataGrid-root': {
          border: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-virtualScroller': {
          marginTop: '32px !important',
          backgroundColor: 'white !important',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
          backgroundColor: 'white !important',
          height: '35px !important',
          maxHeight: '35px !important',
          minHeight: '35px !important',
        },
        '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
          border: '0px solid transparent'
        },
        '&.MuiDataGrid-root *:focus-within': {
          outline: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
          overflow: 'hidden',
          whiteSpace: 'normal',
          color: 'rgb(155, 159, 164)',
          footWeight: '500',
          display: 'flex',
          justifyContent: 'start',
          fontSize: '10px',
          textAlign: 'start',
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'start',
          whiteSpace: 'normal',
          textAlign: 'start',
          borderTop: '1px solid rgb(233, 233, 233)',
          borderBottom: '0px'
        },
      }}
      rowCount={beneficiaries?.meta.itemCount}
      components={{
        Pagination: CustomPagination
      }}
    />
  );
};


export const MyConsultants = () => {
  const { state: { user: { beneficiary, admin }}} = useStateMachine();
  const { popupOpen, closePopup } = usePopup('CreateInvitationPopup');
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [consultants, setConsultants] = useState<ConsultantListDto | undefined>(undefined);
  const [page, setPage] = useState(1);
  const [selectedOrganism, setSelectedOrganism] = useState<GetOrganismAdminDto | undefined>();
  const [filterPopupOpen, setFilterPopUpOpen] = useState(false);

  const getConsultants = useCallback(
   async () => {
    try {
      const res = await APIAxios({ ...APIRoutes.GETConsultants(page, undefined, selectedOrganism?.id)})
      if (res.data as ConsultantListDto) {
        setConsultants(res.data);
      } else {
        enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.");
      }
    } catch (err) {
      enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
  }, [enqueueSnackbar, page, selectedOrganism]);

  const getOrganisms = async (q?: string): Promise<SelectOption[]> => {
    return await APIAxios({...APIRoutes.GETOrganisms(1, q, 50)})
    .then((res) => {
      if (res.data) {
        return res.data.data.map((it: GetOrganismAdminDto) => ({ label: it.name, value: it.id, data: it }));
      }
    })
  }

  useEffect(() => {
    getConsultants();
  }, [getConsultants, selectedOrganism]);


  return (
    <>
      <Popup open={popupOpen()} maxWidth="md" onClose={closePopup} transitionDuration={0} fullScreen={fullscreen}>
        <UserInvitationFormPopup />
      </Popup>
      {filterPopupOpen && (
        <FilterPopup 
          title='Rechercher un organisme' 
          placeholder="Nom de l'organisme"
          handleSelect={(value) => {
            setSelectedOrganism(value ? value.data : undefined);
            setFilterPopUpOpen(false);
          }}
          handleClose={() => setFilterPopUpOpen(false)}
          getOptions={getOrganisms}
          selectedOption={selectedOrganism && 
            ({ value: selectedOrganism.id, data: selectedOrganism, label: selectedOrganism.name })}
        />
      )}
      <ResponsiveContainer>
        {beneficiary ? (
          <>
            <ConsultantsHeader />
            <Grid container gap={4} sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}>
                {consultants?.data.length ? (
                  consultants?.data.map((cid, index) => (
                    <Fragment key={index}>
                        <Consultants user={cid} />
                    </Fragment>
                  ))
                ) : (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="h3">Aucun consultant ne suit votre parcours.</Typography>
                    </Grid>
                    <Typography variant="subtitle1">Vous pouvez en inviter en cliquant sur le bouton &apos;Inviter un consultant&apos; ci-dessus.</Typography>
                  </Grid>
                )}
              </Grid>
              {(consultants?.data.length || 0) > 0 && (
                <Pagination
                color='primary'
                shape='rounded'
                size="large"
                count={consultants?.meta.pageCount}
                page={consultants?.meta.pageCount}
                onChange={(e, value) => setPage(value)} />
              )}
            </> 
          ) : (
            <>
            <DatatableSearchProvider>
              <OrganismConsultantsHeader handleClickFilter={() => setFilterPopUpOpen(true)} selectedOrganism={selectedOrganism?.name} isAdmin={!!admin}  />
              <Stack height='100%' width='100%'>
                <ConsultantsDataTable organismId={selectedOrganism?.id} />
              </Stack>
            </DatatableSearchProvider>
          <Outlet></Outlet>
          </>
        )}
      </ResponsiveContainer>
    </>
  );
};
