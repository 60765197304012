import React from 'react';
import { Grid, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import SuccessImage from '../../Assets/success.svg';
import { usePopup } from '../../Hooks';

type TSuccessProps = {
  title: string;
  message?: string;
  popupId: string;
  closeHandler?: () => void;
}

export const Success = ({title, message, popupId, closeHandler}: TSuccessProps) => {
  const { closePopup } = usePopup(popupId);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container padding={isSm ? theme.spacing(2) : theme.spacing(6)} justifyContent="center" maxWidth={600}>
      {/* content */}
      <Grid container direction="column" alignContent="center" justifyContent="center" gap={2}>
        <Grid item container justifyContent="center">
          <img src={SuccessImage} style={{ width: 73 }} />
        </Grid>
        <Grid item container justifyContent="center">
          <Typography variant={isSm ? 'h4' : 'h1'}>{title}</Typography>
        </Grid>
        <Grid item container justifyContent="center" textAlign='center'>
          <Typography variant="body1">
            {message}
          </Typography>
        </Grid>
      </Grid>
      {/* footer */}
      <Grid item container xs={12} justifyContent="center" marginTop={4}>
        <Button variant="contained" onClick={closeHandler ? closeHandler : closePopup}>
          Terminer
        </Button>
      </Grid>
    </Grid>
  );
}
