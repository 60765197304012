import * as Yup from 'yup';
import { Suspend } from './suspend-beneficiary.popup.molecule';
import { transformError } from '../../Utils/yup.utils';

export const suspendValidation = (suspend: Partial<Suspend>) => {
  try {
    const schema = Yup.object().shape({
      suspendDate: Yup.string().required('Ce champ est requis').default(''),
      suspendReason: Yup.string().required('Ce champ est requis').default('')
    })
    schema.validateSync(suspend, { abortEarly: false });
    return {};
  } catch (err) {
    return transformError(err as any);
  }
}
