import React from 'react';
import { SettingsHeader } from './Components';
import { PaperContainer, ResponsiveContainer } from '../../Components';
import { FloatingContainer } from '../../Components/Organisms/floating-container.organism';
import Grid from '@mui/material/Grid';
import { TUserRole } from '../../Types';
import { Typography, Button } from '@mui/material';
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';
import { PersonalInformationsForm } from '../../Components/Organisms/Forms/UserInformations/perso-informations.form';
import { ChangePassword } from '../../Components';
import { ReauthProvider } from '../../Providers';
import { usePopup } from '../../Hooks';
import { Popup } from '../../Theme';

import { DetetAccountPopup } from '../../Components/Organisms/Popups/DeleteAccount';

const DeleteAccount = () => {
  const { openPopup } = usePopup('DeleteAccount');
  return (
    <Button variant="text" color="error" fullWidth onClick={() => openPopup()} style={{textAlign: 'start'}}>
      <DeleteForeverOutlined style={{ marginRight: '20px' }} />
      Supprimer mon compte SAM.I
    </Button>
  );
};

type TSettingsProps = {
  userRole: TUserRole;
};

export const Settings = ({ userRole }: TSettingsProps) => {
  const {popupOpen, closePopup} = usePopup('DeleteAccount');
  return (
    <>
      <Popup open={popupOpen()} onClose={closePopup}>
        <ReauthProvider>
          <DetetAccountPopup />
        </ReauthProvider>
      </Popup>
      <ResponsiveContainer>
        <SettingsHeader />
        <Grid container justifyContent="space-between" gap={2}>
          <FloatingContainer xs={12} md={7.5}>
            <PaperContainer>
              <Typography variant="h2" marginBottom={2}>
                Informations du compte
              </Typography>
              <ReauthProvider>
                <ChangePassword />
              </ReauthProvider>
              {userRole === 'consultant' && (
                <>
                  <Grid item xs={12} container marginTop={6} marginBottom={3}>
                    <PersonalInformationsForm />
                  </Grid>
                </>
              )}
            </PaperContainer>
          </FloatingContainer>
          <FloatingContainer xs={12} md={4}>
            <PaperContainer>
              <Grid item xs={12} container justifyContent="space-between" marginBottom={3}>
                <Typography variant="h3">Paramètres</Typography>
              </Grid>
              <DeleteAccount />
            </PaperContainer>
          </FloatingContainer>
        </Grid>
      </ResponsiveContainer>
    </>
  );
};
