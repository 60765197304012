import { GlobalState } from 'little-state-machine';
import { TScreenState } from '../../Types';

export const updateSidepanelOpen = (state: GlobalState, sidePanelState: TScreenState) => {
  return {
    ...state,
    screen: {
      ...sidePanelState,
    },
  };
};
