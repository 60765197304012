import React, { useEffect, useState } from 'react';
import { useStateMachine } from 'little-state-machine';
import { updateSidepanelOpen, resetUserState, resetLoginState } from '../../Stores';
import { Drawer, MobileDrawer, DrawerHeader } from '../../Theme';
import { TScreenSidepanelAction } from '../../Types';
import IconButton from '@mui/material/IconButton';
import { SidepanelActions } from '../Molecules';
import Logo from '../../Assets/logo.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SidePanelActionsConfiguration } from '../../Configuration';
import { Button, Grid, Hidden } from '@mui/material';
import BackgroundImage from '../../Assets/background-menu.png';
import Logout from '../../Assets/sign_out.svg';
import { useNavigate } from 'react-router-dom';

export const Sidepanel = () => {
  const [sidePanelConfiguration, setSidePanelConfiguration] = useState<TScreenSidepanelAction[] | null>(null);
  const {
    state: {
      screen,
      // user: { profile },
      user: {
        consultant,
        beneficiary,
        admin,
        organism
      }
    },
    actions,
  } = useStateMachine({ updateSidepanelOpen, resetUserState, resetLoginState });
  const navigate = useNavigate();

  const handleSidepanelState = (isSidePanelOpen: boolean) => {
    actions.updateSidepanelOpen({
      isSidePanelOpen,
    });
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.setItem('shouldRegister', 'true');
    actions.resetLoginState();
    actions.resetUserState();
    navigate('/select-role');
  }

  useEffect(() => {
    if (consultant) {
      setSidePanelConfiguration(SidePanelActionsConfiguration.consultant);
    } else if (beneficiary) {
      setSidePanelConfiguration(SidePanelActionsConfiguration.beneficiary);
    } else if (organism) {
      setSidePanelConfiguration(SidePanelActionsConfiguration.organism);
    } else if (admin) {
      setSidePanelConfiguration(SidePanelActionsConfiguration.admin);
    }
  }, [consultant, beneficiary, admin, organism]);

  return (
    <>
      <Hidden smDown>
        <Drawer variant="permanent" anchor="left" open={(organism || admin) ? true : screen.isSidePanelOpen}>
          <DrawerHeader>
            <img src={Logo} style={{ maxWidth: '80px' }} />
          </DrawerHeader>
          {sidePanelConfiguration && (
            <SidepanelActions open={screen.isSidePanelOpen} configuration={sidePanelConfiguration} />
          )}
          <DrawerHeader open={screen.isSidePanelOpen}>
            <Grid container direction="column" alignItems="center" gap={3}>
              {(consultant || beneficiary) ? (
                <IconButton style={{color: 'black'}} onClick={() => handleSidepanelState(!screen.isSidePanelOpen)}>
                  {screen.isSidePanelOpen && <ArrowBackIcon />}
                  {!screen.isSidePanelOpen && <ArrowForwardIcon />}
                </IconButton>
              ) : (
                <>
                    <Button onClick={logout} variant='light' color='error' startIcon={<img src={Logout} alt='' />}>
                      Se déconnecter
                    </Button>
                  {/* )} */}
                </>
              )}
            </Grid>
          </DrawerHeader>
          {(beneficiary || consultant) && (
            <img
            src={BackgroundImage}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              zIndex: -1,
              width: '80%',
            }}
          />
          )}
        </Drawer>
      </Hidden>
      <Hidden smUp>
        <MobileDrawer
          variant="temporary"
          open={(organism || admin) ? true : screen.isSidePanelOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerHeader>
            <img alt='' src={Logo} style={{ maxWidth: '80px' }} />
          </DrawerHeader>
          {sidePanelConfiguration && (
            <SidepanelActions open={(organism || admin) ? true : screen.isSidePanelOpen} configuration={sidePanelConfiguration} />
          )}
          <DrawerHeader open={screen.isSidePanelOpen}>
            <Grid container direction="column" alignItems="center" gap={3}>
              {(consultant || beneficiary) ? (
                <IconButton style={{color: 'black'}} onClick={() => handleSidepanelState(!screen.isSidePanelOpen)}>
                {screen.isSidePanelOpen && <ArrowBackIcon />}
                {!screen.isSidePanelOpen && <ArrowForwardIcon />}
              </IconButton>
              ) : (
                <>
                  {/* {!screen.isSidePanelOpen ? (
                    <Button onClick={ogout} variant='light' color='error'><img src={Logout} alt='' /></Button>
                  ) : ( */}
                    <Button onClick={logout} variant='light' color='error' startIcon={<img src={Logout} alt='' />}>
                      Se déconnecter
                    </Button>
                   {/* )} */}
                </>
              )}
            </Grid>
          </DrawerHeader>
          {(consultant || beneficiary) && (
            <img
              alt=''
              src={BackgroundImage}
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                zIndex: -1,
                width: '80%',
              }}
            />
          )}
        </MobileDrawer>
      </Hidden>
    </>
  );
};
