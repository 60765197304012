import React, { useEffect } from 'react';
import ReactHelmet from 'react-helmet';

type MatomoTrackingProps = {
  siteId: string;
  trackerUrl: string;
};

const MatomoTracking: React.FC<MatomoTrackingProps> = ({ siteId, trackerUrl }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
  _paq.push(["setCookieDomain", "*.testbug.tech4work.fr"]);
  _paq.push(["setDomains", ["*.testbug.tech4work.fr/select-role","*.testbug.tech4work.fr/admin","*.testbug.tech4work.fr/beneficiary/*","*.testbug.tech4work.fr/consultant/*","*.testbug.tech4work.fr/login/beneficiary","*.testbug.tech4work.fr/login/consultant"]]);
  _paq.push(["setDoNotTrack", true]);
  _paq.push(["disableCookies"]);
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="https://tech4work.matomo.cloud/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '2']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/tech4work.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
  })();
	`;
    const script2 = document.createElement('script');
    script2.innerHTML = `

  var _mtm = window._mtm = window._mtm || [];
  _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  (function() {
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://cdn.matomo.cloud/tech4work.matomo.cloud/container_DGOP4nes.js'; s.parentNode.insertBefore(g,s);
  })();
          `;

    document.head.appendChild(script);
    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(script);
    };
  }, [siteId, trackerUrl]);

  return <ReactHelmet />;
};

export default MatomoTracking;
