import React from 'react';
import { Stack, TextField, Button, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import ConsultantIcon from '../../../Assets/consultant_black.svg'
import { useDatatableSearch } from '../../../Hooks';
import { NewHeader } from '../../../Components/Molecules/new-title.component';
import Search from '../../../Assets/search.svg'
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  isAdmin: boolean;
  selectedOrganism?: string;
  handleClickFilter: () => void;
}
export const OrganismConsultantsHeader = (props: HeaderProps) => {

  const { search, updateSearch } = useDatatableSearch();
  const { isAdmin, selectedOrganism, handleClickFilter } = props;
  const navigate = useNavigate();

  return (
    <Stack direction="column" width='100%' spacing={2}>
      <NewHeader title={'Consultants'} image={ConsultantIcon} />
      <Grid container direction='row' spacing={1}>
        <Grid item xs>
          <TextField
              fullWidth
              value={search}
              onChange={(e) => updateSearch(e.target.value)}
              placeholder="Rechercher un(e) consultant(e)"
              InputProps={{
                startAdornment: <img src={Search} alt='' />
              }}
            />
        </Grid>
        {isAdmin && (
          <Grid item container direction='row' xs={6} display='flex' spacing={1}>
            <Grid item xs>
              <Button sx={{width: '100%', backgroundColor: "rgba(90, 173, 178, 0.1)"}} onClick={() => { handleClickFilter() }}>
                {selectedOrganism || 'Filtrer par organisme'}
              </Button>
            </Grid>
            <Grid item xs>
              <Button fullWidth onClick={() => navigate('create')} variant='contained' startIcon={
                <Add />
              }>
                Ajouter un consultant
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};
