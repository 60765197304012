import React, { Fragment, } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { GenericCard, ProgressSpinner } from '../../../Components';
import Add from '@mui/icons-material/AddRounded';
import { TDashboardProps } from '../dashboard.component';
import { useStateMachine } from 'little-state-machine';
import { useNavigate } from 'react-router-dom';
import { usePopup } from '../../../Hooks';
import { useSnackbar } from 'notistack';

//TODO set in own module

export interface UserActionsCountProps {
  count: number;
}
const UserActionsCount = (props: UserActionsCountProps) => {
  const { count } = props;
  return (
    <Typography component="div" fontSize={(theme) => theme.customShapes.fixedSize48px} fontWeight="bold">
      {count || 0}
    </Typography>
  );
}

export const ActionsDashboard = ({ userRole, consultDashboard, benefDashboard }: TDashboardProps) => {
  const { openPopup } = usePopup('CreateInvitationPopup');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    state: {user}
  } = useStateMachine();
  
  return (
    <Fragment>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.8} lg={3.8} container marginBottom={3}>
          <GenericCard variant="generic" color="secondary">
            {userRole === 'beneficiary' && (
              <Grid container direction="column" justifyItems="space-between" gap={3}>
                <Grid container direction="row" wrap="nowrap" alignItems="center" gap={2}>
                  {
                    benefDashboard?.formCompletionPercentage !== null ? <ProgressSpinner value={benefDashboard?.formCompletionPercentage || 0} /> : <div>...loading</div>
                  }
                  <Typography variant="body1">
                    {benefDashboard?.formCompletionPercentage === 100 ? 'Votre profil est complet !' : 'Complété sur votre fiche bénéficiaire.'}
                  </Typography>
                </Grid>
                <Button variant="light" onClick={() => navigate('/beneficiary/my-informations')}>
                  {benefDashboard?.formCompletionPercentage === 100 ? 'Voir mes informations' : 'Compléter mes informations'}
                </Button>
              </Grid>
            )}
            {userRole === 'consultant' && (
              <Grid item container direction="column" justifyContent="center" alignItems="center" gap={3}>
                <Add
                  sx={{
                    fontSize: (theme) => theme.customShapes.fixedSize48px,
                  }}
                />
                <Button variant="light" onClick={() => openPopup()} disabled={!user.consultant?.isPremium}>
                  Inviter un(e) bénéficiaire
                </Button>
              </Grid>
            )}
          </GenericCard>
        </Grid>
        <Grid item xs={12} md={5.8} lg={3.8} container marginBottom={3}>
          <GenericCard variant="generic" color="primary">
            {userRole === 'beneficiary' && (
              <Grid container direction="column" justifyContent="space-between">
                <Grid container direction="row" wrap="nowrap" alignItems="center" gap={2}>
                  {/* <ActionsProvider uid={id as string} userRole="beneficiary"> */}
                    <UserActionsCount count={benefDashboard?.actionCount || 0} />
                  {/* </ActionsProvider> */}
                  <Typography variant="body1">
                    Démarches dans votre
                    <br />
                    parcours de maintien
                    <br />
                    dans l&apos;emploi
                  </Typography>
                </Grid>
                <Button variant="light" onClick={() => navigate('/beneficiary/journey')}>
                  Visualiser mon parcours
                </Button>
              </Grid>
            )}
            {userRole === 'consultant' && (
              <Grid item container direction="column" justifyContent="space-between">
                <Grid item container direction="row" wrap="nowrap" alignItems="center" gap={2}>
                  {/* <ActionsProvider uid={id as string} userRole="consultant"> */}
                    <UserActionsCount count={consultDashboard?.actionCount || 0} />
                  {/* </ActionsProvider> */}
                  <Typography variant="body1">
                    Actions effectuées avec
                    <br />
                    vos bénéficiaires
                  </Typography>
                </Grid>
                <Button variant="light" onClick={() => navigate('../my-actions')}>
                  Voir mes actions
                </Button>
              </Grid>
            )}
          </GenericCard>
        </Grid>
        <Grid item xs={12} lg={3.8} container marginBottom={3}>
          <GenericCard variant="generic" color="tertiary">
            {userRole === 'beneficiary' && (
              <Grid container direction="column" justifyContent="space-between">
                <Grid container direction="row" wrap="nowrap" alignItems="center" gap={2}>
                  <Typography component="div" fontSize={(theme) => theme.customShapes.fixedSize48px} fontWeight="bold">
                    {benefDashboard?.followingConsultantsCount}
                  </Typography>
                  <Typography variant="body1">
                    Consultant(s) connecté(s)
                    <br />à votre parcours
                  </Typography>
                </Grid>
                <Button variant="light" onClick={() => openPopup()}>
                  Partager mon parcours
                </Button>
              </Grid>
            )}
            {userRole === 'consultant' && (
              <Grid item container direction="column" justifyContent="space-between">
                <Grid item container direction="row" wrap="nowrap" alignItems="center" gap={2}>
                  <Typography component="div" fontSize={(theme) => theme.customShapes.fixedSize48px} fontWeight="bold">
                     {consultDashboard?.followedBeneficiariesCount}
                  </Typography>
                  <Typography variant="body1">
                    Bénéficiaires dont vous
                    <br />
                    suivez le parcours
                  </Typography>
                </Grid>
                <Button variant="light" onClick={() => navigate('../my-beneficiaries/all')}>
                  Voir mes bénéficiaires
                </Button>
              </Grid>
            )}
          </GenericCard>
        </Grid>
      </Grid>
    </Fragment>
  );
};
