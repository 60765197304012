import React, { useCallback, useEffect, useState } from 'react'
import { GetConsultantDto, PostOccupiedEnum } from '../../../Types';
import { Tabs, Tab, Stack, Typography, Button, IconButton, Grid, Theme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { ConsultantsBeneficiariesTable } from './consultants-beneficiaries.table';
import APIAxios, { APIRoutes } from '../../../API/api.axios';
import ArrowLeft from '../../../Assets/arrow_left.svg';
import Premium from '../../../Assets/Premium.svg';
import { NewHeader } from '../../../Components/Molecules/new-title.component';
import { ReadOnlyField } from '../../../Components/Atoms';
import { NewActionsDataTable } from '../../MyActions/Components/new-actions-table.component';
import { ResponsiveContainer } from '../../../Components';

const useStyles =  makeStyles((theme: Theme) => ({
  card: {
    borderRadius: '10px', 
    backgroundColor: 'white',
    fontSize: '13px',
    padding: '1.3rem'
  },
  title: {
    fontWeight: 600,
    color: 'black',
    paddingBottom: '0.7rem',
  },
  name: {
    color: 'rgb(155, 159, 164)',
    fontWeight: 500,
    alignSelf: 'start',
  },
  value: {
    color: 'black',
    fontWeight: 400,
    alignSelf: 'end'
  },
  premium: {
    backgroundColor: 'rgba(255, 185, 92, 0.1) !important',
    color: 'rgba(254, 174, 68)',
  },
  inactive: {
    backgroundColor: 'rgba(155, 159, 164, 0.2)',
    color: 'grey'
  },
  image: {
    height: '70px',
    width: '70px',
    borderRadius: '8px',
    backgroundColor: 'grey'
  }
}));

interface ConsultantProfileProps {
  consultant?: GetConsultantDto;
  handleChange: (state: 'premium' | 'lock') => void;
}
const ConsultantProfileComponent = (props: ConsultantProfileProps) => {

  const { consultant, handleChange } = props;
  const classes = useStyles();

  if (!consultant) {
    return <></>
  }

  return (
    <Grid container flex='flex' minHeight='100%' height='100%' alignItems='start' justifyContent='space-evenly'>
      <Grid item container direction="row" gap={4} >
        <Grid item xs className={classes.card}> 
          <Stack gap={2}>
            <Typography className={classes.title}>
              Informations personnelles
            </Typography>
            <ReadOnlyField name="Nom" value={consultant.lastName} />
            <ReadOnlyField name='Prénom' value={consultant.firstName} />
            <ReadOnlyField name='Numéro de téléphone' value={consultant.phoneNumber} />
            <ReadOnlyField name='Adresse mail' value={consultant.email} />
            <ReadOnlyField 
              name='Poste occupé' 
              value={consultant.postOccupied ? PostOccupiedEnum.label(consultant.postOccupied) : '-'} />
              <ReadOnlyField name="Nom de l'organisme temporaire" value={consultant.organismTmp} />
          </Stack>
        </Grid>
        <Grid item xs className={classes.card}>
          <Stack gap={1}>
            <Typography className={classes.title}>Organisme</Typography>
            <Stack direction="row" justifyContent="start" gap={2}>
              <img className={classes.image} alt='' />
              <Stack>
                <Typography className={classes.title}>{consultant.organism?.name}</Typography>
                <Typography className={classes.name}>{consultant.organism?.address}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs className={classes.card}>
          <Stack gap={1}>
          <Typography className={classes.title}>Statut</Typography>
            <Button onClick={() => handleChange('premium')} startIcon={<img alt='' src={Premium} />} className={consultant.isPremium ? classes.premium : classes.inactive}>Premium</Button>
          </Stack>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          onClick={() => handleChange('lock')}
          sx={{backgroundColor: !consultant.isLocked ? 'rgba(227, 101, 101, 0.1)' : 'rgb(238, 246, 245)', color: !consultant.isLocked ? 'rgb(227, 101, 101)' : 'rgb(90, 173, 178)', padding: '6px', paddingLeft: '15px', paddingRight: '15px'}}>
          {consultant.isLocked ? "Réactiver l'utilisateur" : "Désactiver l'utilisateur" }
        </Button>
      </Grid>
    </Grid>
  )
}

export const ConsultantDetail = () => {

  const { consultantId } = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState(0);
  const [consultant, setConsultant] = useState<GetConsultantDto | undefined>(undefined);

  const getConsultant = useCallback(async () => {
    if (consultantId) {
      try {
        const res = await APIAxios({...APIRoutes.GETConsultantById(consultantId)})
        if (res.data) {
          setConsultant(res.data);
        }
      } catch (err) {
      }
    }
  }, [consultantId]);

  const toggleState = async (state: 'premium' | 'lock') => {
    if (consultant?.id) {
      try {
        const res = await APIAxios({...APIRoutes.PATCHConsultantById(consultant.id),
        data: {
          isPremium: state === 'premium' ? !consultant.isPremium : null,
          isLocked: state === 'lock' ? !consultant.isLocked : null
        }})
        if (res.data) {
          setConsultant(res.data);
        }
      } catch (err) {

      }
    }
  }

  const renderTab = () => {
    switch (tab) {
      case 0:
        return <ConsultantProfileComponent consultant={consultant} handleChange={toggleState} />
      case 1:
        return <ConsultantsBeneficiariesTable consultantId={consultant?.id} />
      case 2:
        return (
          <ResponsiveContainer>
            <NewActionsDataTable consultantId={consultant?.id} />
          </ResponsiveContainer>
        )
    }
  }

  useEffect(() => {
    getConsultant();
  }, [getConsultant]);

  if (consultant === undefined) {
    return <div>loading ...</div>
  }

  return (
    <>
      <Stack spacing={2} height='100%'>
        <Stack direction="row" alignItems='baseline'>
          <IconButton onClick={() => navigate(-1)}>
            <img src={ArrowLeft} alt='retour' />
          </IconButton>
          <NewHeader title={consultant?.firstName + " " + consultant?.lastName} />
        </Stack>
        <Tabs value={tab} onChange={(e, v) => setTab(v)} sx={{width: '100%', borderBottom: 'solid 1px rgb(233, 233, 233)'}}>
          <Tab label="Informations" />
          <Tab label="Bénéficiaires" />
          <Tab label="Dispositifs" />
        </Tabs>
        {renderTab()}
      </Stack>
    </>
  )
};