import * as Yup from 'yup';

export const resetPasswordValidation = Yup.object().shape({
  mail: Yup.string().when('$resetMode', {
    is: false,
    then: Yup.string().required('Ce champ est requis').email("Format de l'email incorrecte").default(''),
  }),
  password: Yup.string().when('$resetMode', {
    is: true,
    then: Yup.string()
      .required('Ce champ est requis')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, 'Le mot de passe doit contenir au moins huit caractères, une lettre, un numéro et un caractère spécial.')
      .default(''),
  }),
  passwordConfirmation: Yup.string().when('$resetMode', {
    is: true,
    then: Yup.string().oneOf([Yup.ref('password'), null], 'Les mots de passes doivent être identiques'),
  }),
});
