import { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Grid, IconButton, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { createSearchParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom';

import { Header } from '../..';
import { usePopup } from '../../../Hooks';
import { GetBeneficiaryDto, SuspendReason, TUserInformationMode } from '../../../Types';
import APIAxios, { APIRoutes } from '../../../API/api.axios';
import { SuspendbenefPopup } from '../../Molecules/suspend-beneficiary.popup.molecule';
import { HelpBenefPopup } from '../../Molecules/help-beneficiary.popup.molecule';

type TBeneficiaryInformationsHeaderProps = {
  mode: TUserInformationMode;
};

interface SuspendInfos extends Pick<GetBeneficiaryDto, 'firstName' | 'suspendedBy' | 'suspendReason' | 'suspendDate' | 'isSuspended' | 'lastName'> {}

export const BeneficiaryInformationsHeader = ({ mode }: TBeneficiaryInformationsHeaderProps) => {
  const { openPopup } = usePopup('ActionPopup');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('uid');
  const [beneficiary, setBeneficiary] = useState<GetBeneficiaryDto | undefined>(undefined);
  const [popupOpen, setPopupOpen] = useState(false);
  const [helpPopupOpen, setHelpPopupOpen] = useState(false);
  const [suspendInfos, setSuspendInfo] = useState<SuspendInfos | undefined>();

  const handleRedirect = () => {
    if (id) {
      navigate({
        pathname: mode === 'informations' ? '../journey' : '../informations',
        search: `?${createSearchParams({
          uid: id,
        })}`,
      });
    }
  };

  const handleTracability = async () => {
    try {      
      if (!beneficiary?.id) {
        console.log('No beneficiary id, cannot update tracability');

        return;
      }

      await APIAxios({ ...APIRoutes.POSTStartHelpingBeneficiary(beneficiary.id) });
    } catch (error) {
      console.log(error);
    }
  };

  const getSuspendInfos = useCallback(async () => {
    if (!id || beneficiary) {
      return;
    }
    try {
      const res = await APIAxios({ ...APIRoutes.GETIsSuspended(id) });
      setSuspendInfo(res.data);
    } catch (err: any) {
    }
  }, [enqueueSnackbar, id]);

  const getBenefProfile = useCallback(async () => {
    
    if (!id) {
      try {
        const res = await APIAxios({ ...APIRoutes.GETMe() });
        if (res.data) {
          setBeneficiary(res.data.beneficiary);
        } else {
          enqueueSnackbar('Une erreur est survenue. Veuillez réessayer plus tard.', {
            variant: 'error',
          });
        }
      } catch (err) {
        enqueueSnackbar('Une erreur est survenue. Veuillez réessayer plus tard.', {
          variant: 'error',
        });
      }
    } else {
      try {
        const res = await APIAxios({ ...APIRoutes.GETBeneficiary(id) });
        if (res.data as GetBeneficiaryDto) {
          setBeneficiary(res.data);
        } else {
          enqueueSnackbar('Une erreur est survenue. Veuillez réessayer plus tard.', {
            variant: 'error',
          });
        }
      } catch (err: any) {
        if (err.response.data.message === 'USER_IS_DELETED') {
          // enqueueSnackbar('Ce compte bénéficiaire a été supprimé', { variant: 'error' });
          if (location.pathname === '/consultant/my-beneficiaries/informations') navigate(-1);
        }
      }
    }
  }, [enqueueSnackbar, id, location, navigate]);

  const handleHelpingClicked = async () => {
    try {
      if (!id) return;

      const res = await APIAxios({ ...APIRoutes.GETBeneficiary(id) });

      if (res.data as GetBeneficiaryDto) {
        if (res.data.isHelpNeeded) {
          setHelpPopupOpen(true)
          handleTracability()
        } else {
          enqueueSnackbar("Pour aider le bénéficiaire, ce dernier doit en faire la demande depuis son espace Sam.i", { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Une erreur est survenue. Veuillez réessayer plus tard.', {
          variant: 'error',
        });
      }

    } catch (error) {
      console.log(error);
      enqueueSnackbar('Une erreur est survenue. Veuillez réessayer plus tard.', {
        variant: 'error',
      });
    }
  }

  useEffect(() => {
    if (!beneficiary) {
      getBenefProfile();
    }

    getSuspendInfos();
  }, [getBenefProfile, getSuspendInfos, beneficiary, popupOpen, helpPopupOpen]);

  const displayInfo = beneficiary || suspendInfos;

  return (
      <>
        {popupOpen && id && (
          <SuspendbenefPopup
              beneficiaryId={id}
              beneficiary={beneficiary}
              isSuspended={displayInfo?.isSuspended}
              handleClose={() => {
                setPopupOpen(false);
                getBenefProfile();
              }}
          />
        )}
        {helpPopupOpen && beneficiary ? (
          <HelpBenefPopup 
            beneficiary={beneficiary}
            handleClose={() => {
              setHelpPopupOpen(false);
              getBenefProfile();
            }}
          />
        ) : (
          <Header
              picture={
                  mode !== 'journey-read' && (
                      <IconButton onClick={() => navigate('../all')}>
                        <ArrowBack />
                      </IconButton>
                  )
              }
              title={
                <>
                  {mode !== 'journey-read' && (
                      <Stack>
                        <Typography variant="h1" component="div">
                          {mode === 'informations' ? 'Fiche infos' : 'Parcours'} de{' '}
                          <span className="capital">
                      {displayInfo
                          ? `${displayInfo.lastName?.toUpperCase()}  ${displayInfo.firstName}`
                          : ''}
                    </span>
                        </Typography>
                        {displayInfo?.isSuspended && (
                            <Typography align="left">
                              {`Parcours archivé
                      le ${displayInfo.suspendDate && new Date(displayInfo.suspendDate).toISOString().split('T')[0]} 
                      par ${displayInfo.suspendedBy?.firstName + ' ' + displayInfo.suspendedBy?.lastName} 
                      de l'organisme ${displayInfo.suspendedBy?.organismName} 
                      avec le motif ${displayInfo.suspendReason && SuspendReason.label(displayInfo.suspendReason)}`}
                            </Typography>
                        )}
                      </Stack>
                  )}
                  {mode === 'journey-read' && (
                      <>
                        <Typography variant="h1" component="h1">
                          Mon parcours
                        </Typography>
                        <Typography variant="h3" color="GrayText">
                          Votre maintien / retour dans l'emploi en plusieurs étapes, visible par vos consultants
                        </Typography>
                      </>
                  )}
                </>
              }
              actions={
                  mode !== 'journey-read' && (
                      <>
                        {mode === 'informations' && (
                            <Grid container gap={1}>
                              <Button 
                                color={"primary"}
                                variant="contained"
                                onClick={handleHelpingClicked.bind(this)}
                              >
                                J'aide le bénéficiaire
                              </Button>

                              <Button color="primary" variant="contained" onClick={handleRedirect}>
                                Voir le parcours
                              </Button>
                            </Grid>
                        )}
                        {mode === 'journey' && (
                            <>
                              <Button color="primary" variant="outlined" onClick={handleRedirect} disabled={!beneficiary}>
                                Voir la fiche infos
                              </Button>
                              <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => openPopup('add')}
                                  disabled={!beneficiary || displayInfo?.isSuspended}
                              >
                                Ajouter une action
                              </Button>
                              <Button
                                  disabled={!displayInfo || (displayInfo.isSuspended && !beneficiary)}
                                  color="warning"
                                  variant="contained"
                                  onClick={() => setPopupOpen(true)}
                              >
                                {displayInfo?.isSuspended ? 'Désarchiver le parcours' : 'Archiver le parcours'}
                              </Button>

                            </>
                        )}
                      </>
                  )
              }
          />
        )}
      </>
  );
};
