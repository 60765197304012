import React, { Fragment } from 'react';
import { consultantValidation, organismValidation, profileValidation } from './regular.validation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import { GenderEnum, TAccountCreateData } from '../../../../Types';
import { useStateMachine } from 'little-state-machine';
import { updateLoginState } from '../../../../Stores';
import { IAccountCreateFormsProps } from '../interfaces.form';
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    margin: theme.spacing(1, 0) + ' !important',
  },
}));

export const BasicProfileForm = ({ setCurrentStep }: IAccountCreateFormsProps) => {
  const classes = useStyles();
  const { state, actions } = useStateMachine({ updateLoginState });
  const { type } = useParams();
  const { register, formState, getValues, control } = useForm<Partial<TAccountCreateData>>({
    resolver: yupResolver(type === 'organism' ? organismValidation : type === 'consultant' ? consultantValidation : profileValidation),
    mode: 'onChange',
  });

  const { isValid, errors } = formState;
  const handleNextStep = () => {
    actions.updateLoginState({
      createAccountValues: {
        gender: type === 'organism' ? undefined : getValues('gender'),
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        name: getValues('name'),
        organismTmp: getValues('organismTmp')
      },
    });
    setCurrentStep(2);
  };

  return (
    <Fragment>
      {type === 'beneficiary' && (
        <div>
          <Typography variant="h3">Je suis</Typography>
          <Controller
            name="gender"
            control={control}
            defaultValue={GenderEnum.MALE}
            render={({ field }) => (
              <FormControl fullWidth component="fieldset" error={!!formState.errors.gender}>
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value={GenderEnum.MALE}
                    control={<Radio />}
                    label={`Un ${GenderEnum.label(GenderEnum.MALE)}`}
                  />
                  <FormControlLabel
                    value={GenderEnum.FEMALE}
                    control={<Radio />}
                    label={`Une ${GenderEnum.label(GenderEnum.FEMALE)}`}
                  />
                </RadioGroup>
                {!!formState.errors.gender && <FormHelperText>{formState.errors.gender.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </div>
      )}
      <TextField
        {...register('firstName')}
        defaultValue={state.loginSession.createAccountValues.firstName || ''}
        error={errors.firstName ? true : false}
        required
        autoFocus
        type="text"
        color="primary"
        placeholder={type === 'organism' ? "Prénom du représentant" : "Prénom"}
        helperText={errors.firstName ? errors.firstName.message : ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutlined />
            </InputAdornment>
          ),
        }}
      ></TextField>
      <TextField
        {...register('lastName')}
        defaultValue={state.loginSession.createAccountValues.lastName || ''}
        error={errors.lastName ? true : false}
        required
        type="text"
        color="primary"
        placeholder={type === 'organism' ? "Nom du représentant" : 'Nom'}
        helperText={errors.lastName ? errors.lastName.message : ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutlined />
            </InputAdornment>
          ),
        }}
      ></TextField>
      {type === "organism" && (
        <TextField
          {...register('name')}
          defaultValue={state.loginSession.createAccountValues.name || ''}
          error={errors.firstName ? true : false}
          required
          autoFocus
          type="text"
          color="primary"
          placeholder="Nom de l'organisme"
          helperText={errors.name ? errors.name.message : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutlined />
              </InputAdornment>
            ),
          }}
        />
      )}
      {type === 'consultant' && (
        <TextField
          {...register('organismTmp')}
          defaultValue={state.loginSession.createAccountValues.organismTmp || ''}
          error={errors.firstName ? true : false}
          required
          autoFocus
          type="text"
          color="primary"
          placeholder="Nom de l'organisme"
          helperText={errors.organismTmp ? errors.organismTmp.message : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutlined />
              </InputAdornment>
            ),
          }}
        />
      )}
      <Button
        className={classes.actionButton}
        disabled={!isValid}
        variant="contained"
        color="primary"
        onClick={handleNextStep}
      >
        Suivant
      </Button>
    </Fragment>
  );
};
