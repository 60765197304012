import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

type TInformation = 'error' | 'warning' | 'success' | 'info';

interface IInformationProps {
  type: TInformation;
  title: string;
  message: string | ReactElement;
  buttonText: string;
  buttonAction: () => unknown;
}

export const InformationContent = ({ buttonText, buttonAction, message, title, type }: IInformationProps) => {
  const theme = useTheme();
  return (
    <Grid
      maxWidth={500}
      width="auto"
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap={theme.spacing(2)}
      margin={theme.spacing(2, 4)}
    >
      <Typography variant="h3">{title}</Typography>
      {message && (
        <Typography component='div' variant="body1" textAlign="center" whiteSpace='break-spaces'>
          {message}
        </Typography>
      )}
      <Button variant="contained" color={type} onClick={buttonAction}>
        {buttonText}
      </Button>
    </Grid>
  );
};
