import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, gridDateComparator } from '@mui/x-data-grid';
import { ProfileAvatar } from '../../../Components';
import { ActionStatus, ActionType, GetActionListDto} from '../../../Types/actions.type';
import { ActionDateCell } from './action-date-cell.component';
import { useDatatableSearch } from '../../../Hooks';
import { useSnackbar } from 'notistack';
import APIAxios, { APIRoutes } from '../../../API/api.axios';
import { useParams } from 'react-router-dom';
import { CustomPagination } from '../../../Theme';

const columns: GridColDef[] = [
  {
    field: 'createdAt',
    headerName: "Création de l'actionService",
    sortable: false,
    disableColumnMenu: true,
    minWidth: 200,
    headerAlign: 'center',
    align: 'center',
    sortComparator: gridDateComparator,
    renderHeader: () => <Typography fontSize="10px">Création de l'action</Typography>,
    renderCell: (params: GridCellParams<Date>) => (
      <ActionDateCell newTable date={new Date(params?.value ?? "")} />
    ),
  },
  {
    field: 'beneficiary',
    headerName: 'Bénéficiaire',
    sortable: false,
    disableColumnMenu: true,
    align: 'left',
    minWidth: 200,
    headerAlign: 'left',
    renderHeader: () => <Typography fontSize="10px">Bénéficiaire</Typography>,
    renderCell: (params: GridCellParams) => (
      <Grid container gap={2} flexWrap='nowrap' alignItems='center'>
        <ProfileAvatar size={37} />
        <Typography fontSize='12px'>
          {params.value.lastName + ' ' + params.value.firstName}
        </Typography>
      </Grid>
    ),
  },
  {
    field: 'type',
    headerName: "Type d'action",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    minWidth: 200,
    align: 'left',
    headerAlign: 'left',
    renderHeader: () => <Typography fontSize="10px">Type d'action</Typography>,
    renderCell: (params: GridCellParams) => (
      <Typography textAlign="start" fontSize='12px'>
        {ActionType.label(params.value)}
      </Typography>
    ),
  },
  {
    field: 'status',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 150,
    align: 'left',
    headerAlign: 'left',
    renderHeader: () => <Typography fontSize="10px">Statut</Typography>,
    renderCell: (params: GridCellParams) => (
    <Typography fontSize='12px'>
      {ActionStatus.label(params.value) || '-'}
    </Typography>
    )
  },
];

interface ActionsProps {
  organismId?: string;
  consultantId?: string;
}

export const NewActionsDataTable = ({organismId, consultantId}: ActionsProps) => {
  const { search } = useDatatableSearch();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [data, setData] = useState<GetActionListDto | undefined>(undefined);

  const getActions = useCallback(async () => {
    try {
      const res = await APIAxios({...APIRoutes.GETActions(page + 1, search, undefined, undefined, consultantId, organismId)});
      if (res.data as GetActionListDto) {
        setData(res.data);
      }
    } catch (err) {
      enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: 'error'});
    }
  }, [consultantId, organismId, enqueueSnackbar, page, search]);

  useEffect(() => {
    getActions();
  }, [getActions]);

  if (data === undefined) {
    return <div>Chargement ...</div>
  }
  
  return (
    <DataGrid
      rows={data.data}
      columns={columns}
      onPageChange={setPage}
      pageSize={data.meta.take}
      autoHeight
      disableSelectionOnClick
      paginationMode='server'
      density='standard'
      rowCount={data.meta.itemCount}
      components={{
        Pagination: CustomPagination
      }}
      sx={{
        '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
          visibility: 'hidden',
        },
        '&.MuiDataGrid-root': {
          border: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-virtualScroller': {
          marginTop: '32px !important',
          backgroundColor: 'white !important',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
          backgroundColor: 'white !important',
          height: '35px !important',
          maxHeight: '35px !important',
          minHeight: '35px !important',
          fontSize: '10px',
          fontWeight: '500',
        },
        '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
        },
        '.& MuiDataGrid-columnHeaders': {
          fontSize: '10px',
          fontWeight: '500',
        },
        '&.MuiDataGrid-root *:focus-within': {
          outline: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
          overflow: 'hidden',
          whiteSpace: 'normal',
          color: 'rgb(155, 159, 164)',
          footWeight: '500',
          display: 'flex',
          justifyContent: 'start',
          fontSize: '10px',
          textAlign: 'start',
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell': {
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'start',
          whiteSpace: 'normal',
          textAlign: 'start',
          borderTop: '1px solid rgb(233, 233, 233)',
          borderBottom: '0px'
        },
      }}
      />
  );
};