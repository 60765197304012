import React, { useState, useLayoutEffect, useCallback, useEffect } from 'react';
import { Copyright, Success } from '../../Components';
import { Button, Grid, TextField, Theme, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Logo from '../../Assets/logo.png';
import { resetPasswordValidation } from './reset-password.validation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendResetPasswordMail, handleResetPassword } from '../../Services/Auth/auth.service';
import { useError, usePopup } from '../../Hooks';
import { Popup } from '../../Theme';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PasswordTextField } from '../../Theme';
import APIAxios, { APIRoutes } from '../../API/api.axios';

const useStyles = makeStyles((theme: Theme) => ({
  shadowed: {
    padding: theme.spacing(6, 5),
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    zIndex: 1,
    boxShadow: theme.customShadows.shadow1,
  },
  containerHeight: {
    height: `calc(100vh - ${theme.customShapes.footerHeight})`,
  },
  logo: {
    maxWidth: '150px',
  },
  [theme.breakpoints.down('md')]: {
    container: {
      padding: '2rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      padding: '1rem',
    },
    shadowed: {
      padding: theme.spacing(3, 2),
    },
    logo: {
      maxWidth: '100px',
    },
  },
}));

export const ResetPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { addError } = useError();
  const { popupOpen, openPopup, closePopup } = usePopup('resetPasswordSuccess');
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [resetMode, setResetMode] = useState<boolean>(false);
  const [shouldShowCGU, setShouldShowCGU] = useState(false);
  const {
    formState: { errors, isValid },
    register,
    handleSubmit,
  } = useForm<{ mail: string; password: string; passwordConfirmation: string }>({
    resolver: yupResolver(resetPasswordValidation),
    mode: 'all',
    context: {
      resetMode,
    },
  });

  const handleSendResetMail = useCallback(
    async ({ mail: email }: { mail: string }) => {
      try {
        await APIAxios({...APIRoutes.POSTLostPassword(), data: { email: email }});
        openPopup();
      } catch (error) {
        addError(error);
      }
    },
    [addError, openPopup],
  );

  const handleResetPass = useCallback(
    async ({
      password
    }: {
      mail: string;
      password: string;
      passwordConfirmation: string;
    }) => {
      try {
        if (resetMode) {
          const token = searchParams.get('token');
          if (token) {
            await APIAxios({...APIRoutes.POSTResetPassword(), data: { password: password, token: token}})
            openPopup();
          } else {
            throw Error('Le code de réinitialisation est manquant. Veuillez faire une nouvelle demande de réinitialisation.')
          }
        }
      } catch (error) {
        addError({message: "Une erreur est survenue. Veuillez réessayer plus tard."});
      }
    },
    [resetMode, searchParams, addError, openPopup],
  );

  const handleSuccess = () => {
    closePopup();
    navigate('/');
  };

  useEffect(() => {
    if (searchParams.get('isNewAccount') === 'true') {
      setShouldShowCGU(true)
    }
  }, [searchParams]);

  useLayoutEffect(() => {
    if (searchParams.get('token')) {
      setResetMode(true);
    }
  }, [searchParams]);

  return (
    <>
      <Popup open={popupOpen()} maxWidth="md" fullScreen={fullscreen}>
        {!resetMode && (
          <Success
            popupId="resetPasswordSuccess"
            title="Succès !"
            message={`Mail de réinitialisation envoyé avec succès !
              Pensez à vérifier vos 'spams'`}
            closeHandler={handleSuccess}
          />
        )}
        {resetMode && (
          <Success
            popupId="resetPasswordSuccess"
            title="Succès !"
            message={`Mot de passe réinitialisé avec succès !
              Vous pouvez désormais vous connecter avec votre nouveau mot de passe.`}
            closeHandler={handleSuccess}
          />
        )}
      </Popup>
      <Grid
        container
        direction="column"
        justifyContent="center"
        position='relative'
        margin="auto"
        maxWidth={600}
        height="100vh"
        className={`${classes.container} ${classes.containerHeight}`}
        flexWrap="nowrap"
        gap={{ xs: 3, sm: 5 }}
      >
        <Grid item container justifyContent="center">
          <img src={Logo} className={classes.logo} alt='' />
        </Grid>
        <Grid item container justifyContent="center" width="100%" className={classes.shadowed} gap={2}>
          <Typography variant="h2" marginBottom={2}>
            Réinitialiser mon mot de passe
          </Typography>
          <Typography variant="body1">Vous êtes sur le point de réinitialiser votre mot de passe SAM.I</Typography>
          {!resetMode && (
            <>
              <Typography variant="subtitle1" textAlign="center">
                Un e-mail de réinitialisation sera envoyé à l&apos;adresse mail que vous aurez renseigné, celui-ci
                contiendra les informations pour réinitialiser votre mot de passe.
              </Typography>
              <TextField
                margin="dense"
                {...register('mail')}
                fullWidth
                placeholder="email"
                label="email"
                required
                type="mail"
                error={!!errors.mail?.message}
                helperText={errors?.mail?.message || ''}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={!isValid}
                onClick={handleSubmit(handleSendResetMail)}
              >
                Envoyer
              </Button>
            </>
          )}
          {resetMode && (
            <>
              <Typography variant="subtitle1" textAlign="center">
                Renseignez votre nouveau mot de passe, confirmez-le puis cliquez sur &apos;Valider&apos;
              </Typography>
              <Grid container direction="column">
                <PasswordTextField
                  {...register('password')}
                  fullWidth
                  margin="dense"
                  placeholder="Mot de passe"
                  error={!!errors.password?.message}
                  helperText={errors?.password?.message || ''}
                />
                <PasswordTextField
                  {...register('passwordConfirmation')}
                  fullWidth
                  margin="dense"
                  placeholder="Confirmation du mot de passe"
                  error={!!errors.passwordConfirmation?.message}
                  helperText={errors?.passwordConfirmation?.message || ''}
                />
                {shouldShowCGU && (
                  <Typography variant="subtitle2" component='div'>
                    En cliquant sur le bouton ci-dessous, j&apos;accepte les <Link to='/terms/terms-of-service'>Conditions Générales d&apos;Utilisation</Link>
                    &nbsp;ainsi que la <Link to='/terms/privacy-policy'>Politique de Confidentialité</Link> de la plateforme.
                  </Typography>
                )}
                <Button
                  sx={{
                    marginTop: (theme) => theme.spacing(2),
                  }}
                  variant="contained"
                  color="primary"
                  disabled={!isValid}
                  onClick={handleSubmit(handleResetPass)}
                >
                  Valider
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      <Copyright />
      </Grid>
    </>
  );
};
