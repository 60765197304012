import React from "react";
import { Button } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { createSearchParams, useNavigate } from "react-router-dom";
import UserInformationsIcon from "@mui/icons-material/AssignmentOutlined";
import UserJourneyIcon from "@mui/icons-material/DateRangeOutlined";
import UserActionEdit from "@mui/icons-material/ModeEditRounded";
import { TUserInformationMode } from "../../../Types";
import { useUserProfile, usePopup } from "../../../Hooks";
import EyeIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { BeneficiaryProfileContext } from "../../../Providers";
import Document from "../../../Assets/document.svg";

type TDatatableCellAction =
  | TUserInformationMode
  | "edit"
  | "consultant-read"
  | "organism-read"
  | "beneficiary-detail";

type TClickableDatatableCellProps = GridRenderCellParams & {
  action: TDatatableCellAction;
};

export const ClickableDatatableCell = ({
  id,
  action,
  row,
}: TClickableDatatableCellProps) => {
  const { openPopup } = usePopup("ActionPopup");
  // const { selectAction, profile } = useUserProfile(BeneficiaryProfileContext);
  const { selectAction, user } = useUserProfile(BeneficiaryProfileContext);
  const navigate = useNavigate();

  const handleClick = () => {
    if (action === "journey-read") {
      selectAction(id as string, () => openPopup("readonly"));
    } else if (action === "edit") {
      selectAction(id as string, () => openPopup("edit"));
    } else if (action === "consultant-read") {
      navigate(`/admin/consultants/${id}`);
    } else if (action === "organism-read") {
      navigate(`/admin/organisms/${id}`);
    } else if (action === "beneficiary-detail") {
      navigate(`${id}`);
    } else {
      navigate({
        pathname: action === "informations" ? "../informations" : "../journey",
        search: `?${createSearchParams({
          uid: id as string,
        })}`,
      });
    }
  };
  
  return (
    <Button
      onClick={handleClick}
      color="primary"
      disabled={action === ("informations") && row.isDeleted}
    >
      {action === "informations" && <UserInformationsIcon />}
      {action === "consultant-read" && <img src={Document} alt="" />}
      {action === "organism-read" && <img src={Document} alt="" />}
      {action === "journey" && <UserJourneyIcon />}
      {/* {action === 'edit' && !profile?.isDeleted && <UserActionEdit />} */}
      {action === "edit" && !user?.isDeleted && <UserActionEdit />}
      {action === "edit" && user?.isDeleted && <EyeIcon />}
      {action === "journey-read" && <EyeIcon />}
      {action === "beneficiary-detail" && <img src={Document} alt="" />}
    </Button>
  );
};
