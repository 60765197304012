import { OptionsObject, VariantType } from "notistack";

class SnackError extends Error {
  private readonly text: string;
  private readonly options: OptionsObject;
  private readonly errorCode?: number;

  constructor(
    key: string,
    variant: VariantType,
    errorCode?: number,
    ...params: any[]
  ) {
    super(...params);

    this.name = "SnackError";

    this.text = (key);
    this.options = { variant };
    this.errorCode = errorCode;
  }
}

export default SnackError;
